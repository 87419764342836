<!-- eslint-disable -->
<template>
    <div class="w-100">
        <span type="button" class="w-100 btn btn-warning btn-icon-text mx-2 mb-3 mb-md-0 bg-green"
            @click="generateFile"> <i class="far fa-file-excel"></i> Export en Excel</span>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division } from "@/Utils/globale.js";
import { exportToExcel, exportToPdf } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo"],
    components: {
    },
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        async generateFile() {
            exportToExcel(this.changeKey(), "report-syntheses_" + this.$moment().utc().format("YYYY-MM-DD HH mm"), true, false)
        },
        changeKey() {
            try {
                const newDataToExport = {}

                if (this.exportTo["meilleurventes"]?.data?.length > 0) {
                    const arrayMeilleurventes = []
                    this.exportTo["meilleurventes"]?.data?.map((item, i) => {
                        arrayMeilleurventes.push({
                            "Vendeur ": item?.LibVendeur,
                            "Groupe ": item?.LibGroupe,
                            "Famille ": item?.LibFamille,
                            "Libelle ": item?.Libelle,
                            "Quantite ": item?.QteTotal,
                            "Total TTC ": item?.TotalTTC?.toFixed(2) + " €",
                        });
                    });
                    newDataToExport['Meilleures 10 ventes'] = arrayMeilleurventes
                }
                if (this.exportTo["ventes_par_vendeur"]?.data?.length > 0) {
                    const arrayVentes_par_vendeur = []
                    this.exportTo["ventes_par_vendeur"]?.data?.map((item, i) => {
                        arrayVentes_par_vendeur.push({
                            "Vendeur ": item?.LibVendeur,
                            "Groupe ": item?.LibGroupe,
                            "Famille ": item?.LibFamille,
                            "Libelle ": item?.Libelle,
                            "Quantite ": item?.QteTotal,
                            "Total TTC ": item?.TotalTTC?.toFixed(2) + " €",
                        });
                    });
                    newDataToExport['Ventes par vendeur'] = arrayVentes_par_vendeur
                }
                if (this.exportTo["articles_annules"]?.data?.length > 0) {
                    const arrayArticles_annules = []
                    this.exportTo["articles_annules"]?.data?.map((item, i) => {
                        arrayArticles_annules.push({
                            "Nombre d'articles ": item?.ArticlesAnnules?.length || 0,
                            "Vendeur ": item?._id,
                            "Quantité ": item?.QuantityVendeur,
                            "Total Valeur (€) ": item?.TotalValeurVendeur?.toFixed(2) + " €",
                        })
                        if (item?.ArticlesAnnules?.length > 0) {
                            arrayArticles_annules.push({
                                "Nombre d'articles ": "La Caisse ",
                                "Vendeur ": "Libelle ",
                                "Quantité ": "Quantité ",
                                "Total Valeur (€) ": "Total Valeur (€) ",
                            });

                            item?.ArticlesAnnules?.map((fm, i) => {
                                arrayArticles_annules.push({
                                    "Nombre d'articles ": fm?.LibCaisse,
                                    "Vendeur ": fm?.libelle,
                                    "Quantité ": fm?.QuantityLibelle,
                                    "Total Valeur (€) ": fm?.TotalValeurLibelle,
                                });
                            });
                        }
                        arrayArticles_annules.push({
                            "Nombre d'articles ": " ",
                            "Vendeur ": " ",
                            "Quantité ": " ",
                            "Total Valeur (€) ": " ",
                        });
                    });
                    newDataToExport['Les Articles Annulés'] = arrayArticles_annules
                }

                if (this.exportTo["articles_offerts"]?.data?.length > 0) {
                    const arrayArticles_offerts = []
                    this.exportTo["articles_offerts"]?.data?.map((item, i) => {
                        arrayArticles_offerts.push({
                            "Nombre d'articles ": item?.ArticlesOfferts?.length || 0,
                            "Vendeur ": item?._id,
                            "Quantité ": item?.QuantityVendeur,
                            "Total Valeur (€) ": item?.TotalValeurVendeur?.toFixed(2) + " €",
                        })
                        if (item?.ArticlesOfferts?.length > 0) {
                            arrayArticles_offerts.push({
                                "Nombre d'articles ": "La Caisse ",
                                "Vendeur ": "Libelle ",
                                "Quantité ": "Quantité ",
                                "Total Valeur (€) ": "Total Valeur (€) ",
                            });

                            item?.ArticlesOfferts?.map((fm, i) => {
                                arrayArticles_offerts.push({
                                    "Nombre d'articles ": fm?.LibCaisse,
                                    "Vendeur ": fm?.libelle,
                                    "Quantité ": fm?.QuantityLibelle,
                                    "Total Valeur (€) ": fm?.TotalValeurLibelle,
                                });
                            });
                        }
                        arrayArticles_offerts.push({
                            "Nombre d'articles ": " ",
                            "Vendeur ": " ",
                            "Quantité ": " ",
                            "Total Valeur (€) ": " ",
                        });
                    });
                    newDataToExport['Les Articles Offerts'] = arrayArticles_offerts
                }
                if (this.exportTo["articles_remises"]?.data?.length > 0) {
                    const arrayArticles_remises = []
                    this.exportTo["articles_remises"]?.data?.map((item, i) => {
                        arrayArticles_remises.push({
                            "Nombre d'articles ": item?.ArticlesRemises?.length || 0,
                            "Vendeur ": item?._id,
                            "Taux ": item?.TauxVendeur,
                            "Total Valeur (€) ": item?.TotalValeurVendeur?.toFixed(2) + " €",
                        })
                        if (item?.ArticlesRemises?.length > 0) {
                            arrayArticles_remises.push({
                                "Nombre d'articles ": "La Caisse ",
                                "Vendeur ": "Libelle ",
                                "Taux ": "Taux ",
                                "Total Valeur (€) ": "Total Valeur (€) ",
                            });

                            item?.ArticlesRemises?.map((fm, i) => {
                                arrayArticles_remises.push({
                                    "Nombre d'articles ": fm?.LibCaisse,
                                    "Vendeur ": fm?.libelle,
                                    "Taux ": fm?.TotalTaux,
                                    "Total Valeur (€) ": fm?.TotalValeurLibelle,
                                });
                            });
                        }
                        arrayArticles_remises.push({
                            "Nombre d'articles ": " ",
                            "Vendeur ": " ",
                            "Taux ": " ",
                            "Total Valeur (€) ": " ",
                        });
                    });
                    newDataToExport['Les Articles Remises'] = arrayArticles_remises
                }

                if (this.exportTo["revenu_statistques"]?.data?.length > 0) {

                    const arrayRevenuStatistiques = []
                    this.exportTo["revenu_statistques"]?.data?.map((item, i) => {
                        arrayRevenuStatistiques.push({
                            "Type ": item?.LibFamille,
                            "La Caisse ": item?._id?.LibCaisse,
                            "Vendeur ": item?._id?.LibVendeur,
                            "Libelle ": item?._id?.Libelle,
                            "Quantité ": item?.QteTotal,
                            "Total Valeur (€) ": item?.TotalValeur?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['Revenu par statistiques'] = arrayRevenuStatistiques
                }
                return newDataToExport
            } catch (error) {
                return []
            }
        }


    },
    mounted() { },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    }
};
</script>


<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>