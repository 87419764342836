<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-8 ps-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <a href="#" class="noble-ui-logo d-block mb-2">Modifier<span>&nbsp;le mot de passe</span></a>
                <form class="forms-sample" @submit.prevent="SubmitData">
                  <div class="row w-100">
                    <div class="col-md-12 mb-3">
                      <div class="">
                        <label for="userPassword" class="form-label">Mot de passe actuel</label>
                        <input type="password" class="form-control" id="userPassword" autocomplete="current-password"
                          placeholder="Mot de passe actuel" v-model="form.password" />
                      </div>
                      <div class="errors-response" v-if="v$.form.password.$error">
                        <p v-if="!v$.form.password.required.$response">{{ $t('validation.required') }}</p>
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <div class="">
                        <label for="userPassword" class="form-label">Nouveau mot de passe</label>
                        <input type="password" class="form-control" id="userPassword" autocomplete="current-password"
                          placeholder="Nouveau mot de passe" v-model="form.newpassword" />
                      </div>
                      <div class="errors-response" v-if="v$.form.newpassword.$error">
                        <p v-if="!v$.form.newpassword.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.newpassword.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'Mot de passe', min: v$.form.newpassword.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.newpassword.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'Mot de passe', max: v$.form.newpassword.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="">
                        <label for="ConfirmPassword" class="form-label">Confirmer le mot de passe</label>
                        <input type="password" class="form-control" id="ConfirmPassword" autocomplete="confirm-password"
                          placeholder="Confirmer le mot de passe" v-model="form.cnfpassword" />
                      </div>
                      <div class="errors-response" v-if="v$.form.cnfpassword.$error">
                        <p v-if="!v$.form.cnfpassword.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.cnfpassword.sameAsPassword.$response">
                          {{ $t('validation.password') }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5">
                      <button type="submit" class="btn btn-primary text-white w-100">
                        Modifier</button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, sameAs } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "EditpasswordView",
  computed: {
    ...mapGetters({ User: "StateUser" }),
  },
  data() {
    return {
      form: {
        password: "",
        newpassword: "",
        cnfpassword: "",
      }
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        password: {
          required
        },
        newpassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(20)
        },
        cnfpassword: {
          required: requiredIf(function () {
            return (this.form.newpassword != '')
          }),
          sameAsPassword: sameAs(this.form.newpassword)
        },

      }
    };
  },
  methods: {

    ...mapActions(["updatePassword"]),
    async SubmitData() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const passwordObj = {
          username: this.User.username,
          password: this.form.password,
          newpassword: this.form.newpassword,
        };
        const result = await this.updatePassword(passwordObj);
        if (result)
          this.$router.push({ name: "profile" });
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
