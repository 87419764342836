<!-- eslint-disable -->
<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <ul v-if="$store?.state?.managerWithStores && isRole == 'manager'" class="sidebar-toggler nav pt-1 w-100">
        <div class="btn-group me-2 w-100">
          <div class="custom-selects">
            <div class="selected-options" @click="toggleDropdown">

              <div v-if="currentSite?.sites?.length > 1">
                {{
                  $store.state.managerWithStores?.SocieteName?.toUpperCase() ||
                  $store.state.managerWithStores?.username?.toUpperCase()
                }}
              </div>
              <div v-else-if="currentSite?.LibSite">
                {{
                  currentSite?.LibSite.toUpperCase()
                }}
              </div>

              <div>
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
              </div>
            </div>
            <ul class="dropdown-lists" v-show="showDropdown">
              <li class="libel">
                <div class="title"> Multi-sites</div>
              </li>
              <li class="list-sites">
                <div @click="selectOption($store.state.managerWithStores, 'allsites')" class="multisites">
                  {{
                    $store.state.managerWithStores?.SocieteName?.toUpperCase() ||
                    $store.state.managerWithStores?.username?.toUpperCase()
                  }}
                </div>
              </li>
              <li class="libel">
                <div class="title"> Sites</div>
              </li>
              <li class="search-item">
                <input class="form-control" type="text" v-model="searchQuery" placeholder="Recherche par LibSite">
              </li>
              <li v-if="$store?.state?.managerWithStores?.associatedRestaurants" class="list-sites"
                v-for="(site, index) in filteredSites" v-bind:key="index">
                <div class="lib-site" @click="selectSites(site)">

                  <div :class="{ 'selected': selectedSitesInfos.some(s => s?._id === site?._id) }" class="check-icon">
                    <i class=" fa fa-check" aria-hidden="true"></i>
                  </div>
                  {{
                    site?.LibSite?.toUpperCase()
                  }}

                </div>
              </li>
              <div @click="selectOption($store.state.managerWithStores, 'multisites')" class="btn-valid-sites">
                Valider
              </div>
            </ul>
          </div>
        </div>
      </ul>
    </div>
    <div class="sidebar-body ">
      <ul
        v-if="$store.state.managerWithStores?.associatedRestaurants?.length > 0 && StateIsAbonnementActif && isRole == 'manager'"
        class="nav">
        <li class="nav-item nav-category mt-4">Tickets</li>
        <li class="nav-item">
          <router-link :to="{ name: 'dashboard' }" class="nav-link">
            <span class="link-title">Tableau de bord</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'tickets' }" class="nav-link">
            <span class="link-title">Contrôle</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'reports' }" class="nav-link">
            <span class="link-title">Rapport</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'statistiques' }" class="nav-link">
            <span class="link-title">Statistiques</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comptes' }" class="nav-link">
            <span class="link-title">Comptes</span>
          </router-link>
        </li>


      </ul>
      <ul v-if="isRole == 'manager'" class="nav mt-3">
        <li class="nav-item nav-category mt-4">Restaurants</li>
        <li class="nav-item">
          <router-link :to="{ name: 'chiffre-affaires' }" class="nav-link">
            <span class="link-title">CA par restaurant </span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'list-restaurants' }" class="nav-link">
            <span class="link-title">Mes restaurants</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a href="/creation-restaurant" class="nav-link">
            <span class="link-title">Ajouter Restaurant</span>
          </a>
        </li>

      </ul>
      <ul v-if="isRole == 'manager'" class="nav mt-3">
        <li class="nav-item nav-category mt-4">Vendeurs</li>
        <li class="nav-item">
          <router-link :to="{ name: 'vendeurs' }" class="nav-link">
            <span class="link-title">Liste des vendeurs</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'pointeuse' }" class="nav-link">
            <span class="link-title">Pointeuse</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="this.$store.state.roles.includes(this.isRole) && isRole != 'manager' && isRole != 'admin'" class="nav">
        <li v-if="isRole == 'super-admin'" class="nav-item nav-category mt-4">Admin</li>
        <li v-if="isRole == 'super-admin'" class="nav-item">
          <router-link to="/admins" class="nav-link">
            <span class="link-title">Admin</span>
          </router-link>
        </li>
        <li v-if="isRole == 'super-admin'" class="nav-item">
          <router-link to="/addadmin" class="nav-link">
            <span class="link-title">Ajouter Admin</span>
          </router-link>
        </li>
        <li class="nav-item nav-category mt-4">Gestion des clients</li>
        <li class="nav-item">
          <router-link to="/managers" class="nav-link">
            <span class="link-title">Clients</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/subscriptions-csicodes" class="nav-link">
            <span class="link-title">subscriptions-csicodes</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/subscriptions-paid" class="nav-link">
            <span class="link-title">subscriptions-paid</span>
          </router-link>
        </li>
        <li class="nav-item nav-category mt-4">Gestion des abonnements</li>
        <li class="nav-item">
          <router-link :to="{ name: 'Abonnements' }" class="nav-link">
            <span class="link-title">Abonnements</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Codecsi' }" class="nav-link">
            <!-- <i class="link-icon" data-feather="plus-square"></i> -->
            <span class="link-title">Codes CSI</span>
          </router-link>
        </li>
      </ul>
      <a class=" position-absolute bottom-0 badge-version" href="/">
        <!-- <img v-if="parametrages?.Logo" :src="BaseUrl + parametrages.Logo" class="badge-version logo d-inline-block" alt="logo" /> -->
        <img src="../../public/assets/img/logo_dark.png" class="  logo d-block" alt="logo" />
        <span class="cloud-version">
          {{ 'V' + appVersion }}
        </span>
      </a>
    </div>
  </nav>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SideBar",
  computed: {
    ...mapGetters({ ManagerWithStore: "StateManagerWithStore", StateUser: "StateUser", isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", currentSite: "currentSite", parametrages: "StateParametrages", StateIsAbonnementActif: "StateIsAbonnementActif" }),
    ...mapState({ appVersion: 'appVersion' }),

    filteredSites() {
      const query = this.searchQuery.toLowerCase();
      return this.$store.state.managerWithStores?.associatedRestaurants.filter(site => {
        return site?.LibSite?.toLowerCase().includes(query);
      });
    }
  },
  mounted() {
    const get = async () => {
      // 

      // this.selectedSitesID = Array.isArray(this.currentSite?._id)
      //   ? this.currentSite._id
      //   : this.currentSite?._id
      //     ? [this.currentSite._id]
      //     : [];
      if (this.currentSite?.sites) {
        if (this.currentSite?.sites?.length > 0) {
          this.selectedSitesInfos = this.currentSite?.sites
        }
      } else {
        this.selectedSitesInfos = [this.currentSite] //?.sites
      }
      // this.selectedSitesInfos = Array.isArray(this.currentSite)
      //   ? this.currentSite?.sites
      //   : this.currentSite?.sites
      //     ? this.currentSite?.sites
      //     : [];


      this.$store.state.managerWithStores = null
      await this.mangerWithAssociatedRestaurants({
        url: "manager/associated-restaurants",
        userID: this.StateUser?._id
      })
      if (this.$store.state.selectedSite) {
        const selectedStore = this.$store.state.managerWithStores?.associatedRestaurants?.filter((restaurant) => restaurant?._id == this.$store.state.selectedSite)
        if (selectedStore?.length > 0) {
          this.selectOption(selectedStore[0], 'site')
        }
      } else {
        // if (this.currentSite != undefined && this.currentSite?._id != "site") {
        //   this.selectedOption = (this.currentSite != undefined) ? this.currentSite?.LibSite : null
        // }

        if (this.$store.state.managerWithStores?.associatedRestaurants?.length >= 0 && this.$store.state.currentSite == null) {
          this.selectOption(this.$store.state.managerWithStores?.associatedRestaurants[0], 'site')
        }
      }




      if (this.currentSite?._id) {
        this.getParametrages(this.currentSite?._id).then(res => {
          let debut = res?.calculeTicket?.TimeDebut || null
          let fin = res?.calculeTicket?.TimeFin || null
          this.logo = this.BaseUrl + "" + res?.Logo || null
          if (debut != null) {
            const [hoursDebut, minutesDebut] = (debut.toString()).split(":");
            const dateDebut = new Date();
            dateDebut.setHours(parseInt(hoursDebut, 10));
            dateDebut.setMinutes(parseInt(minutesDebut, 10));
            this.TimeDebut = {
              hours: dateDebut.getHours(),
              minutes: dateDebut.getMinutes()
            }
          }
          if (fin != null) {
            const [hoursFin, minutesFin] = (fin.toString()).split(":");
            const dateFin = new Date();
            dateFin.setHours(parseInt(hoursFin, 10));
            dateFin.setMinutes(parseInt(minutesFin, 10));
            this.TimeFin = {
              hours: dateFin.getHours(),
              minutes: dateFin.getMinutes()
            }
          }
        })
      }

    };
    if (this.isAuthenticated && this.isRole == 'manager') {
      get();
    }
  },
  data() {
    return {
      defaultVal: [0, "vendeur"],
      showDropdown: false,
      selectedOption: null, //this.$libvendeur ? this.$libvendeur : "vendeur"
      abonnementActif: null,
      selectedSitesID: [],
      selectedSitesInfos: [],

      searchQuery: '',
    };
  },
  methods: {
    onChangeSelect(event) {
      this.restaurant = event.target.value.split(",");
      localStorage.setItem("libvendeur", event.target.value.split(",")[1]);
      location.reload();
      this.getRes(event.target.value.split(",")[1].toUpperCase());
    },
    ...mapActions(["LogOut", "getAllRestaurant", "getSitesWithAssociatedVendeurs", "mangerWithAssociatedRestaurants", "getRes", "selectedLibVendeur", "selectedCurrentSite", "getParametrages", "getAllParentSites"]),
    async logout() {
      try {
        await this.LogOut();
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async selectOption(site, type) {
      if (type == "site") {
        if (site?.LibSite) {
          this.showDropdown = false;
          site.type = type
          const LibSite = site
          this.selectedCurrentSite(LibSite)
        }
      } else if (type == "multisites") {
        this.showDropdown = false;
        /** select one site */
        if (this.selectedSitesInfos?.length === 1) {
          this.selectedCurrentSite(this.selectedSitesInfos[0])
        }
        /** select diffrent sites */
        if (this.selectedSitesInfos?.length > 1) {
          const selectedSitesIDs = this.selectedSitesInfos.map(site => site._id);
          console.log(selectedSitesIDs)
          let parent = {
            name: site?.SocieteName || site?.username,
            LibSite: site?.SocieteName || site?.username,
            _id: selectedSitesIDs,
            sites: this.selectedSitesInfos,
            type: type,
            latestAbonnemnt: { status: 'actif' },
          }
          this.selectedCurrentSite(parent)
        }
      }
      else if (type == "allsites") {
        this.showDropdown = false;
        /** select all sites */
        if (site?.associatedRestaurants && site?.restaurant) {
          let parent = {
            name: site?.SocieteName || site?.username,
            LibSite: site?.SocieteName || site?.username,
            _id: site?.restaurant,
            sites: site?.associatedRestaurants,
            type: type,
            latestAbonnemnt: { status: 'actif' },
          }
          this.selectedCurrentSite(parent)
        }
      }

    },
    selectSites(site) {
      const index = this.selectedSitesInfos.findIndex(s => s._id === site._id);
      console.log(index)
      //const index = this.selectedSitesID.indexOf(site._id);
      if (index === -1) {
        // this.selectedSitesID.push(site._id);
        this.selectedSitesInfos.push({
          _id: site?._id,
          LibSite: site?.LibSite,
          EmetteurSociete: site?.EmetteurSociete,
          EmetteurAdresse: site?.EmetteurAdresse,
          EmetteurCodePostal: site?.EmetteurCodePostal,
          EmetteurVille: site?.EmetteurVille,
          EmetteurPays: site?.EmetteurPays,
          EmetteurTelephone: site?.EmetteurTelephone,
          EmetteurNumTVA: site?.EmetteurNumTVA,
          EmetteurSiret: site?.EmetteurSiret,
          latestAbonnemnt: site?.latestAbonnemnt, abonnements: site?.abonnements
        });
      } else {
        // this.selectedSitesID.splice(index, 1);
        this.selectedSitesInfos.splice(index, 1);
        //    console.log(indexS)
      }
    }
  },

};
</script>
<!-- eslint-disable -->
<style>
.custom-selects {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-lists .multisites {
  background-color: #084593;
}

.dropdown-lists .multisites span {
  color: #fff;
}

.dropdown-lists .selected {
  background: green !important;
}

.selected-options {
  padding: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
  border: 0.15rem solid #084593;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-lists {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 350px;
  overflow-y: auto;
  z-index: 9;
}

.dropdown-lists li .title {
  padding: 8px;
  max-width: 100%;
  width: 250px;
  word-wrap: break-word;
}

.dropdown-lists li .multisites {
  padding: 10px;
  cursor: pointer;
  max-width: 100%;
  width: 250px;
  word-wrap: break-word;
  color: #fff;

}

.search-item {
  padding: 5px;
}

.dropdown-lists li .lib-site {
  padding: 10px;
  cursor: pointer;
  max-width: 100%;
  width: 250px;
  word-wrap: break-word;
  border-bottom: 1px solid #c1c1c1;
  color: #000000d9;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdown-lists span {
  position: relative;
  display: block;
  min-height: 28px;
  padding: 8px 0 0px 4px;
  color: #999999d9;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.list-vendeurs {
  padding-left: 20px !important;
  color: #2d2d2dd9;
  font-weight: 600 !important;
  font-size: 10px;
  line-height: 18px;
  cursor: pointer !important;
  text-transform: uppercase;
  z-index: 999;
}

.list-sites {
  margin: 0px;
  color: #000000d9;
  font-weight: 600;
  font-size: 12px;

}


.list-sites:hover {
  background-color: #f2f2f2 !important;
}

.list-vendeurs:hover {
  background-color: gray !important;
}

.libel {
  color: #00000073;
  font-size: 12px;
  cursor: default;
}





.btn-valid-sites {
  margin: 0px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  background: #084593 !important;
}

.dropdown-lists .fake-selected {
  background: #3e6eff !important;
  color: #fff;
}

/***logo & version */
.badge-version {
  padding: 0.35em 0.65em;

}

.badge-version .logo {
  display: inline-block;

  font-size: 0.8em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  width: 60px !important;
}

.cloud-version {
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  color: #000865;
  display: block;
}

.check-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: #dbdbdb;
}
</style>