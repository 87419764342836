<!-- eslint-disable -->
<template>
  <div class="float-end ">
    <span class="generate-to-file pdf" @click="generatePdf"><i class="far fa-file-pdf"></i></span>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  props: ["dateFrom", "dateTo", "vendeur", "exportTo"],
  components: {
    //Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    async generatePdf() {
      let result = [];

      await this.exportTo.data.map((el) => {
        result.push([
          el.LibVendeur,
          el.date,
          el.timeStartWork,
          el.timeEndWork,
          el.duration,
        ]);
      });
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
      });



      let startY = 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var text = "Pointeuse";
      doc.text(text, pageWidth / 2, startY, { align: "center" });

      startY += 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var text = "Du " + this.$moment(String(this.dateFrom)).utc().format("MM-DD-YYYY HH:mm") + " au " + this.$moment(String(this.dateTo)).utc().format("MM-DD-YYYY HH:mm")
      doc.text(text, pageWidth / 2, startY, { align: "center" });






      startY += 15;
      autoTable(doc, {
        margin: { top: 30 },
        startY: startY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [17, 69, 108],
          textColor: [255, 255, 255],
          cellPadding: 4,
        },
        bodyStyles: { cellPadding: 3 },
        head: [this.exportTo.columns],
        body: result,
      });

      doc.save(this.exportTo.fileName);
    },
  },
  mounted() { },
};
</script>
