<!-- eslint-disable -->
<template>
  <div>
    <div>
      <span class="generate-to-file pdf" @click="generatePdf"><i class="far fa-file-pdf"></i></span>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  props: ["dateFrom", "dateTo", "vendeur", "exportTo"],
  components: {
    //Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    async generatePdf() {
      let result = [];

      if (this.vendeur.length == 1 && this.vendeur[0] != "vendeur") {
        await this.exportTo.data.map((el) => {
          result.push([
            el.Libelle,
            el.QteTotal.toString(),
            el.TotalTTC.toFixed(2).toString() + " €",
          ]);
        });
      } else {
        await this.exportTo.data.map((el) => {
          result.push([
            el.LibVendeur,
            el.Libelle,
            el.QteTotal.toString(),
            el.TotalTTC.toFixed(2).toString() + " €",
          ]);
        });
      }

      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
      });

      let startY = 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var text = "VENTES PAR VENDEUR";
      doc.text(text, pageWidth / 2, startY, { align: "center" });

      startY += 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE D'ÉDITION", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : " + this.$moment(String(new Date())).utc().format("MM-DD-YYYY HH:mm:ss"),
        44,
        startY,
        "left"
      );

      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE DEBUT", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateFrom, 44, startY, "left");

      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE FIN", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateTo, 44, startY, "left");

      if (this.vendeur.length == 1 && this.vendeur[0] != "vendeur") {
        startY += 6;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text("VENDEUR", 15, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + this.vendeur[0], 44, startY, "left");
      }

      startY += 15;
      autoTable(doc, {
        // styles: { fillColor: [255, 0, 0] },
        //columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 30 },
        startY: startY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [17, 69, 108],
          textColor: [255, 255, 255],
          cellPadding: 4,
        },
        bodyStyles: { cellPadding: 3 },
        head: [this.exportTo.columns],
        body: result,
      });

      doc.save(this.exportTo.fileName);
    },
  },
  mounted() { },
};
</script>
