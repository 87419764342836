<!-- eslint-disable -->
<template>
  <footer
    class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
    <p class="text-muted mb-1 mb-md-0">Copyright © {{ new Date().getFullYear() }} <a href="https://guestwaiter.com/"
        target="_blank">Guestwaiter</a>.
    </p>
  </footer>
</template>
<script>
export default {
  name: "FooTer",
};
</script>
