/*eslint-disable*/
import { createI18n } from "vue-i18n";
import Locale from "./fr.js";

const lang = "fr";
const i18n = createI18n({
    locale: lang,
    messages: Locale,
});

export default i18n;