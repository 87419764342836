<!-- eslint-disable -->
<template>
  <div class="main-wrapper">
    <div class="page-wrapper full-page">
      <div class="page-content d-flex align-items-center justify-content-center">
        <div class="row w-100 mx-0 auth-page d-flex align-items-center justify-content-center">
          <div class="col-sm-6  col-md-3 col-xl-5 col-lg-4 mx-auto text-center">
            <img src="../../../public/assets/img/logo_white.png" width="120" height="120" class="badge d-inline-block"
              alt="logo" />
            <h5 class="text-muteds fw-normal mb-4 mt-2">
              Création du restaurant
            </h5>
            <div class="mt-4 mb-4">
              <a href="/">
                Retour au tableau de bord
              </a>
            </div>
          </div>
          <div class="col-sm-6  col-md-9 col-xl-7 col-lg-6 mx-auto">
            <div class="card">
              <div class="row">
                <div class="col-md-12 stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <a href="#" class="noble-ui-logo text-center d-block mb-2">Création du<span>&nbsp;
                          restaurant</span></a>

                      <!-- <form @submit.prevent="sendMail">
                        <button type="submit" class="btn btn-primary submit">
                          Créer
                        </button>
                      </form> -->

                      <form @submit.prevent="SubmitData">

                        <div class="row  mt-3 mb-3 ">
                          <div class="col-md-12 mb-3 ">
                            <h5>Type d'abonnement</h5>
                          </div>
                          <div class="col-md-12 mb-3 ">
                            <div class="d-flex justify-content align-items-end">
                              <div class="form-check  me-4">
                                <input class="form-check-input" type="radio" id="abonnement" v-model="selectedType"
                                  value="abonnement">
                                <label class="form-check-label" for="abonnement">Direct</label>
                              </div>
                              <div class="form-check  me-4">
                                <input class="form-check-input" type="radio" id="codecsi" v-model="selectedType"
                                  value="codecsi">
                                <label class="form-check-label" for="codecsi">Code CSI</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="selectedType == 'abonnement'" class="row mb-3 section-abonnement ">
                          <div class="col-md-12 mb-3">
                            <div class="">
                              <h5>Direct : </h5>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="d-flex justify-content align-items-end flex-wrap grid-margin">
                              <span v-for="(abonnement, index) in abonnements" class="type_ca float-end">
                                <span @click="updateDates(abonnement)" type="button"
                                  class="type-abonnement btn-icon-text me-3 mb-3 mb-md-0 "
                                  :class="{ 'selected-default-dates': abonnement.nbMonths == selectedAbonnement?.nbMonths }">{{
                                    abonnement?.libel }}</span>
                              </span>
                            </div>
                            <div class="w-100">
                              <p> <b>Date de début</b> : {{ dateDebut }}</p>
                              <p> <b>Date de fin</b> : {{ dateFin }}</p>
                              <p> <b>Prix HT</b> : {{ selectedAbonnement?.price?.toFixed(2) }} €</p>
                              <p> <b>TVA</b> : 20%</p>
                              <p> <b>Prix TTC</b> : {{ (selectedAbonnement?.price * (1 + 0.2)).toFixed(2)
                                }} €</p>
                            </div>
                          </div>

                        </div>

                        <div v-if="selectedType == 'codecsi'" class="row mb-3  section-abonnement">
                          <div class="col-md-12 mb-3">
                            <div class="">
                              <h5>Code CSI : </h5>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <input type="text" class="form-control" id="codecsi" placeholder="Code CSI"
                              v-model="codecsi" />
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12 mb-3">
                            <div class="">
                              <label class="form-label">Nom du Restaurant *</label>
                              <input type="text" class="form-control" placeholder="Nom du Restaurant *"
                                v-model="form.name" />
                            </div>
                            <div class="errors-response" v-if="v$.form.name.$error">
                              <p v-if="!v$.form.name.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.name.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'name', min: v$.form.name.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.name.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'name', max: v$.form.name.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Societe</label>
                              <input type="text" class="form-control" placeholder="Societe"
                                v-model="form.EmetteurSociete" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurSociete.$error">
                              <p v-if="!v$.form.EmetteurSociete.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurSociete.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Nom de societe', min: v$.form.EmetteurSociete.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurSociete.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Nom de societe', max: v$.form.EmetteurSociete.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Adresse</label>
                              <input type="text" class="form-control" placeholder="Adresse"
                                v-model="form.EmetteurAdresse" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurAdresse.$error">
                              <p v-if="!v$.form.EmetteurAdresse.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurAdresse.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Adresse', min: v$.form.EmetteurAdresse.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurAdresse.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Adresse', max: v$.form.EmetteurAdresse.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>

                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Ville</label>
                              <input type="text" class="form-control" placeholder="Entrez la ville"
                                v-model="form.EmetteurVille" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurVille.$error">
                              <p v-if="!v$.form.EmetteurVille.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurVille.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Ville', min: v$.form.EmetteurVille.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurVille.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Ville', max: v$.form.EmetteurVille.maxLength.$params.max
                                }) }}
                              </p>
                            </div>

                          </div>
                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Code Postal</label>
                              <input type="text" class="form-control" placeholder="Entrez le Code Postal"
                                v-model="form.EmetteurCodePostal" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurCodePostal.$error">
                              <p v-if="!v$.form.EmetteurCodePostal.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurCodePostal.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Code postale', min: v$.form.EmetteurCodePostal.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurCodePostal.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Code postale', max: v$.form.EmetteurCodePostal.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Pays</label>
                              <input type="text" class="form-control" placeholder="Pays" v-model="form.EmetteurPays" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurPays.$error">
                              <p v-if="!v$.form.EmetteurPays.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurPays.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Pays', min: v$.form.EmetteurPays.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurPays.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Pays', max: v$.form.EmetteurPays.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                          <div class="col-sm-4 mb-3">
                            <div class="">
                              <label class="form-label">Numér TVA</label>
                              <input type="text" class="form-control" placeholder="NuméroTVA"
                                v-model="form.EmetteurNumTVA" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurNumTVA.$error">
                              <p v-if="!v$.form.EmetteurNumTVA.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurNumTVA.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Numéro de TVA', min: v$.form.EmetteurNumTVA.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurNumTVA.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Numéro de TVA', max: v$.form.EmetteurNumTVA.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <div class="">
                              <label class="form-label">Siret</label>
                              <input type="text" class="form-control" placeholder="Siret"
                                v-model="form.EmetteurSiret" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurSiret.$error">
                              <p v-if="!v$.form.EmetteurSiret.required.$response">{{ $t('validation.required') }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurSiret.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Numéro siret', min: v$.form.EmetteurSiret.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurSiret.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Numéro siret', max: v$.form.EmetteurSiret.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="">
                              <label class="form-label">Etablissement</label>
                              <input type="text" class="form-control" placeholder="Etablissement"
                                v-model="form.EmetteurEtablissement" />
                            </div>
                            <div class="errors-response" v-if="v$.form.EmetteurEtablissement.$error">
                              <p v-if="!v$.form.EmetteurEtablissement.required.$response">{{ $t('validation.required')
                                }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurEtablissement.minLength.$response">
                                {{ $t('validation.min.string', {
                                  attribute: 'Etablissement', min: v$.form.EmetteurEtablissement.minLength.$params.min
                                }) }}
                              </p>
                              <p class="error" v-if="!v$.form.EmetteurEtablissement.maxLength.$response">
                                {{ $t('validation.max.string', {
                                  attribute: 'Etablissement', max: v$.form.EmetteurEtablissement.maxLength.$params.max
                                }) }}
                              </p>
                            </div>
                          </div>
                          <!-- <div class="col-md-12 mt-3 mb-3">
                        <div class="">
                          <h4>Abonnement : </h4>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Date début</label>
                          <VueDatePicker locale="fr" v-model="dateDebut"
                            :action-row="{ showSelect: false, showCancel: false }" close-on-scroll auto-apply
                            show-now-button date-picker />
                        </div>
                      </div>
                     <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Date fin</label>
                            <VueDatePicker locale="fr" v-model="dateFin"
                            :action-row="{ showSelect: false, showCancel: false }" close-on-scroll auto-apply
                            show-now-button date-picker />  
                        </div>
                      </div> -->





                          <div class="col-sm-12 mb-3">
                            <div class="w-50 float-end">
                              <div class="form-label">&nbsp;</div>
                              <button type="submit" class=" w-100 btn btn-primary submit">
                                Créer
                              </button>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "AddrestaurantView",
  components: { VueDatePicker },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser" }),
    ...mapState({})
  },
  watch: {
    selectedType(newv, oldv) {
      if (newv == 'abonnement') {
        this.codecsi = null
        this.selectedAbonnement = null
        this.abonnement = []
        if (this.abonnements?.length > 0) {
          this.updateDates(this.abonnements[0])
        }
      } else if (newv == 'codecsi') {
        this.codecsi = null
        this.selectedAbonnement = null
        this.abonnement = []

      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurSociete: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurAdresse: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(500)
        },
        EmetteurVille: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurCodePostal: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurEtablissement: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(500)
        },
        EmetteurNumTVA: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(200)
        },
        EmetteurSiret: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(250)
        },
        EmetteurPays: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(250)
        },
      }
    };
  },
  mounted() {
    const get = async () => {
      try {
        this.fetchAbonnements()
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          alertSwal("error", "Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      users: [],
      form: {
        name: "",
        EmetteurSociete: "",
        EmetteurAdresse: "",
        EmetteurVille: "",
        EmetteurCodePostal: "",
        EmetteurEtablissement: "",
        EmetteurNumTVA: "",
        EmetteurSiret: "",
        EmetteurPays: "",
      },

      dateDebut: null,
      dateFin: null,
      show: false,
      abonnements: [],
      codecsi: null,
      selectedAbonnement: null,
      selectedType: 'abonnement',
      abonnement: []
    };
  },
  methods: {
    ...mapMutations(["setRenouvelerSite"]),
    ...mapActions(["createRestaurantByManeger", "searchAbonnements", "sendMailjet"]),
    async fetchAbonnements() {
      const searchData = {}
      searchData['isEnabled'] = true
      this.abonnements = await this.searchAbonnements(searchData);
      if (this.abonnements?.length > 0) {
        this.updateDates(this.abonnements[0])
      }
    },
    updateDates(abonnement) {
      if (abonnement) {
        const currentDate = new Date();
        this.dateDebut = currentDate.toLocaleDateString()
        this.selectedAbonnement = abonnement
        const dateFin = new Date(currentDate);
        dateFin.setDate(currentDate.getDate() + (abonnement?.nbMonths * 30));
        this.dateFin = dateFin.toLocaleDateString();
      }

    },

    async sendMail() {
      const result = await this.sendMailjet();
    },

    async SubmitData() {

      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        this.dateDebut = new Date().toLocaleDateString()
        const parsedDateDebut = this.$moment(this.dateDebut, 'DD/MM/YYYY');
        const isoDateDebut = parsedDateDebut.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const parsedDateFin = this.$moment(this.dateFin, 'DD/MM/YYYY');
        const isoDateFin = parsedDateFin.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        if (this.selectedType == 'abonnement') {
          if (!this.selectedAbonnement?._id) {
            alertSwal("error", "abonnement est obligatoire");
            return;
          }
          this.abonnement = [
            {
              dateDebut: isoDateDebut,
              dateFin: isoDateFin,
              motif: '',
              closed: false,
              isActive: false,
              abonnementID: this.selectedAbonnement?._id || null,
              nbMonths: this.selectedAbonnement?.nbMonths || null,
              price: Math.round((this.selectedAbonnement?.price * (1 + 0.2)) * 100) || null,
              codecsi: null,
            }
          ]
        } else if (this.selectedType == 'codecsi') {
          if (!this.codecsi || this.codecsi == null) {
            alertSwal("error", "code CSI est obligatoire");
            return;
          }
          this.abonnement = [
            {
              dateDebut: isoDateDebut,
              dateFin: isoDateFin,
              motif: '',
              closed: false,
              isActive: false,
              abonnementID: null,
              nbMonths: null,
              price: null,
              codecsi: this.codecsi || null,
            }
          ]
        }
        const restaurant = {
          name: this.form.name.trim(),
          LibSite: this.form.name.trim(),
          EmetteurSociete: this.form.EmetteurSociete.trim(),
          EmetteurAdresse: this.form.EmetteurAdresse.trim(),
          EmetteurVille: this.form.EmetteurVille.trim(),
          EmetteurCodePostal: this.form.EmetteurCodePostal.trim(),
          EmetteurEtablissement: this.form.EmetteurEtablissement.trim(),
          EmetteurNumTVA: this.form.EmetteurNumTVA.trim(),
          EmetteurSiret: this.form.EmetteurSiret.trim(),
          EmetteurPays: this.form.EmetteurPays.trim(),
          abonnements: this.abonnement,
          userID: this.User?._id,
          userEmail: this.User?.email ?? null,
          userName: this.User?.SocieteName ?? null,
        };
        try {
          const result = await this.createRestaurantByManeger(restaurant);
          if (result?.data?.status == 201 || result?.data?.LibSite || result?.data?.url) {
            this.setRenouvelerSite(this.currentSite?._id)
            window.location.href = result?.data?.url ? result?.data?.url : '/';
          } else if (result?.data?.status == 404) {
            alertSwal("error", "code CSI n'existe pas ou déja utilisé");
          } else if (result?.data?.status === 400) {
            if (result?.data?.message) {
              alertSwal("error", this.$t(`errors.` + result?.data?.message));
            }
          }
        } catch (error) {
          console.log(error)
          if (error.response.status === 403) {
            alertSwal("error", "Forbidden");
            return;
          } else if (error.response.status === 404) {
            alertSwal("error", "Introuvable");
            return;
          }
          alertSwal("error", "Please Enter good informations 00");
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
<!-- eslint-disable -->
<style scoped>
.main-wrapper {
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-image: url('../../../public/assets/img/bg2.jpg');

}

.page-wrapper.full-page {
  background: rgba(0, 0, 0, 0.57);
  position: relative;
}

.text-muteds {
  color: #fff;
  font-size: 20px;
}

.section-abonnement {
  background: #08459336;
  border-radius: 15px;
  margin: 10px;
  margin-bottom: 10px;
  padding: 10px;

}


.type-abonnement {
  background: blue;
  color: #fff;
  padding: 5px 24px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  font-weight: 500;
  margin: 3px;
  position: relative;
}
</style>