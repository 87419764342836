<!-- eslint-disable -->
<script setup>
import { reactive } from 'vue'
const props = defineProps({
    EmetteurVille: String
})


const emit = defineEmits(['place-data'])

const searchTerm = reactive({
    query: props.EmetteurVille ?? 'Maroc',
    timeout: null,
    results: null
})

const getWeather = async (id) => {
    const res = await fetch(
        `https://api.weatherapi.com/v1/forecast.json?key=8a59dfc2004a4427848220920240704&q=${searchTerm.query}&lang=fr&days=7&aqi=yes&alerts=yes`
    )

    const data = await res.json()

    emit('place-data', data)

    searchTerm.query = ''
    searchTerm.results = null
}
getWeather()
</script>