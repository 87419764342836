<!-- eslint-disable -->
<template>
  <!-- content of the page -->
  <!-- partial -->
  <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
    <div>
      <h4 class="mb-3 mb-md-0">Bienvenue sur le tableau de bord</h4>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
    <div>
      <button type="button" class="btn btn-info btn-icon-text mx-2 mb-3 mb-md-0" @click="getDateButton('today')">
        Aujourd'hui
      </button>
      <button type="button" class="btn btn-info btn-icon-text mx-1 mb-3 mb-md-0" @click="getDateButton('yesterday')">
        Hier
      </button>
      <button type="button" class="btn btn-info btn-icon-text mx-1 mb-3 mb-md-0" @click="getDateButton('month')">
        Mois
      </button>
      <button type="button" class="btn btn-info btn-icon-text mx-1 mb-3 mb-md-0" @click="getDateButton('year')">
        Année
      </button>
    </div>
    <div class="d-flex align-items-center flex-wrap text-nowrap">
      <div class="p-float-label">
        <Calendar id="date" v-model="date" :showIcon="true" />
      </div>
    </div>
  </div>

  <!-- tables -->

  <div class="row">
    <!-- #1 -->
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center"><code>STAT PRINCIPAL</code></h1>
          <div class="table-responsive">
            <table class="tableClass table table-hover">
              <thead>
                <tr>
                  <th>Family</th>
                  <th>Value</th>
                  <th>Value N -1</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(statp, index) in statprincipal" v-bind:key="index">
                  <td>{{ statp.family }}</td>
                  <td>{{ statp.value }}</td>
                  <td>{{ statp.valuen }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- #2 -->
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center"><code>Paiements</code></h1>
          <div class="table-responsive">
            <table class="tableClass table table-hover">
              <thead>
                <tr>
                  <th>Family</th>
                  <th>Quantity</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(paiement, index) in paiements" v-bind:key="index">
                  <td>{{ paiement.family }}</td>
                  <td>{{ paiement.quantity }}</td>
                  <td>{{ paiement.value }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center"><code>STATISTIQUES</code></h1>
          <div class="table-responsive">
            <table class="tableClass table table-hover">
              <thead>
                <tr>
                  <th>Family</th>
                  <th>Quantity</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(statistique, index) in statistiquesDashboard" v-bind:key="index">
                  <td>{{ statistique.family }}</td>
                  <td>{{ statistique.quantity }}</td>
                  <td>{{ statistique.value }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center"><code>MEILLEURES VENTES</code></h1>
          <div class="table-responsive">
            <table class="tableClass table table-hover">
              <thead>
                <tr>
                  <th>Family</th>
                  <th>Quantity</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(meilleuresvente, index) in meilleuresventes" v-bind:key="index">
                  <td>{{ meilleuresvente.family }}</td>
                  <td>{{ meilleuresvente.quantity }}</td>
                  <td>{{ meilleuresvente.value }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- #3 -->
    <!-- <div class="col-md-3 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h1 class="card-title text-center"><code>MEILLEURES 5 HEURES</code></h1>
                        <div class="table-responsive">
                            <table class="tableClass table table-hover">
                                <thead>
                                    <tr>
                                        <th>Hour</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(meilleurshoure, index) in meilleurshoures" v-bind:key="index">
                                        <td>{{meilleurshoure.hour}}</td>
                                        <td>{{meilleurshoure.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div> -->

  </div>

  <!-- chart -->
  <div class="row">
    <div class="col-12 col-xl-12 grid-margin stretch-card">
      <div class="card overflow-hidden">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline mb-4 mb-md-3">
            <h6 class="card-title mb-0">Vue graphique sur un an</h6>

          </div>
          <div class="row align-items-start">
            <div class="col-md-7">
              <p class="text-muted tx-13 mb-3 mb-md-0">Plus de détails ...
              </p>
            </div>

          </div>
          <Chart type="line" :data="basicData" :options="basicOptions" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardView",
  computed: {
    ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes" }),
  },
  mounted() {
    this.date = this.currentDateTime();
    this.date1 = this.currentDateTime();
    const get = async () => {
      this.users = await this.getAllWaiter();
    };
    if (this.isAuthenticated) {
      get();
    }
    this.getDateButton("today");
  },
  watch: {
    date(value) {
      /* eslint-disable-next-line */
      this.get(this.$dayjs(value).format("MM-DD-YYYY"), this.$dayjs(value).format("MM-DD-YYYY"), "day", this.res);
    },
  },
  data() {
    return {
      users: [],
      statprincipal: [],
      paiements: [],
      meilleurshoures: [],
      statistiquesDashboard: [],
      meilleuresventes: [],
      date: null,
      date1: null,
      basicData: {
        /* eslint-disable-next-line */
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
        ],
      },
      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["getAllByDate", "getAllWaiter", "getDataJson"]),
    currentDateTime() {
      const current = new Date();
      const dateTime = this.$dayjs(current).format("MM-DD-YYYY");
      return dateTime;
    },
    getDateButton(datename) {
      switch (datename) {
        case "yesterday": {
          const yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
          /* eslint-disable-next-line */
          this.get(this.currentDateTime(), this.$dayjs(yesterday).format("MM-DD-YYYY"), "yesterday", this.res);
          break;
        }
        case "month": {
          const month = new Date(Date.now() - 2592000000); // that is: 30 * 24 * 60 * 60 * 1000
          /* eslint-disable-next-line */
          this.get(this.currentDateTime(), this.$dayjs(month).format("MM-DD-YYYY"), "month", this.res);
          break;
        }
        case "year": {
          const year = new Date(Date.now() - 31104000000); // that is: 12 * 30 * 24 * 60 * 60 * 1000
          /* eslint-disable-next-line */
          this.get(this.currentDateTime(), this.$dayjs(year).format("MM-DD-YYYY"), "year", this.res);
          break;
        }
        case "today": {
          const today = new Date(Date.now());
          /* eslint-disable-next-line */
          this.get(this.currentDateTime(), this.$dayjs(today).format("MM-DD-YYYY"), "day", this.res);
          break;
        }
        default:
          break;
      }
    },
    async get(date, date1, check, res) {
      try {
        /* eslint-disable */
        this.statprincipal = await this.getAllByDate({ date, date1, check, url: "statprincipal", res });
        this.paiements = await this.getAllByDate({ date, date1, check, url: "paiements", res });
        this.meilleurshoures = await this.getAllByDate({ date, date1, check, url: "meilleurshoures", res });
        //this.statistiquesDashboard = []
        this.statistiquesDashboard = await this.getAllByDate({ date, date1, check, url: "statistiquesDashboard", res });
        this.meilleuresventes = await this.getAllByDate({ date, date1, check, url: "meilleuresventes", res });

        let datajson = await this.getDataJson();

      } catch (error) {
        console.log(error);
      }
    },

  },

};
</script>
<!-- eslint-disable -->
<style>
.tableClass {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tableClass th,
.tableClass td {
  text-align: left;
  width: 25%;
}

.tableClass tbody {
  display: block;
  overflow: auto;
  height: 200px;
}

.tableClass thead {
  width: 100%;
  display: table;
}
</style>
