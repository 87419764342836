<!-- eslint-disable -->
<template>
  <div v-if="detail">
    <div class="row">
      <div class="col-12 col-xl-12 grid-margin stretch-card">
        <div class="card overflow-hidden">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <div class="d-flex">
                <div class="me-4 btn" @click="exportCSV($event)">
                  <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                  <h5 class="text-center fw-light">Copy</h5>
                </div>
                <download-excel :data="cars" :fields="json_fields">
                  <div class="me-4 btn">
                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                    <h5 class="text-center fw-light">Exc</h5>
                  </div>
                </download-excel>
                <div class="btn" @click="printDocument()">
                  <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                  <h5 class="text-center fw-light">PDF</h5>
                </div>

              </div>
              <div class="me-auto"></div>
              <div class="me-auto"></div>
              <div class="d-flex align-items-center flex-wrap text-nowrap">
                <div class="p-float-label">
                  <Calendar id="date" v-model="date" :showIcon="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- row -->
    <!-- datatable -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive" v-if="show && controlState">
              <DataTable showGridlines id="divToPrint" stripedRows :value="cars" ref="dt" class="p-datatable-lg table"
                :paginator="true" :rows="10" sortMode="multiple" dataKey="id" :rowHover="true" v-model:filters="filters"
                filterDisplay="menu" :loading="false" v-model:selection="selectedProduct" selectionMode="single"
                @rowSelect="onRowSelect"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 25, 50]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll">
                <template #header>
                  <div class="d-flex align-items-center">
                    <h3 class="title col-sm-8">Tickets</h3>
                    <span class="p-input-icon-left col-sm-1">
                      <i class="pi pi-search mx-3" />
                      <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                  </div>
                </template>
                <template #empty>
                  No tickets found.
                </template>
                <template #loading>
                  Loading tickets data. Please wait.
                </template>
                <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                  :sortable="true">
                </Column>
                <template #footer>
                  In total there are {{ cars ? cars.length : 0 }} tickets.
                </template>
              </DataTable>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-else>
              <div class="spinner-border btn-outline-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="col-md-12 justify-content-between align-items-center flex-wrap grid-margin">
      <div class="card">
        <div class="col-md-12">
          <button @click="() => { this.detail = true }" type="button" class="btn btn-outline-primary mx-7">Back</button>
          <button @click="() => { }" type="button" class="btn btn-outline-primary mx-7">Send To email</button>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-baseline">
            <h5 class="card-title mb-0">Restaurant name</h5>
            <div class="dropdown mb-2">
              <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="icon-lg text-muted pb-3px" data-feather="more-horizontal"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-md-12 col-xl-5">
              <h6 class="mb-2 text-muted">Ticket : {{ ticketnumber }}</h6>
              <div class="d-flex align-items-baseline mb-2">
                <p class="text-success text-muted">
                  <span>Telephone : </span>
                  <i data-feather="arrow-up" class="icon-sm mb-1"></i>
                </p>
              </div>
              <h6 class="mb-2 text-muted">Date : </h6>
              <hr />
              <h6 class="mb-2 text-muted">
                Article :
              </h6>
              <p v-for="(ticke, index) in ticket" v-bind:key="index">
                {{ Number(ticke.quantity).toFixed(0) }} {{ ticke.family }}
              </p>
              <hr />
              <h6 class="text-muted">
                Total €
              </h6>
              <hr />
            </div>
            <div class="col-6 col-md-12 col-xl-7">
              <h6 class="mb-2 text-muted">Waiter : {{ waiter }}</h6>
              <div class="d-flex align-items-baseline mb-2">
                <p class="text-success text-muted">
                  <span>+xxxxxxxxxxxxxxxx</span>
                  <i data-feather="arrow-up" class="icon-sm mb-1"></i>
                </p>
              </div>
              <h6 class="mb-2 text-muted">{{ ticketdate }}</h6>
              <hr />
              <h6 class="mb-2 text-muted">
                Price :
              </h6>
              <div v-for="(ticke, index) in ticket" v-bind:key="index">
                {{ ticke.value }} €
              </div>
              <hr />
              <p>
                {{ Number(total).toFixed(2) }} €
              </p>
              <hr />
            </div>
          </div>
          <h6>Merci à bientôt</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

export default {
  name: "ControltView",
  mounted() {
    this.date = this.currentDateTime();
  },
  watch: {
    date(value) {
      this.get(this.$dayjs(value).format("MM-DD-YYYY"));
    },
  },
  data() {
    return {
      /* eslint-disable */
      cars: [],
      detail: true,
      columns: null,
      date: null,
      controlState: [],
      ticket: [],
      ticketnumber: "",
      waiter: "",
      ticketdate: "",
      total: 0,
      selectedProduct: null,
      show: true,
      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      json_fields: {
        "Ticket Number": "ticketnumber",
        "Ticket Value": "ticketvalue",
        "Payment Method": "paymentmethod",
        "Waiter": "waiter",
        "Time": "time",
      },
    };
  },
  created() {
    this.columns = [
      { field: "ticketnumber", header: "Ticket Number" },
      { field: "ticketvalue", header: "Ticket Value" },
      { field: "paymentmethod", header: "Payment Method" },
      { field: "waiter", header: "Waiter" },
      { field: "time", header: "Time" },
    ];
  },
  methods: {
    ...mapActions(["getAllEntete", "getTicketsByDate", "getTicket"]),
    currentDateTime() {
      const current = new Date(Date.now());
      return current;
    },
    async get(date) {
      try {
        // this.controlState = await this.getAllEntete();
        this.cars = await this.getTicketsByDate(date);
        this.show = true;
      } catch (error) {
        this.show = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    printDocument() {
      const pdfTable = document.getElementById('divToPrint');
      let html = htmlToPdfmake(pdfTable.innerHTML);
      html = html.splice(0, 2);
      const documentDefinition = { content: html };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).open();
    },
    async onRowSelect(event) {
      this.ticketnumber = event.data.ticketnumber;
      this.waiter = event.data.waiter;
      this.ticketdate = this.$dayjs(new Date()).utc().format("MM-DD-YYYY HH:mm");
      this.ticket = await this.getTicket({ url: "ticketdetail", id: event.data.ticketnumber });
      this.total = 0;
      this.ticket.map(el => {
        this.total += +el.value
      });
      this.detail = false;
    },



  },
};
</script>
<!-- eslint-disable -->
<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.special-day {
  text-decoration: line-through;
}
</style>