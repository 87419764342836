<!-- eslint-disable -->
<script setup>
import csiCodeExcel from "@/components/export/csiCode/excel.vue"; 
</script>
<!-- eslint-disable -->
<template>
  <AddCodecsi @fetchCodecsisData="fetchCodecsis" />
  <EditCodecsi @fetchCodecsisData="fetchCodecsis" :codecsi="stateCodecsi" />
  <div class=" " v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-4 d-flex justify-content-between align-items-center">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des code csi</span>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAdd">
          <i class="fas fa-plus"></i> Créer un code csi
        </button>
      </div>
    </div>
    <div class="row w-100 mx-0 auth-page mb-5 usercard p-3">
      <div class="row">
        <!-- <div class="col-md-3">
          <div class="mb-3">
            <label for="libel" class="form-label">Libel</label>
            <input type="text" class="form-control" id="libel" placeholder="libel" v-model="search.libel" />
          </div>
        </div> -->
        <div class="col-md-4">
          <div class="mb-3">
            <label for="code" class="form-label">code</label>
            <input type="text" class="form-control" id="code" placeholder="code" v-model="search.code" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="nbMonths" class="form-label">Active</label>
            <select v-model="search.isEnabled" class="form-control">
              <option :value="null">
                Tous
              </option>
              <option :value="true">
                Activer
              </option>
              <option :value="false">
                Désactiver
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="nbMonths" class="form-label">Utiliser </label>
            <select v-model="search.isUsed" class="form-control">
              <option :value="null">
                Tous
              </option>
              <option :value="true">
                Utiliser
              </option>
              <option :value="false">
                pas encore
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="mb-3 w-100">
            <button type="button" class="float-end btn btn-primary w-33" @click="fetchCodecsis">
              Recherche
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12 export-btn grid-3 mb-3 text-end">

          <span v-if="codecsis.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <csiCodeExcel :exportTo="codecsis" />
          </span>

        </div>
      </div>
    </div>


    <div class="col-md-12 col-xl-12 mx-auto">
      <div class="row">

        <div class="col-md-12">


          <DataTable showGridlines stripedRows :value="codecsis">

            <Column field="Code CSI" header="Code CSI" sortable>
              <template #body="slotProps">
                {{ slotProps.data?.code }}
              </template>
            </Column>
            <Column field="nbMonths" header="Nombre des mois" sortable>
              <template #body="slotProps">
                {{ slotProps.data.nbMonths }} Mois
              </template>
            </Column>
            <Column field="isEnabled" header="Status" sortable>
              <template #body="slotProps">
                <span v-if="slotProps.data?.isEnabled" class="table-tag" :style="{ backgroundColor: '#0be80b' }">
                  Active
                </span>
                <span v-else class="table-tag" :style="{ backgroundColor: '#fb6060' }">
                  Désactivé
                </span>
              </template>
            </Column>
            <Column field="isUsed" header="isUsed" sortable>
              <template #body="slotProps">
                <span v-if="slotProps.data?.isUsed" class="table-tag" :style="{ backgroundColor: '#fb6060' }">
                  Déja utilisé
                </span>
                <span v-else class="table-tag" :style="{ backgroundColor: '#0be80b' }">
                  Pas encore
                </span>
              </template>
            </Column>
            <Column field="LibSite" header="LibSite" sortable>
              <template #body="slotProps">
                <span class="libsite" v-if="slotProps.data?.storeID" @click="voirInfo(slotProps.data?.storeID)">
                  {{ slotProps.data?.LibSite }}
                </span>
                <span v-else>
                  Old-version
                </span>

              </template>
            </Column>



            <Column field="Actions" header="Actions" sortable>
              <template #body="slotProps">
                <div class="button mt-2 d-flex flex-row align-items-center">
                  <button type="button" @click="stateCodecsi = slotProps.data" data-bs-toggle="modal"
                    data-bs-target="#modalEdit" class="btn btn-sm btn-primary w-100">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button type="button" @click="remove(slotProps.data?._id)" class="btn btn-sm btn-danger w-100 ml-3">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </template>
            </Column>


          </DataTable>
        </div>

      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      try {
        this.fetchCodecsis()
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      codecsis: [],
      show: false,
      isModalOpen: false,
      stateCodecsi: null,
      subject: null,
      search: {
        libel: "",
        isUsed: null,
        code: null,
        isEnabled: null,
      }
    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "deleteCodecsi",
      "searchCodecsis",
    ]),
    async fetchCodecsis() {
      const searchData = {}
      if (this.search?.libel)
        searchData['libel'] = this.search?.libel
      if (this.search?.price)
        searchData['price'] = parseFloat(this.search?.price)
      if (this.search?.code)
        searchData['code'] = this.search?.code
      if (this.search?.isEnabled != null) {
        searchData['isEnabled'] = this.search?.isEnabled
      }
      if (this.search?.isUsed != null) {
        searchData['isUsed'] = this.search?.isUsed
      }
      this.codecsis = await this.searchCodecsis(searchData);
    },
    async remove(id) {
      try {
        const results = await this.deleteCodecsi({ id: id });
        this.fetchCodecsis()
        this.showSuccess();
      } catch (error) {
        if (error?.response?.status == 409)
          this.showAlert("Cannot delete the entity because it is referenced by other records.");
        else {
          this.showAlert("Nothing delete");
        }
      }
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.abn-disabled {
  background-color: #f69a9a;
}

.libsite {
  cursor: pointer;
  color: blue;
  text-transform: uppercase;
}
</style>