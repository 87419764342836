<template>
    <div class="main-wrapper">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">
                <div class="row w-100 mx-0 auth-page">
                    <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
                        <h4 class="mb-2">Abonnement expiré </h4>
                        <!-- {{ currentSite?.LibSite }} -->
                        <p>Pensez à renouveler votre abonnement pour accéder à votre tableau de bord </p>
                        <router-link :to="{ name: 'renouveler' }" class="btn btn-primary text-white w-50 mt-3">
                            Je renouvelle l'abonnement
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({ LibSite: "LibSite" }),
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", currentSite: "currentSite" }),
    },
    mounted() {
        console.log("Abonnement Expirer View componennt")
    }
}

</script>