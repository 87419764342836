/*eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios-api";
import PrimeVue from "primevue/config";
import VueSweetalert2 from "vue-sweetalert2";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Chart from "primevue/chart";
import FileUpload from "primevue/fileupload";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import i18n from '@/i18n/index';

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@vuepic/vue-datepicker/dist/main.css";

import LoaderView from "@/components/loader.vue";
import datanotfound from "@/components/datanotfound.vue";
import AbonnerRestaurant from "./views/Abonner/AbonnerRestaurant.vue";
import payementSuccess from "./views/Abonner/payementSuccess.vue";
import payementCancled from "./views/Abonner/payementCancled.vue";
import EditEtatVendeurView from "@/views/vendeurs/EditEtatVendeurView.vue";

import AddCodecsiView from "@/views/Codecsis/AddCodecsiView.vue";
import EditCodecsiView from "@/views/Codecsis/EditCodecsiView.vue";
import AddAbonnementView from "@/views/Abonnements/AddAbonnementView.vue";
import EditAbonnementView from "@/views/Abonnements/EditAbonnementView.vue";
import WelcomeToReporting from "./views/WelcomeToReporting.vue";

const app = createApp(App);
var libvendeur = localStorage.getItem("libvendeur");
dayjs.extend(utc);
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$libvendeur = libvendeur ? libvendeur : "vendeur";

/* eslint-disable */
app.component("LoaderView", LoaderView);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Chart", Chart);
app.component("FileUpload", FileUpload);
app.component('Datepicker', Datepicker);
app.component('datanotfound', datanotfound);
app.component('AbonnerRestaurant', AbonnerRestaurant);
app.component('payementSuccess', payementSuccess);
app.component('payementCancled', payementCancled);
app.component('WelcomeToReporting', WelcomeToReporting);
app.component('AddAbonnement', AddAbonnementView);
app.component('EditAbonnement', EditAbonnementView);
app.component('AddCodecsi', AddCodecsiView);
app.component('EditCodecsi', EditCodecsiView);
app.component('EditEtatVendeur', EditEtatVendeurView);

app.use(VueSweetalert2);
app.use(PrimeVue);
app.use(i18n);
app.use(store).use(router).mount("#app");

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;


axios.interceptors.response.use(undefined, (error) => {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut1");
      return (router.push("/login"));
    }
  }
});
