<!-- eslint-disable -->
<template>
  <!-- <Line :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
      :css-classes="cssClasses" :styles="styles" :width="width" :height="height" class="h-30rem" /> -->
  <Chart type="line" :data="chartData" :chart-options="chartOptions" :plugins="plugins" :width="width" :height="height"
    class="h-30rem" />
</template>
<!-- eslint-disable -->
<script>
import { Line } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export default {
  name: "LineChart",
  components: {
    Line,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 120,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    chartData: {
      type: Object,
      default: () => { },
    },

  },
  data() {
    return {
      plugins: [ChartDataLabels],
      chartOptions: {
        responsive: true,
        plugins: [ChartDataLabels],
      },
      /* plugins: {
         responsive: true,
         tooltip: {
           enabled: false,
           callbacks: {
             label: function (context) {
               let label = context.dataset.label || "";
 
               if (label) {
                 label += ": ";
               }
               if (context.parsed.y !== null) {
                 label += new Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                 }).format(context.parsed.y);
               }
               return label;
             },
           },
         },
       },*/
    };
  },
  mounted() {
  },
};
</script>
