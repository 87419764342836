<!-- eslint-disable -->
<template>
  <a class="theme-item" @click="loading()">
    <i class="me-2 icon-md" data-feather="settings"></i>
    <span v-if="style == 'demo1'"> Mode nuit </span>
    <span v-else> Mode jour </span>
  </a>
</template>
<!-- eslint-disable -->
<script>
export default {
  name: "SettingSidebar",
  data() {
    return {
      style: "",
    };
  },
  mounted() {
    this.getStyle();
  },
  methods: {
    getStyle() {
      this.style = localStorage.getItem("style") || 'demo1';
      document.getElementById("style").href = `/assets/css/${this.style}/style.css`;
    },
    loading() {
      this.style = this.style == "demo1" ? "demo2" : "demo1";
      localStorage.setItem("style", this.style);
      document.getElementById("style").href = `/assets/css/${this.style}/style.css`;
    },
  },
};
</script>
