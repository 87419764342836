<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-3">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des restaurants</span>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="row">
          <div v-for="(restaurant, index) in restaurants" v-bind:key="index" class="col-md-4 col-xl-4 mb-4">
            <div class="usercard p-3">
              <div class="d-flex align-items-center">
                <div class=" w-100">
                  <h5 class="mb-0 mt-0">{{ restaurant.LibSite }}</h5>
                  <div class="mt-2   d-flex justify-content-between rounded  ">
                    <div class="d-flex flex-column">
                      <p class="addresse">
                        <b>Adresse</b> : {{ restaurant.EmetteurAdresse }}, {{ restaurant.EmetteurVille }}, {{
                          restaurant.EmetteurPays }},
                        {{
                          restaurant.EmetteurCodePostal }}
                      </p>
                      <p class="addresse">
                        <b>Societe</b> : {{ restaurant.EmetteurSociete }}
                      </p>
                    </div>

                  </div>
                  <div class="lastet-subscribe">
                    <div v-if="restaurant?.abonnements?.length > 0">
                      <p>
                        <b>Date début</b> :
                        {{ $dayjs(restaurant?.abonnements[restaurant?.abonnements?.length -
                          1]?.dateDebut).utc().format("MM/DD/YYYY HH:mm:ss") }}
                      </p>
                      <p>
                        <b>Date fin</b> :
                        {{ $dayjs(restaurant?.abonnements[restaurant?.abonnements?.length -
                          1]?.dateFin).utc().format("MM/DD/YYYY HH:mm:ss") }}

                      </p>
                      <p v-if="!isSubscribreEnd(restaurant?.abonnements[restaurant?.abonnements?.length - 1]?.dateFin)">
                        <b>
                          Abonnement :
                        </b>
                        Términer
                      </p>
                    </div>
                  </div>
                  <div class="button mt-2 d-flex flex-row align-items-center">
                    <!-- <button type="button" @click="editRes(restaurant._id)" class="btn btn-sm btn-primary w-100">
                      <i class="fas fa-edit"></i>
                    </button> -->
                    <!-- <button type="button" @click="deleteM(restaurant._id)" class="btn btn-sm btn-danger w-100 ml-3">
                      <i class="fas fa-trash-alt"></i>
                    </button> -->
                    <button type="button" @click="voirInfo(restaurant._id)" class="btn btn-sm btn-success w-100 ">
                      <i class="fas fa-eye"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
///import { DeleteIcon } from "@vue-icons/feather";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RestaurantView",
  components: {
    // DeleteIcon,
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser" }),
  },
  mounted() {
    const get = async () => {
      try {
        this.restaurants = await this.getAllRestaurant();
      } catch (error) {
        this.showAlert("Error !!!");
      }
      try {
        await this.getAllWaiter();
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      restaurants: [],
      show: false,

    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "getAllWaiter",
      "getAllRestaurant",
      "deleteRestaurant",
      "editRestaurant",
      "selectedCurrentSite"
    ]),
    async editRes(store_id) {
      try {
        this.$router.push({
          name: "modifier-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        console.log(error);
        //this.showAlert("Forbidden resource");
      }
    },
    async deleteM(id) {
      try {
        await this.deleteRestaurant(id);
        this.restaurants = await this.getAllRestaurant();
        this.showSuccess();
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
    isSubscribreEnd(dateFin) {
      const currentDate = this.$moment()
      //var fin = this.$moment(dateFin, "MM-DD-YYYY");
      var fin = this.$moment(dateFin).format("YYYY-MM-DD");
      const dateToCompare = this.$moment(fin)
      return dateToCompare.isAfter(currentDate)
    },
    selectedSite(site) {
      site.type = "site"
      const LibSite = site
      this.selectedCurrentSite(LibSite)
    }
  },
};
</script>
