<!-- eslint-disable -->
<template>
    <div class="main-wrapper">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">
                <div class="row w-100 mx-0 auth-page d-flex align-items-center justify-content-center">
                    <div class="col-sm-6  col-md-9 col-xl-7 col-lg-6 mx-auto">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-12 stretch-card">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div v-if="success_payement" class="col-md-12 text-center">
                                                    <i class="font-icon fa fa-check" aria-hidden="true"></i>
                                                    <h2>Paiement effectué.</h2>
                                                    <p class="mt-2"> Votre compte a été créé avec succès.</p>
                                                    <div class="mt-4 mb-4">
                                                        <a href="/">
                                                            Retour au tableau de bord
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else-if="success_payement == false"
                                                    class="col-md-12 text-center">
                                                    <i class="font-icon fa fa-times" aria-hidden="true"></i>
                                                    <h2>Les données sont incorrectes</h2>
                                                    <div class="mt-4 mb-4">
                                                        <a href="/">
                                                            Retour au tableau de bord
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    name: "payementSuccess",
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser" }),
    },
    mounted() {
        const get = async () => {
            try {
                await this.ActivatedAccountStore()
            } catch (error) {
                if (error) {
                    this.show = false;
                    this.showAlert("Forbidden resource");
                }
            }
        };
        if (this.isAuthenticated)
            get();
    },
    data() {
        return {
            users: [],
            show: false,
            success_payement: null
        };
    },
    methods: {
        ...mapMutations(["setRenouvelerSite"]),
        ...mapActions(["validateStoreAfterPayementSuccess"]),
        async ActivatedAccountStore() {
            const validate = {
                token: this.$route.params?.token
            };
            try {
                const result = await this.validateStoreAfterPayementSuccess(validate);
                if (result?.data?.status == 404) {
                    this.success_payement = false
                } else if (result?.data?.status == 200) {
                    this.success_payement = true
                    this.setRenouvelerSite(result?.data?.restaurantID)
                }
            } catch (error) {
                return;
            }
            return false
        },
    },
};
</script>

<style scoped>
.main-wrapper {
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-image: url('../../../public/assets/img/bg2.jpg');

}

.page-wrapper.full-page {
    background: rgba(0, 0, 0, 0.57);
    position: relative;
}

.text-muteds {
    color: #fff;
    font-size: 20px;
}



.font-icon {
    border-radius: 100%;
    padding: 12px;
    margin: 30px;
    width: 80px;
    height: 80px;

}

.font-icon.fa-check {
    border: 4px solid #37b437;
    color: #55ba55;
}

.font-icon.fa-times {
    border: 4px solid #fd6c6c;
    color: #fd4c4c;
}

a {
    font-size: 18px;
    color: #3a3aa5 !important;
}
</style>