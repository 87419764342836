export default {
    "fr": {
        'side': {
            "menu": {
                "tableau_bord": "Tableau de bord",
            }
        },
        'menu': {
            "home": "Accueil",
        },
        "content": {
            "pages": {
                "dashboard": {
                    "cards": {
                        "couvert_n": {
                            "couvert": "COUVERT",
                            "couvert_moyen": "couvert moyen",
                            "ca_table": "CA Table",
                            "ca": "Chiffre d'affaire",
                            "reglements": "Règlement",
                            "today": "auj"
                        },
                        "couvert_n_1": {
                            "couvert": "COUVERT N - 1",
                            "couvert_moyen": "couvert moyen",
                            "ca_table": "CA Table",
                            "ca": "Chiffre d'affaire",
                            "reglements": "Règlement",
                            "type": "N - 1"
                        },
                        "best_day": {
                            "title": "MEILLEUR JOUR DU MOIS EN COURS"
                        }
                    },
                },
                "datanotfound": {
                    "items": {
                        "item_1": {
                            "title": "Aucun résultat",
                            "description": "Il n'y aucun restaurant dans vos favoris",
                            "cta_title": "Découvrez nos restaurants"
                        }
                    }
                }
            },
            "button": {

                "save": "Enregistrer",
                "send": "Envoyer",
                "add": "Ajouter",
                "cancel": "Annuler",
                "valider": "Validé",
                "upload": "Importer",
                "edit": "Modifier",
                "delete": "Supprimer",
                "default": "Par défaut",
                "show": "Voir",
                "hide": "Masquer",
                "close": "Fermer",
                "prev": "Précédent",
                "next": "Suivant",
                "completed": "Terminé",
                'display_all': 'Afficher tout',
                'search': 'Chercher',
                "enable": "Activer",
                "disable": "Désactiver",
                "clean": "Vider les champs",
                "ok": "Ok",
                "yes": "Non",
                "no": "Oui",
                "details": "Voir détails",
                "showCart": "Voir le panier",
                "show": "Voir",
                "today": "Aujourd'hui",
                "yesterday": "Hier",
                "week": "Cette semaine",
                "previous_week": "La semaine précédente",
                "month": "Mois en cours",
                "previous_month": "Le mois précédent",
                "year": "Année actuelle",
                "previous_year": "L'année précédent"
            },
            "message": {
                "datanotfound": "Il n'y a pas de données disponibles"
            }

        },
        "alertswal": {
            "added": "{champ} ajoutée avec succès",
            "deleted": "{champ} supprimée avec succès",
            "updated": "{champ} modifié avec succès",
            "forbidden": "Forbidden"
        },
        "alerts": {
            "add": {
                "message": "Vous êtes sûr de vouloir ajout ?",
                "sous_message": "",
                "error": "",
                "success": "L'opération est effectuée avec succès"
            },
            "delete": {
                "message": "Étes-vous sûr de vouloir supprimer cette {champ} ? ",
                "sous_message": "",
                "error": "",
                "success": "La suppression a réussi"
            },
            "edit": {
                "message": "Vous êtes sûr de vouloir modifier ?",
                "sous_message": "",
                "error": "",
                "success": "La modification a réussi"
            },
            "aucune_info": "Aucune information à afficher.",
            "permission": "Vous n'êtes pas autorisé à naviguer sur cette page",

        },
        "validation": {
            "invalid": "{champ} invalid",
            'accepted': 'Le champ {attribute} doit être accepté.',
            'active_url': "Le champ {attribute} n'est pas une URL valide.",
            'after': 'Le champ {attribute} doit être une date postérieure au {date}.',
            'after_or_equal': 'Le champ {attribute} doit être une date postérieure ou égale au {date}.',
            'alpha': 'Le champ {attribute} doit contenir uniquement des lettres.',
            'alpha_dash': 'Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.',
            'alpha_num': 'Le champ {attribute} doit contenir uniquement des chiffres et des lettres.',
            'array': 'Le champ {attribute} doit être un tableau.',
            'before': 'Le champ {attribute} doit être une date antérieure au {date}.',
            'before_or_equal': 'Le champ {attribute} doit être une date antérieure ou égale au {date}.',
            'between': {
                'numeric': 'La valeur de {attribute} doit être comprise entre {min} et {max}.',
                'file': 'La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir entre {min} et {max} caractères.',
                'array': 'Le tableau {attribute} doit contenir entre {min} et {max} éléments.',
            },
            'boolean': 'Le champ {attribute} doit être vrai ou faux.',
            'confirmed': 'Le champ de confirmation {attribute} ne correspond pas.',
            'date': "Le champ {attribute} n'est pas une date valide.",
            'date_equals': 'Le champ {attribute} doit être une date égale à {date}.',
            'date_format': 'Le champ {attribute} ne correspond pas au format {format}.',
            'different': 'Les champs {attribute} et {other} doivent être différents.',
            'digits': 'Le champ {attribute} doit contenir :digits chiffres.',
            'digits_between': 'Le champ {attribute} doit contenir entre {min} et {max} chiffres.',
            'dimensions': "La taille de l'image {attribute} n'est pas conforme.",
            'distinct': 'Le champ {attribute} a une valeur en double.',
            'email': 'Le champ {attribute} doit être une adresse email valide.',
            'ends_with': 'Le champ {attribute} doit se terminer par une des valeurs suivantes : {values}',
            'exists': 'Le champ {attribute} sélectionné est invalide.',
            'file': 'Le champ {attribute} doit être un fichier.',
            'filled': 'Le champ {attribute} doit avoir une valeur.',
            'gt': {
                'numeric': 'La valeur de {attribute} doit être supérieure à {value}.',
                'file': 'La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir plus de {value} caractères.',
                'array': 'Le tableau {attribute} doit contenir plus de {value} éléments.',
            },
            'gte': {
                'numeric': 'La valeur de {attribute} doit être supérieure ou égale à {value}.',
                'file': 'La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir au moins {value} caractères.',
                'array': 'Le tableau {attribute} doit contenir au moins {value} éléments.',
            },
            'image': 'Le champ {attribute} doit être une image.',
            'in': 'Le champ {attribute} est invalide.',
            'in_array': "Le champ {attribute} n'existe pas dans {other}.",
            'integer': 'Le champ {attribute} doit être un entier.',
            'ip': 'Le champ {attribute} doit être une adresse IP valide.',
            'ipv4': 'Le champ {attribute} doit être une adresse IPv4 valide.',
            'ipv6': 'Le champ {attribute} doit être une adresse IPv6 valide.',
            'json': 'Le champ {attribute} doit être un document JSON valide.',
            'lt': {
                'numeric': 'La valeur de {attribute} doit être inférieure à {value}.',
                'file': 'La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir moins de {value} caractères.',
                'array': 'Le tableau {attribute} doit contenir moins de {value} éléments.',
            },
            'lte': {
                'numeric': 'La valeur de {attribute} doit être inférieure ou égale à {value}.',
                'file': 'La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir au plus {value} caractères.',
                'array': 'Le tableau {attribute} doit contenir au plus {value} éléments.',
            },
            'max': {
                'numeric': 'La valeur de {attribute} ne peut être supérieure à {max}.',
                'file': 'La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.',
                'string': 'Le texte de {attribute} ne peut contenir plus de {max} caractères.',
                'array': 'Le tableau {attribute} ne peut contenir plus de {max} éléments.',
            },
            'mimes': 'Le champ {attribute} doit être un fichier de type : {values}.',
            'mimetypes': 'Le champ {attribute} doit être un fichier de type : {values}.',
            'min': {
                'numeric': 'La valeur de {attribute} doit être supérieure ou égale à {min}.',
                'file': 'La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.',
                'string': 'Le texte {attribute} doit contenir au moins {min} caractères.',
                'array': 'Le tableau {attribute} doit contenir au moins {min} éléments.',
            },
            'multiple_of': 'La valeur de {attribute} doit être un multiple de {value}',
            'not_in': "Le champ {attribute} sélectionné n'est pas valide.",
            'not_regex': "Le format du champ {attribute} n'est pas valide.",
            'numeric': 'Le champ {attribute} doit contenir un nombre.',
            'min_character': 'Ce champ doit contenir au minimum {champ} caractères',
            'max_character': 'Ce champ doit contenir au maximum {champ} caractères',
            'min_chiffre': 'Ce champ doit contenir au minimum {champ} chiffres',
            'max_chiffre': 'Ce champ doit contenir au maximum {champ} chiffres',
            'password': 'Le mot de passe est incorrect',
            'present': 'Le champ {attribute} doit être présent.',
            'regex': 'Le format du champ {attribute} est invalide.',
            'required': 'Ce champ est obligatoire.',
            'required_if': 'Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.',
            'required_unless': 'Le champ {attribute} est obligatoire sauf si {other} est {values}.',
            'required_with': 'Le champ {attribute} est obligatoire quand {values} est présent.',
            'required_with_all': 'Le champ {attribute} est obligatoire quand {values} sont présents.',
            'required_without': "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            'required_without_all': "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            'same': 'Les champs {attribute} et {other} doivent être identiques.',
            'size': {
                'numeric': 'La valeur de {attribute} doit être :size.',
                'file': 'La taille du fichier de {attribute} doit être de :size kilo-octets.',
                'string': 'Le texte de {attribute} doit contenir :size caractères.',
                'array': 'Le tableau {attribute} doit contenir :size éléments.',
            },
            'starts_with': 'Le champ {attribute} doit commencer avec une des valeurs suivantes : {values}',
            'string': 'Le champ {attribute} doit être une chaîne de caractères.',
            'timezone': 'Le champ {attribute} doit être un fuseau horaire valide.',
            'unique': 'La valeur du champ {attribute} est déjà utilisée.',
            'uploaded': "Le fichier du champ {attribute} n'a pu être téléversé.",
            'url': "Le format de l'URL de {attribute} n'est pas valide.",
            'uuid': 'Le champ {attribute} doit être un UUID valide',
            'custom': {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
            'attributes': {
                'name': 'nom',
                'username': "nom d'utilisateur",
                'email': 'adresse email',
                'first_name': 'prénom',
                'last_name': 'nom',
                'password': 'mot de passe',
                'password_confirmation': 'confirmation du mot de passe',
                'city': 'ville',
                'country': 'pays',
                'address': 'adresse',
                'phone': 'téléphone',
                'mobile': 'portable',
                'age': 'âge',
                'sex': 'sexe',
                'gender': 'genre',
                'day': 'jour',
                'month': 'mois',
                'year': 'année',
                'hour': 'heure',
                'minute': 'minute',
                'second': 'seconde',
                'title': 'titre',
                'content': 'contenu',
                'description': 'description',
                'excerpt': 'extrait',
                'date': 'date',
                'time': 'heure',
                'available': 'disponible',
                'size': 'taille'
            }
        },
        "errors": {
            "libsite_already_existe": "Nom du Restaurant déja existe",
            "siret_already_existe": "Siret du Restaurant déja existe"


        }
    }
}