import axios from "axios";

const token = localStorage.getItem("jwt");
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const instance = axios.create({
  headers: { Authorization: `Bearer ${token}` },
});

export default instance;
