<!-- eslint-disable -->
<template>
    <div class="w-100 text-center" @click="generateFile"> <i class="far fa-file-excel"></i> Excel
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { exportToExcel, exportToPdf } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo"],
    components: {
    },
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        async generateFile() {
            exportToExcel(this.changeKey(), "report-codecsis" + this.$moment().utc().format("YYYY-MM-DD HH mm"), true, false)
        },
        changeKey() {
            try {
                var csicodes = this.exportTo.slice().sort((a, b) => {
                    // Convert usedDate to comparable format (e.g., string to Date object)
                    const dateA = a.usedDate ? new Date(a.usedDate) : null;
                    const dateB = b.usedDate ? new Date(b.usedDate) : null;

                    // Handle case when one or both objects don't have usedDate
                    if (dateA && !dateB) return -1;
                    if (!dateA && dateB) return 1;
                    if (!dateA && !dateB) return 0;

                    // Compare usedDate
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;

                    // If usedDate is the same, compare code
                    if (a.isUsed < b.isUsed) return -1;
                    if (a.isUsed > b.isUsed) return 1;
                    return 0;
                });
                const newDataToExport = {}
                console.log(csicodes)
                if (csicodes) {

                    const arrayCsiCode = []
                    csicodes?.map((item, i) => {
                        arrayCsiCode.push({
                            "CSI Code ": item?.code,
                            "Status ": item?.isUsed ? 'Already used' : 'Not yet',
                            "Store name ": item?.storeID ? item?.LibSite : 'Old-version',
                            "Subscription date ": item?.storeID ? item?.usedDate : 'Old-version'
                        })
                    });
                    newDataToExport['CSI Codes ' + this.$moment().utc().format("YYYY-MM-DD HH mm")] = arrayCsiCode

                }

                return newDataToExport
            } catch (error) {
                return []
            }
        }


    },
    mounted() { },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    }
};
</script>


<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>