<!-- eslint-disable -->
<template>
    <div class="row">
        <div class="col-12 col-xl-12 grid-margin stretch-card">
            <div class="card  n">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <h5>
                                Recherche
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="operation" class="col-md-6 mb-5">
                            <label class="form-label">Operation :</label>
                            <select class="form-select btn-outline-primarys dropdown-toggle"
                                @change="onChangeSelectOperation()" v-model="SelectedOperation">
                                <option selected value="all">
                                    Toutes Operations.
                                </option>
                                <option v-for="(operation, index) in data?.operations" v-bind:key="`Operation-${index}`"
                                    :value="operation">{{
                                        index
                                        +
                                        1
                                    }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ operation.toUpperCase() }}</option>
                            </select>
                        </div>
                        <div v-if="etat" class="col-md-6 mb-5">
                            <label class="form-label">Etats :</label>
                            <select class="form-select btn-outline-primarys dropdown-toggle"
                                @change="onChangeSelectEtat()" v-model="SelectedEtat">
                                <option selected value="all">
                                    Toutes Etats sans ouvert
                                </option>
                                <option v-for="(etat, index) in data?.etats" v-bind:key="`etat-${index}`" :value="etat">
                                    {{ index + 1 }}&nbsp;&nbsp;-&nbsp;&nbsp; {{ etat.toUpperCase() }}</option>
                            </select>
                        </div>
                        <div v-if="caisse" class="col-md-6 mb-5">
                            <label class="form-label">Caisse :</label>
                            <select class="form-select btn-outline-primarys dropdown-toggle"
                                @change="onChangeSelectCaisse()" v-model="SelectedCaisse">
                                <option selected value="all">
                                    Toutes caisses.
                                </option>
                                <option v-for="(caisse, index) in data?.caisses" v-bind:key="`caisse-${index}`"
                                    :value="JSON.stringify(caisse)">{{
                                        index
                                        +
                                        1
                                    }}&nbsp;&nbsp;-&nbsp;&nbsp; {{ caisse.LibCaisse.toUpperCase() }}</option>
                            </select>
                        </div>
                        <div v-if="vendeur" class="col-md-6 mb-5">
                            <label class="form-label">Vendeur :</label>
                            <select class="form-select btn-outline-primarys dropdown-toggle"
                                @click="onChangeSelectVendeur()" v-model="SelectedVendeur">
                                <option selected value="all">
                                    Toutes vendeurs.
                                </option>
                                <option v-for="(vendeur, index) in data?.vendeurs" v-bind:key="`vendeurs-${index}`"
                                    :value="JSON.stringify(vendeur)"> {{ vendeur.LibVendeur.toUpperCase() }}</option>
                            </select>
                        </div>

                        <div v-if="date" class="col-md-6 mb-3  ">
                            <div class="w-100  ">
                                <label for="Date" class="form-label">Dates</label>
                                <VueDatePicker locale="fr" @update:model-value="handleDate" v-model="selectedDates"
                                    :action-row="{ showSelect: false, showCancel: false }" range multiCalendars
                                    :enableTimePicker="true" format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply
                                    show-now-button />
                            </div>

                        </div>
                        <div class="col-md-12 mt-4 d-flex ">
                            <button @click="clearFilter" class="  btn btn-secondary   text-white w-50 ms-3">
                                Vider </button>
                            <button @click="search" class="  btn btn-primary text-white w-50 ms-3">
                                Chercher</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
    components: { VueDatePicker },
    props: ['operation', 'etat', 'caisse', 'vendeur', 'date', 'vendeurStaus'],
    data() {
        return {
            selectedDates: [],
            data: {
                caisses: [],
                vendeurs: [],
                etats: ["Ouvert"],
                operations: ['VENTE', 'TRANSFERT', 'ANNULATION'],
            },
            SelectedVendeur: {},
            SelectedCaisse: {},
            SelectedEtat: "all",
            SelectedOperation: "all",
        }
    },
    mounted() {
        this.defaultVal()
        const get = async () => {
            if (this.currentSite?._id) {
                await this.getListVendeurs();
                await this.getListCaisses()
            }
        };
        if (this.isAuthenticated && this.isRole == 'manager')
            get();

        this.clearFilter()
    },
    methods: {
        ...mapActions(["getVendeurs", "getCaisses"]),
        async getListVendeurs() {
            const siteID = (this.currentSite != undefined) ? this.currentSite?._id : 'site'
            this.data.vendeurs = await this.getVendeurs({
                url: "vendeurs",
                site: siteID,
                vendeur: "vendeur",
                isActive: this.vendeurStaus == 'all' ? [true, false] : [true]
            });
        },
        async getListCaisses() {
            const siteID = (this.currentSite != undefined) ? this.currentSite?._id : 'site'
            this.data.caisses = await this.getCaisses({
                url: "entetes/caisses",
                site: siteID,
                caisses: 'caisses'
            });
        },
        clearFilter() {
            this.SelectedCaisse = "all"
            this.globalSearch.SelectedLibCaisses = []
            this.SelectedVendeur = "all"
            this.globalSearch.SelectedLibVendeurs = []
            this.SelectedEtat = "all"
            this.globalSearch.SelectedEtats = []
            this.SelectedOperation = "all"
            this.globalSearch.SelectedOperations = []
            this.$emit('search');
        },
        onChangeSelectOperation() {
            if (this.SelectedOperation == "all") {
                this.globalSearch.SelectedOperations = []
            } else {
                const Operation = this.SelectedOperation
                if (this.globalSearch.SelectedOperations?.length)
                    this.globalSearch.SelectedOperations.splice(0, 1);
                this.globalSearch.SelectedOperations.push(Operation);
            }
        },
        onChangeSelectEtat() {
            if (this.SelectedEtat == "all" || this.SelectedEtat == "Autres") {
                this.globalSearch.SelectedEtats = []
            } else {
                const etat = this.SelectedEtat
                if (this.globalSearch.SelectedEtats?.length)
                    this.globalSearch.SelectedEtats.splice(0, 1);
                this.globalSearch.SelectedEtats.push(etat);
            }

        },
        onChangeSelectCaisse() {
            if (this.SelectedCaisse == "all") {
                this.globalSearch.SelectedLibCaisses = []
            } else {
                const caisse = JSON.parse(this.SelectedCaisse)
                if (this.globalSearch.SelectedLibCaisses?.length)
                    this.globalSearch.SelectedLibCaisses.splice(0, 1);
                this.globalSearch.SelectedLibCaisses.push(caisse?.LibCaisse);

            }

        },
        onChangeSelectVendeur() {
            if (this.SelectedVendeur == "all") {
                this.globalSearch.SelectedLibVendeurs = []
            } else {
                const vendeur = JSON.parse(this.SelectedVendeur)
                if (this.globalSearch.SelectedLibVendeurs?.length)
                    this.globalSearch.SelectedLibVendeurs.splice(0, 1);
                this.globalSearch.SelectedLibVendeurs.push(vendeur?.LibVendeur);

            }
        },
        search() {
            // Emit a custom event with the search title
            this.$emit('search');
        },

        handleDate(selectedDate) {
            console.log("hello ayoub hh")
            if (selectedDate) {
                let dateFrom, dateTo;
                let dateNFrom, dateNTo;

                const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
                const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']


                const from = (this.$dayjs(selectedDate[0]).format('YYYY-MM-DD HH:mm'))
                dateFrom = DateTime.fromFormat(from, 'yyyy-MM-dd HH:mm');
                const to = (this.$dayjs(selectedDate[1]).format('YYYY-MM-DD HH:mm'))
                dateTo = DateTime.fromFormat(to, 'yyyy-MM-dd HH:mm');

                dateNFrom = dateFrom.minus({ year: 1 })
                dateNTo = dateTo.minus({ year: 1 })

                if (fromHour > 0) {
                    dateTo = dateTo.plus({ day: 1 })
                    dateNTo = dateNTo.plus({ day: 1 })
                }


                this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
                this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');


                this.datePicker.date_n.from = dateNFrom.toFormat('MM-dd-yyyy HH:mm');
                this.datePicker.date_n.to = dateNTo.toFormat('MM-dd-yyyy HH:mm');

                const isodateFrom = DateTime.fromISO(dateFrom);
                const isodateTo = DateTime.fromISO(dateTo);
                const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
                const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });
                this.datePicker.date.range = [formattedDateFrom, formattedDateTo];

                const isodateNFrom = DateTime.fromISO(dateNFrom);
                const isodateNTo = DateTime.fromISO(dateNTo);
                const formattedNDateFrom = isodateNFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
                const formattedNDateTo = isodateNTo.toISO({ includeOffset: false, suppressMilliseconds: false });
                this.datePicker.date_n.range = [formattedNDateFrom, formattedNDateTo];
                this.datePicker.defaultDates.type = null
                this.selectedDatesN1 = [this.datePicker.date_n.from, this.datePicker.date_n.to];
            }
            else {
                this.defaultVal()
            }
        },
        defaultVal() {
            const timeZone = 'Europe/Paris';
            const currentDate = DateTime.now().setZone(timeZone).setZone('UTC+0');
            let dateFrom, dateTo;

            const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
            const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']

            const startTime = { hour: fromHour, minute: fromMinutes }
            const endTime = { hour: toHour, minute: toMinute }
            dateFrom = currentDate.set(startTime);
            dateTo = dateFrom.set(endTime);
            const isodateFrom = DateTime.fromISO(dateFrom);
            const isodateTo = DateTime.fromISO(dateTo);
            const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
            const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });
            //this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
            //this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');
            console.log([formattedDateFrom, formattedDateTo])
            // this.datePicker.date.range = [formattedDateFrom, formattedDateTo]
            // this.selectedDates = [formattedDateFrom, formattedDateTo]
        }
    },
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite", parametrages: "StateParametrages" }),
        ...mapState(["globalSearch", "datePicker"]),
    }
}
</script>
 