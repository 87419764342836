<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-3">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des serveurs</span>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="row">
          <div v-for="(user, index) in users" v-bind:key="index" class="col-md-4 col-xl-4 mb-4">
            <div class="usercard p-3">
              <div class="d-flex align-items-center">
                <div class="image">
                  <img class=" rounded" :src="(user.avatar) ? BaseUrl + user.avatar : BaseUrl + 'default.png'"
                    alt="avatar" width="155">
                  <span>
                    {{ user.username }}
                  </span>
                </div>
                <div class="ml-3 w-100">
                  <h5 class="mb-0 mt-0">{{ user.firstName }} {{ user.lastName }}</h5>
                  <p>
                    <span class="email">{{ user.email }}</span>
                  </p>
                  <p>
                    <span class="articles">Role : {{ user.role.toUpperCase() }}</span>
                  </p>
                  <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                    <div class="d-flex flex-column">
                      <span v-if="(user.restaurant != undefined)" class="number1">
                        <span v-for="(restaurant, indexRest) in user.restaurant" :key="`restaurant-${indexRest}`">
                          <span @click="voirInfo(restaurant._id)" class="info-restaurant">
                            {{ restaurant.LibSite }}
                          </span>
                          <span v-if="user.restaurant.length > (indexRest + 1)">,&nbsp; </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="button mt-2 d-flex flex-row align-items-center">
                    <button type="button" @click="editM(user.username)" class="btn btn-sm btn-primary w-100">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button type="button" @click="deleteM(user._id)" class="btn btn-sm btn-danger w-100 ml-3">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import { DeleteIcon, EditIcon, MessageSquareIcon } from "@vue-icons/feather";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "WaitersView",
  components: {
    //DeleteIcon,
    //EditIcon,
    //MessageSquareIcon,
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      try {
        let saufID = this.User._id ? this.User._id : "";
        this.users = await this.getAllWaiterWithoutAuth(saufID);
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      users: [],
      show: false,
    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "getAllWaiter",
      "getAllWaiterWithoutAuth",
      "deleteWaiter",
      "editManager",
      "getRestaurantById",
    ]),
    async deleteM(id) {
      try {
        await this.deleteWaiter(id);
        let saufID = this.User._id ? this.User._id : "";
        this.users = await this.getAllWaiterWithoutAuth(saufID);
        this.showSuccess();
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
    async editM(username) {
      try {
        await this.editManager(username);
        window.location.href = `/editwaiter`;
      } catch (error) {
        this.showAlert("nothing to edit");
      }
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
  },
};
</script>
