<!-- eslint-disable -->
<template>
    <div class="modal fade" ref="myModal" id="modalSendMail" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="modalSendMailLabel" aria-hidden="true">
        <div class="modal-dialog">

            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-block mb-2"> <strong>Enovoyer ticket sous format PDF et Excel</strong> </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form class="forms-sample" method="POST" enctype="multipart/form-data" @submit.prevent="generateFile">
                    <div class="modal-body">
                        <div v-for="(item, index) in listDestinataires" :key="index" class="row px-4 py-2">
                            <div class="col-md-12">
                                <label for="description" class="form-label">Destinataire {{ index + 1 }} </label>
                            </div>
                            <div :class="[index == 0 ? 'col-md-12' : 'col-md-10']">
                                <input :readonly="index == 0 ? true : false" class="form-control" type="email"
                                    v-model="listDestinataires[index].Email" required placeholder="Destinataire">
                            </div>
                            <div v-if="index != 0" class="col-md-2">
                                <button type="button" class="btn btn-sm btn-danger w-100 " @click="removeItem(index)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row px-4 py-2">
                            <div class="col-md-12">
                                <button class="btn btn-secondary" type="button" @click="addItem">Ajouter
                                    distinataire</button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Envoyer </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division, alertSwal } from "@/Utils/globale.js";
import { exportToExcel, exportToPdf, exportToPdfMail } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo"],
    components: {
    },
    watch: {

    },
    data() {
        return {
            listDestinataires: [], // Array to store input data
            message: "",
            isSuccess: false
        };
    },
    mounted() {
        this.listDestinataires = []
        if (this.User?.email || this.User?.email !== "")
            this.listDestinataires.push({ Email: this.User?.email, Name: this.exportTo?.data?.LibSite });
    },
    methods: {
        ...mapActions(['sendEmailWithAttachment']),
        addItem() {
            // Add a new item to the listDestinataires array
            this.listDestinataires.push({ Email: '', Name: this.exportTo?.data?.LibSite });
        },
        removeItem(index) {
            // Remove the item at the specified index
            this.listDestinataires.splice(index, 1);
        },
        async generateFile() {
            const recipients = []
            this.listDestinataires?.forEach((item, index) => {
                if (item?.Email !== '')
                    recipients.push(this.listDestinataires[index]);
            });
            if (this.User?.email || this.User?.email !== "") {
                const pdfData = await exportToPdfMail(this.exportTo)

                const excelBuffer = await exportToExcel(this.DataToExport(), "report-syntheses_" + this.$moment().utc().format("YYYY-MM-DD HH mm"), true, true)
                // Send the email with the uploaded Excel file
                const data = {
                    recipients: recipients, //this.User?.email,
                    subject: 'Numéro de ticket : ' + this.exportTo?.data?.NumTicket + " - Site : " + this.exportTo?.data?.LibSite,
                    message: this.message,
                    ticket: {
                        site: this.exportTo?.data?.LibSite,
                        EmetteurAdresse: this.exportTo?.data?.EmetteurAdresse,
                        LibVendeur: this.exportTo?.data?.LibVendeur,
                        LibCaisse: this.exportTo?.data?.LibCaisse,
                        NumTicket: this.exportTo?.data?.NumTicket,
                        Operation: this.exportTo?.data?.Operation,
                        TableNumber: this.exportTo?.data?.TableNumber,
                        Horodatage: this.$dayjs(this.exportTo?.data?.Horodatage).utc().format("MM-DD-YYYY HH:mm"),
                        MontantHT: this.exportTo?.data?.MontantHT?.toFixed(2),
                        MontantTTC: this.exportTo?.data?.MontantTTC?.toFixed(2),
                        MontantTVA: this.exportTo?.data?.MontantTVA?.toFixed(2),
                        NbCouverts: this.exportTo?.data?.NbCouverts,
                    },
                    excelBuffer: excelBuffer,
                    pdfData: pdfData, // Pass raw PDF data
                }
                this.isSuccess = await this.sendEmailWithAttachment(data);
            } else {
                alertSwal("error", "Votre profile ne continet pas une adresse mail");
            }
        },
        DataToExport() {
            try {
                const newDataToExport = {}
                if (this.exportTo["data"]) {
                    const arrayCommande = []
                    arrayCommande.push({
                        "Ticket N° ": this.exportTo?.data?.NumTicket,
                        "site ": this.exportTo?.data?.LibSite,
                        "Adresse ": this.exportTo?.data?.EmetteurAdresse,
                        "Vendeur ": this.exportTo?.data?.LibVendeur,
                        "La Caisse ": this.exportTo?.data?.LibCaisse,
                        "Operation ": this.exportTo?.data?.Operation,
                        "Numéro de la table ": this.exportTo?.data?.TableNumber,
                        "Date de commande ": this.$dayjs(this.exportTo?.data?.Horodatage).utc().format("MM-DD-YYYY HH:mm"),
                        "Montant HT ": this.exportTo?.data?.MontantHT?.toFixed(2) + " €",
                        "Montant TTC ": this.exportTo?.data?.MontantTTC?.toFixed(2) + " €",
                        "Montant TVA ": this.exportTo?.data?.MontantTVA?.toFixed(2) + " €",
                        "Nb Couverts ": this.exportTo?.data?.NbCouverts,
                    });
                    newDataToExport['Détails de commandes'] = arrayCommande
                }

                if (this.exportTo["commandes"]?.length > 0) {
                    const arrayArticles = []
                    this.exportTo["commandes"]?.map((item, i) => {
                        if (item?.ARTICLE?.length > 0) {
                            item?.ARTICLE?.map((article, i) => {
                                arrayArticles.push({
                                    "COMMANDE ID ": item?.GUID,
                                    //"Site ": article?.LibSite,
                                    //"Vendeur ": article?.LibVendeur,
                                    "Libelle ": article?.Libelle,
                                    "Groupe ": article?.LibGroupe,
                                    "Famille ": article?.LibFamille,
                                    "TVA ": article?.LibTVA,
                                    "Quantite ": article?.QuantiteNet,
                                    "PrixUnitaireTTC ": article?.PrixUnitaireTTC?.toFixed(2) + " €",
                                    "PrixTotalNetHT ": article?.PrixTotalNetHT?.toFixed(2) + " €",
                                    "PrixTotalNet ": article?.PrixTotalNet?.toFixed(2) + " €",
                                });
                            });
                        }
                    });
                    arrayArticles.push({
                        "COMMANDE ID ": " ",
                        "Libelle ": " ",
                        "Groupe ": " ",
                        "Famille ": " ",
                        "TVA ": " ",
                        "Quantite ": " ",
                        "PrixUnitaireTTC ": " ",
                        "PrixTotalNetHT ": "TOTAL TTC : ",
                        "PrixTotalNet ": this.exportTo?.data?.MontantTTC?.toFixed(2) + " €",
                    });
                    newDataToExport['Liste des articles'] = arrayArticles
                }
                if (this.exportTo["reglements"]?.length > 0) {
                    const arrayReglements = []
                    this.exportTo["reglements"]?.map((item, i) => {
                        arrayReglements.push({
                            "Site ": item?.LibSite,
                            "Group ": item?.LibFamille,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Valeur Unitaire ": item?.ValeurUnitaire?.toFixed(2) + " €",
                            "Total Value (€) ": item?.TotalValue?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['Reglements'] = arrayReglements
                }

                if (this.exportTo["tvas"]?.length > 0) {
                    const arrayTva = []
                    this.exportTo["tvas"]?.map((item, i) => {
                        arrayTva.push({
                            "Site ": item?.LibSite,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Libelle ": item?.Libelle,
                            "Taux ": item?.Taux,
                            "TVA ": item?.ValeurTVA?.toFixed(2) + " €",
                            "HT ": item?.ValeurHT?.toFixed(2) + " €",
                            "TTC ": item?.ValeurTTC?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['TVA'] = arrayTva
                }

                if (this.exportTo["statistiques"]?.length > 0) {
                    const arrayStatistiques = []
                    this.exportTo["statistiques"]?.map((item, i) => {
                        arrayStatistiques.push({
                            "Site ": item?.LibSite,
                            "Type ": item?.LibFamille,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Libelle ": item?.Libelle,
                            "Type ": item?.LibFamille,
                            "Quantité ": item?.Quantite,
                            "Valeur (€) ": item?.Valeur?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['Statistiques'] = arrayStatistiques
                }
                return newDataToExport
            } catch (error) {
                return []
            }
        }
    },
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase", currentSite: "currentSite", }),

    },
};
</script>

<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>