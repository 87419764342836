<!-- eslint-disable -->
<template>
    <div class="w-100">
        <span type="button" class="w-100 btn btn-warning btn-icon-text bg-green" @click="generateFile"> <i
                class="far fa-file-excel"></i> Synthèse Excel</span>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division } from "@/Utils/globale.js";
import { exportToExcel, exportToPdf } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo"],
    components: {
    },
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        async generateFile() {
            exportToExcel(this.changeKey(), "report-syntheses_" + this.$moment().utc().format("YYYY-MM-DD HH mm"), true, false)
        },
        changeKey() {
            try {
                const newDataToExport = {}
                Object.keys(this.exportTo).map(key => {
                    if (key === "famille" && this.exportTo[key]?.data[0]?.length > 0) {
                        const arrayFamille = []
                        this.exportTo[key]?.data[0]?.map((item, i) => {

                            arrayFamille.push({
                                "Group ": item?._id,
                                "Quantité ": item?.QuantityFamille,
                                "Ratio Quantité ": item?.QteRatioFamille?.toFixed(2).toString().replace('.', ',') + " %",
                                "Chiffre d'affaire ": item?.PrixTotalNetFamille?.toFixed(2).toString().replace('.', ',') + " €",
                                "Ratio Chiffre d'affaire ": item?.PrixRatioFamille?.toFixed(2).toString().replace('.', ',') + " %",
                            })
                            if (item?.LibFamilles?.length > 0) {
                                arrayFamille.push({
                                    "Group ": "Produit ",
                                    "Quantité ": "Quantité produit ",
                                    "Ratio Quantité ": "Ratio QTE",
                                    "Chiffre d'affaire ": "Chiffre d'affaire par produit",
                                    "Ratio Chiffre d'affaire ": "Ratio CA"
                                });

                                item?.LibFamilles?.map((fm, i) => {
                                    arrayFamille.push({
                                        "Group ": fm?.libelle,
                                        "Quantité ": fm?.QuantityLibelle,
                                        "Ratio Quantité ": Division(fm?.QuantityLibelle * 100, item?.QuantityFamille).toString().replace('.', ','),
                                        "Chiffre d'affaire ": fm?.PrixTotalNetLibelle?.toFixed(2).toString().replace('.', ','),
                                        "Ratio Chiffre d'affaire ": Division(fm?.PrixTotalNetLibelle * 100, item?.PrixTotalNetFamille).toString().replace('.', ','),
                                    });
                                });
                            }
                            arrayFamille.push({
                                "Group ": "  ",
                                "Quantité ": "  ",
                                "Ratio Quantité ": " ",
                                "Chiffre d'affaire ": " ",
                                "Ratio Chiffre d'affaire ": " "
                            });
                            // arrayFamille.push({
                            //     "Group ": "Group ",
                            //     "Quantité ": "Quantité ",
                            //     "Ratio Quantité ": "Ratio Quantité",
                            //     "Chiffre d'affaire ": "Chiffre d'affaire ",
                            //     "Ratio Chiffre d'affaire ": "Ratio Chiffre d'affaire"
                            // });
                        });
                        newDataToExport['Groups'] = arrayFamille
                    }
                    if (key === "reglements" && this.exportTo[key]?.data?.length > 0) {

                        const arrayReglements = []
                        this.exportTo[key]?.data?.map((item, i) => {
                            arrayReglements.push({
                                "Site ": item?.LibSite,
                                "Group ": item?.LibFamille,
                                "La Caisse ": item?.LibCaisse,
                                "TotalValeur ": item?.TotalValeur?.toFixed(2).toString().replace('.', ',') + " €",
                            })
                        });
                        newDataToExport['Reglements'] = arrayReglements
                    }

                    if (key === "tva" && this.exportTo[key]?.data?.length > 0) {
                        const arrayTva = []
                        this.exportTo[key]?.data?.map((item, i) => {
                            arrayTva.push({
                                "Site ": item?.LibSite,
                                "TVA ": item?.TotalValeurTVA?.toFixed(2).toString().replace('.', ',') + " €",
                                "HT ": item?.TotalValeurHT?.toFixed(2).toString().replace('.', ',') + " €",
                                "TTC ": item?.TotalValeurTTC?.toFixed(2).toString().replace('.', ',') + " €",
                                //"Date ": this.$dayjs(item?.Horodatage).format("MM-DD-YYYY")
                            })
                        });
                        newDataToExport['TVA'] = arrayTva
                    }

                    if (key === "statistiques" && this.exportTo[key]?.data?.length > 0) {

                        const arrayStatistiques = []
                        this.exportTo[key]?.data?.map((item, i) => {
                            arrayStatistiques.push({
                                "Type ": item?.Family,
                                "Quantité ": item?.QteTotal,
                                "Montant (€) ": item?.TotalValeur?.toFixed(2).toString().replace('.', ',') + " €",
                                // "Date ": this.$dayjs(item?.Horodatage).format("MM-DD-YYYY")
                            })
                        });
                        newDataToExport['Statistiques'] = arrayStatistiques
                    }
                    if (key === "statistiques_caisse" && this.exportTo[key]?.data?.length > 0) {
                        const arrayStatistiques_caisse = []

                        this.exportTo[key]?.data?.map((item, i) => {
                            arrayStatistiques_caisse.push({
                                "Site ": item?.LibSite,
                                "La Caisse ": item?._id?.LibCaisse,
                                "Type ": item?.Family,
                                "Quantité ": item?.QteTotal,
                                "Montant (€) ": item?.TotalValeur?.toFixed(2).toString().replace('.', ',') + " €",
                                // "Date ": this.$dayjs(item?.Horodatage).format("MM-DD-YYYY")
                            })
                        });
                        newDataToExport['Statistiques par caisse'] = arrayStatistiques_caisse
                    }
                });

                return newDataToExport
            } catch (error) {
                return []
            }
        }


    },
    mounted() { },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    }
};
</script>


<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>