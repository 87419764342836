<!-- eslint-disable -->
<template>
    <div class="row">
        <div class="col-md-12">
            <p class="text-center">
                <b><i>{{ text }}</i></b>
            </p>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
export default {
    props: ["text"],
    methods: {},
};
</script>
