<!-- eslint-disable -->
<template>
    <div class="row">
        <div class="col-12 col-xl-12 grid-margin stretch-card">
            <div class="card overflow-hidden">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="me-auto">
                        </div>
                        <div class="form-check form-switch mb-2">
                            <div>
                                <label class="form-check-label" for="formSwitch1">SANS CLEF</label>
                            </div>
                            <input type="checkbox" class="form-check-input ms-0" id="formSwitch1" v-model="selected">
                        </div>
                        <div class="form-check form-switch mb-2">
                            <div>
                                <label class="form-check-label" for="formSwitch1">GW</label>
                            </div>
                            <input type="checkbox" class="form-check-input ms-1" id="formSwitch1" v-model="selected1">
                        </div>
                        <div class="form-check form-switch mb-2">
                            <div>
                                <label class="form-check-label" for="formSwitch1">LUCAS</label>
                            </div>
                            <input type="checkbox" class="form-check-input ms-1" id="formSwitch1" v-model="selected2">
                        </div>
                        <div class="form-check form-switch mb-2">
                            <div>
                                <label class="form-check-label" for="formSwitch1">JULIEN</label>
                            </div>
                            <input type="checkbox" class="form-check-input ms-1" id="formSwitch1" v-model="selected3">
                        </div>
                        <div class="me-auto">
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div> <!-- row -->

    <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">MEILLEURES VENTES</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="cars" :fields="json_fields">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint" :value="cars" ref="dt" class="p-datatable-lg table"
                            :paginator="true" :rows="5" sortMode="multiple" dataKey="id" :rowHover="true"
                            v-model:filters="filters" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No VENTES found.
                            </template>
                            <template #loading>
                                Loading VENTES data. Please wait.
                            </template>
                            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ cars ? cars.length : 0 }} VENTES.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">articles de cadeau</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="articlescadeau" :fields="json_fields3">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('3')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint3" :value="articlescadeau" ref="dt"
                            class="p-datatable-lg table" :paginator="true" :rows="5" sortMode="multiple" dataKey="id"
                            :rowHover="true" v-model:filters="filters3" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters3['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No articles de cadeau found.
                            </template>
                            <template #loading>
                                Loading articles de cadeau data. Please wait.
                            </template>
                            <Column v-for="col of columns3" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ articlescadeau ? articlescadeau.length : 0 }} articles de cadeau.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="card-title">MEILLEURES 5 HEURES</h6>
                    <div class="d-flex">
                        <div class="me-4 btn" @click="exportCSV($event)">
                            <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                            <h5 class="text-center fw-light">Copy</h5>
                        </div>
                        <download-excel 
                            :data="besthours"
                            :fields="json_fields1">
                            <div class="me-4 btn">
                                <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Exc</h5>
                            </div>
                        </download-excel>
                        <div class="btn" @click="printDocument('1')">
                            <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                            <h5 class="text-center fw-light">PDF</h5>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <DataTable showGridlines id="divToPrint1" :value="besthours" ref="dt" class="p-datatable-lg table" :paginator="true" :rows="10" sortMode="multiple" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="false" 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll">
                        <template #header>
                            <div class="d-flex align-items-center">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search mx-3" />
                                <InputText v-model="filters1['global'].value" placeholder="Keyword Search" />
                            </span>
                            </div>
                        </template>
                        <template #empty>
                            No VENTES found.
                        </template>
                        <template #loading>
                            Loading VENTES data. Please wait.
                        </template>
                        <Column v-for="col of columns1" :field="col.field" :header="col.header" :key="col.field" :sortable="true">
                        </Column>
                        <template #footer>
                            In total there are {{besthours ? besthours.length : 0 }} VENTES.
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div> -->
    </div>



    <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">Statistiques Revenu</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="statistiquesrevenu" :fields="json_fields2">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('2')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint2" :value="statistiquesrevenu" ref="dt"
                            class="p-datatable-lg table" :paginator="true" :rows="5" sortMode="multiple" dataKey="id"
                            :rowHover="true" v-model:filters="filters2" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters2['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No statistiques revenu found.
                            </template>
                            <template #loading>
                                Loading statistiques revenu data. Please wait.
                            </template>
                            <Column v-for="col of columns2" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ statistiquesrevenu ? statistiquesrevenu.length : 0 }} statistiques
                                revenu.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

export default {
    name: "StatsView",
    data() {
        return {
            selected: false,
            selected1: false,
            selected2: false,
            selected3: false,
            cars: [],
            besthours: [],
            statistiquesrevenu: [],
            articlescadeau: [],
            columns: null,
            columns1: null,
            columns2: null,
            columns3: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters1: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters2: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters3: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            json_fields: {
                "Waiter": "waiter",
                "Label": "label",
                "Qte": "qte",
            },
            json_fields1: {
                "Waiter": "waiter",
                "Hour": "hour",
                "Amount": "amount",
            },
            json_fields2: {
                "Waiter": "waiter",
                "Label": "label",
                "Qte": "qte",
                "Amount (€)": "amount",
            },
            json_fields3: {
                "Waiter": "waiter",
                "Libelle": "libelle",
                "Total": "total",
                "Qte": "qte",
            },
        };
    },
    watch: {
        selected(value) {
            /* eslint-disable-next-line */
            this.get();
        },
        selected1(value) {
            /* eslint-disable-next-line */
            this.get();
        },
        selected2(value) {
            /* eslint-disable-next-line */
            this.get();
        },
        selected3(value) {
            /* eslint-disable-next-line */
            this.get();
        },
    },
    created() {
        this.columns = [
            { field: "waiter", header: "Waiter" },
            { field: "label", header: "Label" },
            { field: "qte", header: "Qte" },
        ];
        this.columns1 = [
            { field: "waiter", header: "Waiter" },
            { field: "hour", header: "Hour" },
            { field: "amount", header: "Amount" },
        ];
        this.columns2 = [
            { field: "waiter", header: "Waiter" },
            { field: "label", header: "Label" },
            { field: "qte", header: "Qte" },
            { field: "amount", header: "Amount (€)" }
        ];
        this.columns3 = [
            { field: "waiter", header: "Waiter" },
            { field: "libelle", header: "Libelle" },
            { field: "total", header: "Total" },
            { field: "qte", header: "Qte" },
        ];
    },
    methods: {
        ...mapActions(["getAllByDateWaiter"]),
        currentDateTime() {
            const current = new Date();
            const dateTime = this.$dayjs(current).format("MM-DD-YYYY");
            return dateTime;
        },
        async get() {
            try {
                let waiter = ["nothing"];
                if (this.selected)
                    waiter.push("SANS CLEF");
                if (this.selected1)
                    waiter.push("GW");
                if (this.selected2)
                    waiter.push("LUCAS");
                if (this.selected3)
                    waiter.push("JULIEN");
                this.cars = await this.getAllByDateWaiter({ waiter, url: "bestvente" });


                this.statistiquesrevenu = await this.getAllByDateWaiter({ waiter, url: "statistiquesrevenu" });
                this.articlescadeau = await this.getAllByDateWaiter({ waiter, url: "articlescadeau" });
                // this.besthours = await this.getAllByDateWaiter({ date: "1", url: "besthours" });





                this.show = true;
            } catch (error) {
                this.show = false;
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        printDocument(id) {
            const pdfTable = document.getElementById(`divToPrint${id}`);
            let html = htmlToPdfmake(pdfTable.innerHTML);
            html = html.splice(0, 2);
            const documentDefinition = { content: html };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(documentDefinition).open();
        }
    },
};
</script>
<!-- eslint-disable -->
<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead>tr>th {
        text-align: left;
    }

    .p-datatable-tbody>tr>td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}
</style>