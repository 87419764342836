<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-3">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des clients</span>
        </div>
      </div>

      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="row  ">
          <div class="col-md-12 mb-4">
            <div class="row usercard m-1 p-3">
              <div class="col-md-12 mb-3">
                <h5>
                  Recherche
                </h5>
              </div>
              <div class="col-md-12 ">
                <div class="mb-3 ">
                  <label for="nbMonths" class="form-label">Type </label>
                  <select v-model="isAbonnementExiste" class="form-control" @change="filterbyLatestAbonnement">
                    <option :value="'null'">
                      Tous
                    </option>
                    <option :value="'actif'">
                      Activer
                    </option>
                    <option :value="'inactif'">
                      Désactiver (L'abonnement est terminé)
                    </option>
                    <option :value="'cancled'">
                      Paiement annuler
                    </option>
                    <option :value="'closed'">
                      Férmer leur abonnement
                    </option>
                    <option :value="'none'">
                      L'utilisateur n'a pas d'abonnement
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="searchKeyword" class="form-label">Filtrer</label>
                  <input type="text" class="form-control" id="searchKeyword"
                    placeholder="Filtrer par (Société, username, email, restaurant name o adresse) "
                    v-model="searchKeyword" @input="filterData" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="row">
          <div v-if="filteredData?.length > 0" v-for="(user, index) in filteredData" v-bind:key="index"
            class="col-md-6 col-xl-6 mb-4">
            <div class="row usercard p-3 m-1 ">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <div class="image">
                    <img class="rounded" :src="user.avatar
                      ? BaseUrl + user.avatar
                      : BaseUrl + 'default.png'
                      " alt="avatar" width="155" />
                    <span>
                      {{ user.username }}
                    </span>
                  </div>
                  <div class="ml-3 w-100">
                    <h5 class="mb-0 mt-0">
                      {{ user.firstName }} {{ user.lastName }}
                    </h5>
                    <p>
                      <span class="email">{{ user.email }}</span>
                    </p>
                    <p>
                      <span class="articles"> <strong>Nom de société </strong>: {{ user?.SocieteName }}</span>
                    </p>
                    <p>
                      <span class="articles"> <strong>Role</strong> : {{ user.role.toUpperCase() }}</span>
                    </p>

                  </div>

                </div>
              </div>
              <div v-if="user.associatedRestaurants?.length > 0" class="col-md-12 mt-4 ">
                <div class="row restaurants text-white stats mb-3">
                  <div class="col-md-12 mb-3">
                    <p> <strong>Restaurants (Child) : </strong> </p>
                  </div>
                  <div
                    v-for="(restaurant, indexRest) in user.associatedRestaurants?.filter((test) => (test?.latestAbonnemnt?.check?.status == isAbonnementExiste) || isAbonnementExiste == 'null')"
                    :key="`restaurant-${indexRest}`" class="col-md-6 child  mb-2 " @click="voirInfo(restaurant._id)">
                    <div class="info-restaurant  ">
                      <p><strong> Nom </strong> : {{ restaurant.LibSite }}</p>
                      <p>
                        <strong>Ville</strong> : {{ restaurant.EmetteurVille }}
                      </p>
                      <p class="last-abonnement" :class="restaurant.latestAbonnemnt?.check?.status">
                        {{ restaurant.latestAbonnemnt?.check?.message }}
                      </p>
                    </div>
                    <span v-if="user.restaurant.length > indexRest + 1">,&nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 section-child-stores">
                <div class="button mt-2 d-flex flex-row align-items-center editBtn">
                  <button title="Modifier les informations d'utilisateur" type="button" @click="editM(user.username)"
                    class="btn btn-sm btn-primary w-100">
                    <i class="fas fa-edit"></i> Modifier
                  </button>
                  <!-- <button type="button" @click="deleteM(user._id)" class="btn btn-sm btn-danger w-100 ml-3">
                  <i class="fas fa-trash-alt"></i>
                </button> -->
                  <!-- <button type="button" @click="affectRestaurant(user.username)" class="btn btn-sm btn-danger w-100 ml-3">
                  +Restaurant(s)
                </button> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-md-12 text-center">
            <div class="card-body d-flex align-items-center justify-content-center">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import { DeleteIcon, EditIcon, MessageSquareIcon } from "@vue-icons/feather";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ManagersView",
  components: {
    //DeleteIcon,
    //EditIcon,
    //MessageSquareIcon,
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      try {
        let saufID = this.User._id ? this.User._id : "";
        this.users = await this.getAllManagerWithoutAuth(saufID);
        this.filteredData = this.users
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      users: [],
      show: false,
      searchKeyword: "",
      filteredData: [],
      isAbonnementExiste: 'null',
    };
  },
  methods: {
    filterbyLatestAbonnement() {
      this.filteredData = this.users?.filter((item) => {
        if (this.isAbonnementExiste == 'null') {
          return item;
        } else if (this.isAbonnementExiste === 'actif') {
          return item?.associatedRestaurants?.some((restaurant) => restaurant?.latestAbonnemnt && restaurant?.latestAbonnemnt?.isActive)
        } else if (this.isAbonnementExiste === 'inactif') {
          return item?.associatedRestaurants?.some((restaurant) => (restaurant?.latestAbonnemnt && !restaurant?.latestAbonnemnt?.isActive))
        } else if (this.isAbonnementExiste === 'cancled') {
          return item?.associatedRestaurants?.some((restaurant) => (restaurant?.latestAbonnemnt && restaurant?.latestAbonnemnt?.payementCancled))
        } else if (this.isAbonnementExiste === 'closed') {
          return item?.associatedRestaurants?.some((restaurant) => (restaurant?.latestAbonnemnt && restaurant?.latestAbonnemnt?.closed))
        } else if (this.isAbonnementExiste === 'none') {
          return item?.associatedRestaurants?.some((restaurant) => (!restaurant?.latestAbonnemnt)) || item?.associatedRestaurants?.length <= 0
        }
      });
    },
    filterData() {
      const keyword = this.searchKeyword.trim();
      this.filteredData = this.users?.filter((item) => {
        // Your filtering logic here
        return (
          item?.SocieteName?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          item?.username?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          item?.email?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          item?.associatedRestaurants?.some((restaurant) =>
            restaurant?.name?.toLowerCase()?.includes(keyword?.toLowerCase())
          ) ||
          item?.associatedRestaurants?.some((restaurant) =>
            restaurant?.EmetteurAdresse?.toLowerCase()?.includes(keyword?.toLowerCase())
          )
        );
      });
    },
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "getAllManager",
      "getAllManagerWithoutAuth",
      "deleteManager",
      "editManager",
    ]),
    async deleteM(id) {
      try {
        await this.deleteManager(id);
        let saufID = this.User._id;
        this.users = await this.getAllManagerWithoutAuth(saufID);
        this.showSuccess();
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
    async editM(username) {
      try {
        await this.editManager(username);
        window.location.href = `/editmanager`;
      } catch (error) {
        this.showAlert("nothing to edit");
      }
    },
    async affectRestaurant(username) {
      try {
        await this.editManager(username);
        window.location.href = `/affecte-restaurants`;
      } catch (error) {
        this.showAlert("nothing to edit");
      }
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
  },
};
</script>



<style>
.restaurants {
  padding: 10px;
  max-height: 200px;
  overflow-y: scroll;
}


.restaurants .child .info-restaurant {
  padding: 8px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000865d9;
  color: #fff !important;
  text-decoration-line: none !important;
  font-size: 12px;
}

.restaurants .child .info-restaurant:hover {
  background: #000865;
  color: #fff !important;
}



.restaurants .info-restaurant .last-abonnement {
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
}

.restaurants .info-restaurant .last-abonnement.closed {
  background: black;
}

.restaurants .info-restaurant .last-abonnement.inactif {
  background: #ff4545;
}

.restaurants .info-restaurant .last-abonnement.actif {
  background: #6ddc6d;
  color: black;
}

.restaurants .info-restaurant .last-abonnement.pending {
  background: #5e5efb;
}

.restaurants .info-restaurant .last-abonnement.cancled {
  background: orange;
  color: #000;
}
</style>