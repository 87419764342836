<!-- eslint-disable -->
<template>
  <div class="main-wrapper">
    <div class="page-wrapper full-page">
      <div class="page-content d-flex align-items-center justify-content-center">
        <div class="row w-100 mx-0 auth-page d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-xl-6 mx-auto text-center">
            <img src="../../public/assets/img/logo_white.png" width="120" height="120" class="badge d-inline-block"
              alt="logo" />
            <h5 class="text-muteds fw-normal mb-4 mt-2">
              Créer votre compte.
            </h5>
          </div>
          <div class="col-md-8 col-xl-6 mx-auto">
            <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <div class="auth-form-wrapper px-4 py-4">
                    <div class="noble-ui-logo text-center d-block mb-5"> Créer un <span>&nbsp;compte</span></div>

                    <form class="forms-sample" @submit.prevent="SubmitData">
                      <div class="row">

                        <div class="col-md-12 mb-3">
                          <div class="">
                            <label for="exampleInputSocieteName" class="form-label">Nom de société </label>
                            <input type="text" class="form-control" id="exampleInputSocieteName"
                              autocomplete="SocieteName" placeholder="Nom de société" v-model="form.SocieteName" />
                          </div>
                          <div class="errors-response" v-if="v$.form.SocieteName.$error">
                            <p v-if="!v$.form.SocieteName.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.SocieteName.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'Nom de société', min: v$.form.SocieteName.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.SocieteName.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'Nom de société', max: v$.form.SocieteName.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="exampleInputUsername1" class="form-label">Nom d'utilisateur</label>
                            <input type="text" class="form-control" id="exampleInputUsername1" autocomplete="Username"
                              placeholder="Nom d'utilisateur" v-model="form.username" />
                          </div>
                          <div class="errors-response" v-if="v$.form.username.$error">
                            <p v-if="!v$.form.username.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.username.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: "Nom d'utilisateur", min: v$.form.username.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.username.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: "Nom d'utilisateur", max: v$.form.username.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="userEmail" class="form-label">Adresse e-mail</label>
                            <input type="email" class="form-control" id="userEmail" placeholder="Adresse e-mail"
                              autocomplete="Email" v-model="form.email" />
                          </div>
                          <div class="errors-response" v-if="v$.form.email.$error">
                            <p v-if="!v$.form.email.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p v-if="!v$.form.email.email.$response">{{ $t('validation.email') }}
                            </p>
                            <p class="error" v-if="!v$.form.email.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'email', min: v$.form.email.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.email.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'email', max: v$.form.email.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="exampleInputFirstname1" class="form-label">Prénom</label>
                            <input type="text" class="form-control" id="exampleInputFirstname1" placeholder="Prénom"
                              autocomplete="first-name" v-model="form.firstName" />
                          </div>
                          <div class="errors-response" v-if="v$.form.firstName.$error">
                            <p v-if="!v$.form.firstName.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.firstName.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'Prénom', min: v$.form.firstName.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.firstName.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'Prénom', max: v$.form.firstName.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="exampleInputLastname1" class="form-label">Nom de famille</label>
                            <input type="text" class="form-control" id="exampleInputLastname1"
                              placeholder="Nom de famille" autocomplete="last-name" v-model="form.lastName" />
                          </div>
                          <div class="errors-response" v-if="v$.form.lastName.$error">
                            <p v-if="!v$.form.lastName.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.lastName.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'Nom', min: v$.form.lastName.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.lastName.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'Nom', max: v$.form.lastName.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="userPassword" class="form-label">Mot de passe</label>
                            <input type="password" class="form-control" id="userPassword" autocomplete="current-password"
                              placeholder="Mot de passe" v-model="form.password" />
                          </div>
                          <div class="errors-response" v-if="v$.form.password.$error">
                            <p v-if="!v$.form.password.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.password.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'Mot de passe', min: v$.form.password.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.password.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'Mot de passe', max: v$.form.password.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="">
                            <label for="ConfirmPassword" class="form-label">Confirmer le mot de passe</label>
                            <input type="password" class="form-control" id="ConfirmPassword"
                              autocomplete="confirm-password" placeholder="Confirmer le mot de passe"
                              v-model="form.cnfpassword" />
                          </div>
                          <div class="errors-response" v-if="v$.form.cnfpassword.$error">
                            <p v-if="!v$.form.cnfpassword.required.$response">{{ $t('validation.required') }}
                            </p>
                            <p class="error" v-if="!v$.form.cnfpassword.sameAsPassword.$response">
                              {{ $t('validation.password') }}
                            </p>
                          </div>
                        </div>

                        <div class="col-md-12 mt-4">
                          <button type="submit" class="btn btn-primary text-white w-100">
                            Enregistrer</button>
                        </div>
                      </div>
                    </form>
                    <div class="col-md-12 mt-4 text-center">
                      <p> Déjà membre ?</p>
                      <router-link :to="{ name: 'login' }">
                        <span>Connectez-vous ici </span>
                      </router-link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, sameAs } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated" })
  },
  data() {
    return {
      form: {
        SocieteName: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        cnfpassword: "",
      },
      show: true,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        SocieteName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        username: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        email: {
          email,
          required,
          minLength: minLength(1),
          maxLength: maxLength(350)
        },
        firstName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        lastName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(20)
        },
        cnfpassword: {
          required,
          sameAsPassword: sameAs(this.form.password)
        },

      }
    };
  },
  mounted() {
    const get = async () => {
      try {
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          this.showAlert("Forbidden resource");
        }
      }
    };

    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },

  methods: {

    ...mapActions(["createCustomAccountManager"]),

    async SubmitData() {
      // add more logic here
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const user = {
          SocieteName: this.form.SocieteName.trim(),
          username: this.form.username.trim(),
          email: this.form.email.trim(),
          firstName: this.form.firstName.trim(),
          lastName: this.form.lastName.trim(),
          password: this.form.password.trim(),
          role: "manager",
        };
        await this.createCustomAccountManager(user);
        if (this.isAuthenticated) {
          window.location.href = "/";
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>



<style scoped > .main-wrapper {
   background-position: 50% !important;
   background-repeat: no-repeat !important;
   background-size: cover !important;
   background-image: url('../../public/assets/img/bg2.jpg');

 }

 .page-wrapper.full-page {
   background: rgba(0, 0, 0, 0.57);
   position: relative;
 }

 .text-muteds {
   color: #fff;
   font-size: 20px;
 }
</style>