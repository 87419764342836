<!-- eslint-disable -->
<template>
    <div class="d-flex align-items-center justify-content-center" v-if="show">
        <div class="row w-100 mx-0 auth-page">
            <div class="col-md-3 d-flex justify-content-center">
                <div class="profile-img sticky-top top-10">
                    <img class=" rounded-circle" :src="BaseUrl + User.avatar" alt="profile" />
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6 col-xl-6 mx-auto mb-4">
                        <router-link to="/editprofile" class="btn btn-sm btn-primary w-100">
                            Modifier profile
                        </router-link>
                    </div>
                    <div class="col-md-6 col-xl-6 mx-auto mb-4">
                        <router-link to="/editpassword" class="btn btn-sm btn-primary w-100">
                            Modifier mot de passe
                        </router-link>
                    </div>
                    <div class="col-md-12 col-xl-12 mx-auto mb-4">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-12 stretch-card">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="noble-ui-logo d-block  mb-4 mt-3 text-uppercase text-center">
                                                Bienvenu <span>&nbsp;<strong>{{ User.username }}</strong></span>
                                            </div>
                                            <!-- Row -->
                                            <div class="row">
                                                <div v-if="User.firstName" class="col-md-12">
                                                    <div class="mb-1">
                                                        <b class="form-label label-site">Prénom : </b>
                                                        <span> {{ User.firstName }}</span>
                                                    </div>
                                                </div>
                                                <div v-if="User.lastName" class="col-md-12">
                                                    <div class="mb-1">
                                                        <b class="form-label label-site">Nom : </b>
                                                        <span> {{ User.lastName }}</span>
                                                    </div>
                                                </div>
                                                <div v-if="User.email" class="col-md-12">
                                                    <div class="mb-1">
                                                        <b class="form-label label-site">Email : </b>
                                                        <span> {{ User.email }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="restaurants" class="col-md-12 col-xl-12 mx-auto mb-4">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-12 stretch-card">
                                    <div class="card">
                                        <div class="card-body ">
                                            <div class="px-2 py-2" v-for="(restaurant, index) in restaurants"
                                                v-bind:key="index">
                                                <div class="noble-ui-logo d-block mb-4 mt-3 text-uppercase text-center ">
                                                    <h3>
                                                        <strong> {{ index + 1 }} - {{ restaurant.LibSite }}</strong>
                                                    </h3>
                                                </div>
                                                <!-- Row -->
                                                <div class="row">
                                                    <div v-if="restaurant.LibSite" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Nom site : </b>
                                                            <span> {{ restaurant.LibSite }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurAdresse" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Adresse : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurAdresse }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurTelephone" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Numéro téléphone : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurTelephone }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurSociete" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Nom société : </b>
                                                            <span> {{ restaurant.EmetteurSociete }} </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurEtablissement" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Etablissement : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurEtablissement }}
                                                            </span>
                                                        </div>
                                                    </div>


                                                    <div v-if="restaurant.EmetteurVille" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Ville : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurVille }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurCodePostal" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Code postal :
                                                            </b>
                                                            <span>
                                                                {{ restaurant.EmetteurCodePostal }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurPays" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Pays : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurPays }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="restaurant.EmetteurNumTVA" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Numéro TVA : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurNumTVA }}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div v-if="restaurant.EmetteurSiret" class="col-md-12">
                                                        <div class="mb-1">
                                                            <b class="form-label label-site">Siret : </b>
                                                            <span>
                                                                {{ restaurant.EmetteurSiret }}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr>
                                                <!-- <div class="row">
                                                <div class="col-md-12">
                                                    <div
                                                        class="noble-ui-logo d-block mb-4 mt-3 text-uppercase text-center ">
                                                        <h3>
                                                            <strong>Vendeurs </strong>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div v-for="(vendeur, indexVendeur) in restaurant.vendeurs"
                                                    v-bind:key="indexVendeur" class="col-md-12">
                                                    <div class="mb-1">
                                                        <b class="form-label label-site">Nom : </b>
                                                        <span> {{ vendeur.LibVendeur }}</span>
                                                    </div>
                                                </div>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AddrestaurantView",
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", User: "StateUser" }),
    },
    mounted() {
        const get = async () => {
            try {
                this.restaurants = await this.getSitesWithAssociatedVendeurs({
                    url: "sites",
                    vendeur: "vendeur",
                    restaurants_ID: this.User?.restaurant
                });
                this.show = true;
            } catch (error) {
                if (error) {
                    this.show = false;
                    this.showAlert("Forbidden resource");
                }
            }
        };
        if (this.isAuthenticated)
            get();
        //}
    },
    data() {
        return {
            restaurants: [],
            show: false,
        };
    },
    methods: {
        ...mapActions([
            "getRestaurantById",
            "editRestaurant",
            "getSitesWithAssociatedVendeurs",
        ]),
        editProfile() {
            this.$router.push({
                name: "editprofile",
            });
        }
    },
};
</script>

<style>
.profile-img,
.profile-img img {
    width: 150px;
    height: 150px;
}

.label-site {
    width: 115px;
    display: inline-block;
}
</style>