<!-- eslint-disable -->
<template>
    <div class="main-wrapper">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">
                <div class="row w-100 mx-0 auth-page d-flex align-items-center justify-content-center">
                    <div class="col-sm-6  col-md-9 col-xl-7 col-lg-6 mx-auto">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-12 stretch-card">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div v-if="cancle_payement" class="col-md-12 text-center">
                                                    <i class="font-icon fa fa-times" aria-hidden="true"></i>
                                                    <h2>Paiement non effectué.</h2>
                                                    <p class="mt-2">
                                                        Votre restaurant ne peut pas être créé.
                                                    </p>
                                                    <div class="mt-4 mb-4">
                                                        <a href="/">
                                                            Retour au tableau de bord
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else-if="cancle_payement == false" class="col-md-12 text-center">
                                                    <i class="font-icon fa fa-times" aria-hidden="true"></i>
                                                    <h2>Les données sont incorrectes</h2>
                                                    <div class="mt-4 mb-4">
                                                        <a href="/">
                                                            Retour au tableau de bord
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "payementSuccess",
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser" }),
    },
    mounted() {
        const get = async () => {
            try {
                this.ActivatedAccountStore()
                this.show = true;
            } catch (error) {
                if (error) {
                    this.show = false;
                    this.showAlert("Forbidden resource");
                }
            }
        };
        if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
            get();
    },
    data() {
        return {
            users: [],
            show: false,
            cancle_payement: null
        };
    },
    methods: {
        ...mapActions(["removeAbonnemetWhenPayementCancled"]),
        async ActivatedAccountStore() {
            const removeSubscription = {
                userID: this.User?._id,
                token: this.$route.params?.token
            };
            try {
                const result = await this.removeAbonnemetWhenPayementCancled(removeSubscription);
                if (result?.data?.status == 404) {
                    this.cancle_payement = false
                } else if (result?.data?.status == 200) {
                    this.cancle_payement = true
                }
            } catch (error) {
                return;
            }
        },
    },
};
</script>
<style scoped > .main-wrapper {
     background-position: 50% !important;
     background-repeat: no-repeat !important;
     background-size: cover !important;
     background-image: url('../../../public/assets/img/bg2.jpg');

 }

 .page-wrapper.full-page {
     background: rgba(0, 0, 0, 0.57);
     position: relative;
 }

 .text-muteds {
     color: #fff;
     font-size: 20px;
 }



 .font-icon {
     border-radius: 100%;
     padding: 12px;
     margin: 30px;
     width: 80px;
     height: 80px;

 }

 .font-icon.fa-check {
     border: 4px solid #37b437;
     color: #55ba55;
 }

 .font-icon.fa-times {
     border: 4px solid #fd6c6c;
     color: #fd4c4c;
 }

 a {
     font-size: 18px;
     color: #3a3aa5 !important;
 }
</style>