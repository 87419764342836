<!-- eslint-disable -->
<template>
    <!-- border -->
    <div class="w-full h-px bg-gradient-to-r"></div>
</template>
<!-- eslint-disable -->
<style>
.bg-gradient-to-r {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0) 100%);
    margin-top: 2.5rem;
    /* Assuming 1 rem = 16px */
    margin-bottom: 2.5rem;
    /* Assuming 1 rem = 16px */
}
</style>