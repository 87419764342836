<!-- eslint-disable -->
<template>
    <div class="w-100 text-center" @click="generateFile"> <i class="far fa-file-excel"></i> Excel {{ filename }}
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { exportToExcel, exportToPdf } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo", "type", "filename"],
    components: {
    },
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        async generateFile() {
            exportToExcel(this.changeKey(), this.filename + this.$moment().utc().format("YYYY-MM-DD"), true, false)
        },
        changeKey() {
            try {
                const newDataToExport = {}
                if (this.exportTo) {
                    const arrayCsiCode = []
                    this.exportTo?.map((item, i) => {
                        var obj = {}
                        obj["LibSite"] = item?.LibSite ? item?.LibSite : 'Old-version'
                        if (this.type === "csi")
                            obj["CSI CODE"] = item?.codecsi;
                        obj["nbMonths"] = item?.nbMonths ? item?.nbMonths : 'Old-version'
                        obj["date Debut "] = item?.dateDebut ? item?.dateDebut : 'Old-version'
                        obj["date Fin"] = item?.dateFin ? item?.dateFin : 'Old-version'
                        obj["Status"] = item?.isActive ? 'Active' : 'Subscription expired'


                        arrayCsiCode.push(obj)
                    });
                    newDataToExport[this.filename + this.$moment().utc().format("YYYY-MM-DD")] = arrayCsiCode
                }
                return newDataToExport
            } catch (error) {
                return []
            }
        }


    },
    mounted() { },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    }
};
</script>


<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>