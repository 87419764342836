<!-- eslint-disable -->
<template>
  <nav class="navbar" v-if="User">
    <a href="#" class="sidebar-toggler">
      <i data-feather="menu"></i>
    </a>
    <div class="navbar-content">
      <div class="selected-stores" v-if="currentSite?.sites?.length > 1">
        <p>
          <b>Les sites sélectionnés :</b>
          <span v-for="(site, indexSite) in currentSite?.sites" :key="indexSite">
            {{ site?.LibSite.toUpperCase() }}<span v-if="indexSite < currentSite.sites.length - 1">, </span>
          </span>
        </p>
      </div>
      <div class="selected-stores" v-else-if="currentSite?.LibSite">
        <p>
          <b>Les sites sélectionnés :</b>
          <span> {{ currentSite?.LibSite.toUpperCase() }} </span>
        </p>
      </div>
      <ul class="navbar-nav dropdown-profile">
        <li v-if="isAuthenticated && User.role !== 'manager'" class="nav-item dropdown">
          <b>{{ User.role }}</b>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img class="wd-30 ht-30 rounded-circle" :src="BaseUrl + User.avatar" alt="profile" />
          </a>
          <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="mb-3">
                <img class="wd-80 ht-80 rounded-circle" :src="User.avatar && BaseUrl + User.avatar" alt="avatar" />
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{ User.firstName }} {{ User.lastName }}</p>
                <p class="tx-12 text-muted">{{ User.email }}</p>
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{ User.role }}</p>
              </div>
            </div>
            <ul class="list-unstyled ">
              <li class="dropdown-item ">
                <SettingSidebar class="me-2" />
              </li>
            </ul>
            <ul v-if="isAuthenticated && isRole == 'manager'" class="list-unstyled ">
              <li class="dropdown-item ">
                <a href="/parametrages" class="text-body ms-0">
                  <i class="me-2 icon-md" data-feather="settings"></i>
                  <span>Parametres</span>
                </a>
              </li>
            </ul>
            <ul class="list-unstyled ">
              <li class="dropdown-item ">
                <a href="/profile" class="text-body ms-0">
                  <i class="me-2 icon-md" data-feather="edit"></i>
                  <span>Profile</span>
                </a>
              </li>
              <li class="dropdown-item py-2">
                <a @click.prevent="logout" class="text-body ms-0">
                  <i class="me-2 icon-md" data-feather="log-out"></i>
                  <span>Se Déconnecter</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import SettingSidebar from "@/components/SettingSidebar.vue";

export default {
  components: {
    SettingSidebar,
  },
  name: "NavBar",
  data() {
    return {
      day: [],
      yesterday: [],
      month: [],
      year: [],
      interval: null,
      chiffre_affaires: [],
    };
  },
  computed: {
    /* eslint-disable */
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      User: "StateUser",
      BaseUrl: "StateBase",
      res: "StateRes",
      currentSite: "currentSite",
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions(["LogOut"]),

    async logout() {
      try {
        await this.LogOut();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.badge {
  font-size: 0.9em;
}

.input-group.badges {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.dropdown-profile .dropdown-menu li {
  cursor: pointer;
}

.selected-stores {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width:992px) {

  .selected-stores {
    display: none;
  }
}
</style>
