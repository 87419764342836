<!-- eslint-disable -->
<template>
    <div class="d-flex align-items-center justify-content-center" v-if="restaurant">
        <div class="row w-100 mx-0 auth-page">
            <!-- <div class="col-md-6 mb-5" v-if="isAbonnementActif">
                <button type="button" @click="resilierAbonnement(restaurant._id)" class="btn btn-sm btn-warning w-100">
                    Résilier abonnement
                </button>
            </div> -->
            <div class="col-md-6 mb-5"
                v-if="!StateIsAbonnementActif && $store.state.managerWithStores?.associatedRestaurants.length">
                <router-link :to="{ name: 'renouveler' }" class="btn btn-sm btn-warning w-100">
                    <b>Je renouvelle l'abonnement</b>
                </router-link>
            </div>
            <div class="col-md-6 mb-5">
                <button type="button" @click="editRes(restaurant._id)" class="btn btn-sm btn-primary w-100">
                    <b>Modifier les information du restaurant</b> </button>
            </div>
            <div class="col-md-12 col-xl-12 mx-auto mb-5">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 stretch-card">
                            <div class="card">
                                <div class="card-body">
                                    <!-- Row -->
                                    <div class="row">
                                        <div v-if="restaurant.serieNumber" class=" mt-3 col-md-12">
                                            <div class="mb-5 text-center">
                                                <h3>
                                                    <b class="form-label">Numéro de série : </b>
                                                    <span> {{ restaurant.serieNumber }}</span>
                                                </h3>
                                            </div>
                                        </div>
                                        <div v-if="restaurant.LibSite" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Nom du restaurant : </b>
                                                <span> {{ restaurant.LibSite }}</span>
                                            </div>
                                        </div>
                                        <div v-if="restaurant.EmetteurSociete" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Societe : </b>
                                                <span> {{ restaurant.EmetteurSociete }} </span>
                                            </div>
                                        </div>
                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurTelephone" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Téléphone : </b>
                                                <span>
                                                    {{ restaurant.EmetteurTelephone }}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurAdresse" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Adresse : </b>
                                                <span>
                                                    {{ restaurant.EmetteurAdresse }}
                                                </span>
                                            </div>
                                        </div>

                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurCodePostal" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">CodePostal :
                                                </b>
                                                <span>
                                                    {{ restaurant.EmetteurCodePostal }}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurVille" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Ville : </b>
                                                <span>
                                                    {{ restaurant.EmetteurVille }}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurNumTVA" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">NumTVA : </b>
                                                <span>
                                                    {{ restaurant.EmetteurNumTVA }}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- Col -->
                                        <div v-if="restaurant.EmetteurPays" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Pays : </b>
                                                <span>
                                                    {{ restaurant.EmetteurPays }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="restaurant.EmetteurSiret" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Siret : </b>
                                                <span>
                                                    {{ restaurant.EmetteurSiret }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="restaurant.EmetteurEtablissement" class="col-sm-4">
                                            <div class="mb-3">
                                                <b class="form-label">Etablissement : </b>
                                                <span>
                                                    {{ restaurant.EmetteurEtablissement }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-12 mx-auto mb-5" v-if="restaurant?.abonnements?.length > 0">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 stretch-card ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="noble-ui-logo d-block mb-5">
                                        <div class="row">
                                            <div class="col-md-5">
                                                Abonnements
                                            </div>
                                            <div class="col-md-7  ">
                                                <div class="d-flex justify-content-start align-items-center">
                                                    <!-- <div class="code-color pending">A venir </div> -->
                                                    <div class="code-color actif">Actif </div>
                                                    <div class="code-color inactif">Inactif </div>
                                                    <div class="code-color cancled">Annuler </div>
                                                    <div class="code-color closed">Férmer </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4 mb-4" v-for="(abonnement, indexabonnements) in abonnements"
                                            v-bind:key="indexabonnements">
                                            <div class="abonnement" :class="abonnement?.check?.status">
                                                <p>
                                                    <b class="abn-label">Date début</b> : {{
                                                        abonnement?.dateDebut }}
                                                </p>
                                                <p>
                                                    <b class="abn-label">Date fin</b> : {{
                                                        abonnement?.dateFin }}
                                                </p>
                                                <p>
                                                    <b class="abn-label">Jours restant</b> : {{
                                                        abonnement?.check?.remainingDays }} jour(s)
                                                </p>
                                                <p v-if="abonnement?.closed">
                                                    <b class="abn-label">Motif </b> : {{ abonnement?.motif }}
                                                </p>

                                                <p v-if="abonnement?.check?.message">
                                                    <b class="abn-label">Status </b> : {{ abonnement?.check?.message }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-12 mb-3">
                <div class="noble-ui-logo d-block mb-2">
                    Liste<span>&nbsp;des serveurs</span>
                </div>
            </div>
            <div class="col-md-12 col-xl-12 mx-auto">
                <div class="row">
                    <div v-for="(user, index) in waiters" v-bind:key="`waiter-${index}`" class="col-md-4 col-xl-4 mb-4">
                        <div class="usercard p-3">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img class=" rounded"
                                        :src="(user.avatar) ? BaseUrl + user.avatar : BaseUrl + 'default.png'" alt="avatar"
                                        width="155">
                                    <span>
                                        {{ user.username }}
                                    </span>
                                </div>
                                <div class="ml-3 w-100">
                                    <h5 class="mb-0 mt-0">{{ user.firstName }} {{ user.lastName }}</h5>
                                    <p>
                                        <span class="email">{{ user.email }}</span>
                                    </p>
                                    <p>
                                        <span class="articles">Role : {{ user.role.toUpperCase() }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AddrestaurantView",
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", ManagerWithStore: "StateManagerWithStore", StateIsAbonnementActif: "StateIsAbonnementActif" }),
    },
    created() {
        if (this.$route.params.id) {
            const get = async () => {
                // try {
                this.restaurant = this.$store.state?.managerWithStores?.associatedRestaurants?.filter((restaurant) => restaurant?._id == this.$route.params.id)[0] || null

                if (this.restaurant) {
                    this.abonnements = this.restaurant?.abonnements
                    /*.map((abn, index) => {
                    abn.check = this.checkAbonnementStatus(abn);
                    return abn
                })*/
                    this.abonnements.sort((a, b) => (!a.isActive === !b.isActive ? 0 : !a.isActive ? -1 : 1));
                }
            };
            if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
                get();
        }
    },
    data() {
        return {
            manager: [],
            show: false,
            abonnements: [],
            isAbonnementActif: false,
            restaurant: null
        };
    },
    methods: {
        ...mapActions([
            "editRestaurant",
        ]),

        async editRes(store_id) {
            try {
                this.$router.push({
                    name: "modifier-restaurant-manager",
                    params: { id: store_id },
                });
            } catch (error) {
                console.log(error);
                //this.showAlert("Forbidden resource");
            }
        },
        async renouvelerAbonnement(store_id) {
            try {
                this.$router.push({
                    name: "renouveler-abonnement",
                    params: { id: store_id },
                });
            } catch (error) {
                console.log(error);
                //this.showAlert("Forbidden resource");
            }
        },
        async resilierAbonnement(store_id) {
            try {
                this.$router.push({
                    name: "resilier-abonnement",
                    params: { id: store_id },
                });
            } catch (error) {
                console.log(error);
                //this.showAlert("Forbidden resource");
            }
        },
    },
};
</script>



<style>
.abonnement {
    padding: 18px;
    border-radius: 10px;
    background: #f0f0f0;
    height: 100%;
}

.abonnement.closed {
    border-top: 10px solid black;
}

.abonnement.inactif {
    border-top: 10px solid #ff4545;
}

.abonnement.actif {
    border-top: 10px solid #6ddc6d;
}

.abonnement.pending {
    border-top: 10px solid #5e5efb;
}

.abonnement.cancled {
    border-top: 10px solid orange;
}

.abn-label {
    display: inline-block;
    width: 100px;
}

.code-color {
    padding: 5px;
    width: 33.3333333%;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin: 5px;
}

.code-color.inactif {
    background-color: #ff4545;
    color: #fff;
}

.code-color.actif {
    background-color: #6ddc6d;
    color: #000;
}

.code-color.pending {
    background-color: #5e5efb;
    color: #000;
}

.code-color.cancled {
    background-color: orange;
    color: #000;
}

.code-color.closed {
    background-color: black;
    color: #fff;
}
</style>