<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="User">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <a href="#" class="noble-ui-logo d-block mb-2">Modifier<span>&nbsp;Profile</span></a>
                  <form @submit.prevent="SubmitData">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <div class="">
                          <label for="exampleInputUsername1" class="form-label">Nom d'utilisateur</label>
                          <input type="text" readonly class="form-control" id="exampleInputUsername1"
                            autocomplete="Username" placeholder="Nom d'utilisateur" v-model="form.username" />
                        </div>
                        <div class="errors-response" v-if="v$.form.username.$error">
                          <p v-if="!v$.form.username.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.username.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: "Nom d'utilisateur", min: v$.form.username.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.username.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: "Nom d'utilisateur", max: v$.form.username.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div class="">
                          <label for="userEmail" class="form-label">Adresse e-mail</label>
                          <input type="email" class="form-control" id="userEmail" placeholder="Adresse e-mail"
                            autocomplete="Email" v-model="form.email" />
                        </div>
                        <div class="errors-response" v-if="v$.form.email.$error">
                          <p v-if="!v$.form.email.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p v-if="!v$.form.email.email.$response">{{ $t('validation.email') }}
                          </p>
                          <p class="error" v-if="!v$.form.email.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'email', min: v$.form.email.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.email.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'email', max: v$.form.email.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div class="">
                          <label for="exampleInputFirstname1" class="form-label">Prénom</label>
                          <input type="text" class="form-control" id="exampleInputFirstname1" placeholder="Prénom"
                            autocomplete="first-name" v-model="form.firstName" />
                        </div>
                        <div class="errors-response" v-if="v$.form.firstName.$error">
                          <p v-if="!v$.form.firstName.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.firstName.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Prénom', min: v$.form.firstName.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.firstName.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Prénom', max: v$.form.firstName.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div class="">
                          <label for="exampleInputLastname1" class="form-label">Nom de famille</label>
                          <input type="text" class="form-control" id="exampleInputLastname1" placeholder="Nom de famille"
                            autocomplete="last-name" v-model="form.lastName" />
                        </div>
                        <div class="errors-response" v-if="v$.form.lastName.$error">
                          <p v-if="!v$.form.lastName.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.lastName.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Nom', min: v$.form.lastName.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.lastName.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Nom', max: v$.form.lastName.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Télécharger Avatar:</label>
                          <div class="row">
                            <div class="col-md-8">
                              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" :customUpload="true"
                                @select="uploadImage" />
                            </div>
                            <div class="col-md-4">

                              <div class="m-auto col-sm-1">
                                <!--  img avatar -->
                                <div class="d-flex align-items-center">
                                  <img class="img-lg rounded-circle"
                                    :src="!avatar ? User.avatar && BaseUrl + User.avatar : avatar" alt="avatar" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Row -->
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="mb-3 mt-3">
                          <button type="submit" class="w-100 btn btn-primary submit">
                            Modifier
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Row -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, sameAs } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "EditprofileView",
  computed: {
    ...mapGetters({ User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    this.form.username = this.User.username;
    this.form.email = this.User.email;
    this.form.firstName = this.User.firstName;
    this.form.lastName = this.User.lastName;
  },
  data() {
    return {
      avatar: "",
      image_file: null,

      form: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
      }

    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        username: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        email: {
          email,
          required,
          minLength: minLength(1),
          maxLength: maxLength(350)
        },
        firstName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        lastName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },

      }
    };
  },
  methods: {

    ...mapActions(["upload", "updateUser"]),
    uploadImage(event) {
      this.image_file = event.files[0];
      if (event.files[0].objectURL) {
        this.avatar = event.files[0].objectURL;
      }
    },
    async SubmitData() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const user = {
          id: this.User._id,
          username: this.form.username,
          email: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        };
        const result = await this.updateUser(user);
        const obj = { image: this.image_file, id: this.User._id };
        await this.upload(obj);
        if (result)
          this.$router.push({ name: "profile" });

      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
