<!-- eslint-disable -->
<template>
  <AddAbonnement @fetchAbonnementsData="fetchAbonnements" />
  <EditAbonnement @fetchAbonnementsData="fetchAbonnements" :abonnement="stateAbonnement" />
  <div class=" " v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-4 d-flex justify-content-between align-items-center">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des abonnements</span>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAdd">
          <i class="fas fa-plus"></i> Créer un Abonnement
        </button>
      </div>
    </div>

    <div class="row w-100 mx-0 auth-page mb-5 usercard p-3">
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <label for="libel" class="form-label">Nom</label>
            <input type="text" class="form-control" id="libel" placeholder="libel" v-model="search.libel" />
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="mb-3">
            <label for="price" class="form-label">Prix</label>
            <input type="text" class="form-control" id="price" placeholder="price" v-model="search.price" />
          </div>
        </div> -->
        <div class="col-md-4">
          <div class="mb-3">
            <label for="nbMonths" class="form-label">Nombre des mois</label>
            <select v-model="search.nbMonths" class="form-control">
              <option :value="1"> 1 mois</option>
              <option :value="3"> 3 mois</option>
              <option :value="6"> 6 Mois</option>
              <option :value="9"> 9 Mois</option>
              <option :value="12"> 1 Ans</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="nbMonths" class="form-label">Active</label>
            <select v-model="search.isEnabled" class="form-control">
              <option :value="null">
                Tous
              </option>
              <option :value="true">
                Activer
              </option>
              <option :value="false">
                Désactiver
              </option>
            </select>
          </div>
        </div>

      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="mb-3 w-100">
            <button type="button" class="float-end btn btn-primary w-33" @click="fetchAbonnements">
              Recherche
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-xl-12 mx-auto">
      <div class="row">
        <div v-if="abonnements?.length > 0" v-for="(abonnement, indexabonnement) in abonnements" v-bind:key="index"
          class="col-md-4 col-xl-4 mb-4">
          <div class="d-flex align-items-center usercard p-3" :class="{ 'abn-disabled': !abonnement?.isEnabled }">
            <div>
              <b>Nom </b>: <span>{{ abonnement?.libel }}</span>
              <p>
                <b>Prix </b>: <span class="email">{{ abonnement?.price }}</span>
              </p>
              <p>
                <b>Nombre de mois </b>: <span class="email">{{ abonnement?.nbMonths }}</span>
              </p>
              <p>
                <b>Description </b>: <span class="email">{{ abonnement?.description }}</span>
              </p>

              <div class="button mt-2 d-flex flex-row align-items-center">
                <button type="button" @click="stateAbonnement = abonnement" data-bs-toggle="modal"
                  data-bs-target="#modalEdit" class="btn btn-sm btn-primary w-100">
                  <i class="fas fa-edit"></i>
                </button>
                <button type="button" @click="remove(abonnement?._id)" class="btn btn-sm btn-danger w-100 ml-3">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12 text-center">
          <div class="card-body d-flex align-items-center justify-content-center">
            <datanotfound :text="$t(`content.message.datanotfound`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      try {
        this.fetchAbonnements()
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      abonnements: [],
      show: false,
      isModalOpen: false,
      stateAbonnement: null,
      subject: null,
      search: {
        libel: "",
        price: null,
        nbMonths: null,
        isEnabled: null,
      }
    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "deleteAbonnement",
      "searchAbonnements",
    ]),

    async fetchAbonnements() {
      const searchData = {}
      if (this.search?.libel)
        searchData['libel'] = this.search?.libel
      if (this.search?.price)
        searchData['price'] = this.search?.price
      if (this.search?.nbMonths)
        searchData['nbMonths'] = this.search?.nbMonths
      if (this.search?.isEnabled != null) {
        searchData['isEnabled'] = this.search?.isEnabled
      }
      this.abonnements = await this.searchAbonnements(searchData);
    },
    async remove(id) {
      try {
        const results = await this.deleteAbonnement({ id: id });
        this.fetchAbonnements()
        this.showSuccess();
      } catch (error) {
        if (error?.response?.status == 409)
          this.showAlert("Cannot delete the entity because it is referenced by other records.");
        else {
          this.showAlert("Nothing delete");
        }
      }
    },
  },
};
</script>


<style>
.abn-disabled {
  background-color: #f69a9a;
}
</style>