<!-- eslint-disable -->
<template>
    <div class="w-100 text-center" @click="generateFile"> <i class="far fa-file-excel"></i> Excel
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division } from "@/Utils/globale.js";
import { exportToExcel, exportToPdf } from "@/Utils/generatedFile.js";

export default {
    props: ["dateFrom", "dateTo", "exportTo"],
    components: {
    },
    data() {
        return {
            clicked: false,
        };
    },
    methods: {
        async generateFile() {
            exportToExcel(this.changeKey(), "report-syntheses_" + this.$moment().utc().format("YYYY-MM-DD HH mm"), true, false)
        },
        changeKey() {
            try {
                const newDataToExport = {}

                if (this.exportTo["data"]) {
                    const arrayCommande = []
                    arrayCommande.push({
                        "Ticket N° ": this.exportTo?.data?.NumTicket,
                        "site ": this.exportTo?.data?.LibSite,
                        "Adresse ": this.exportTo?.data?.EmetteurAdresse,
                        "Vendeur ": this.exportTo?.data?.LibVendeur,
                        "La Caisse ": this.exportTo?.data?.LibCaisse,
                        "Operation ": this.exportTo?.data?.Operation,
                        "Numéro de la table ": this.exportTo?.data?.TableNumber,
                        "Date de commande ": this.$dayjs(this.exportTo?.data?.Horodatage).utc().format("MM-DD-YYYY HH:mm"),
                        "Montant HT ": this.exportTo?.data?.MontantHT?.toFixed(2) + " €",
                        "Montant TTC ": this.exportTo?.data?.MontantTTC?.toFixed(2) + " €",
                        "Montant TVA ": this.exportTo?.data?.MontantTVA?.toFixed(2) + " €",
                        "Nb Couverts ": this.exportTo?.data?.NbCouverts,
                    });
                    newDataToExport['Détails de commandes'] = arrayCommande
                }

                if (this.exportTo["commandes"]?.length > 0) {
                    const arrayArticles = []

                    this.exportTo["commandes"]?.map((item, i) => {
                        if (item?.ARTICLE?.length > 0) {
                            item?.ARTICLE?.map((article, i) => {
                                arrayArticles.push({
                                    "COMMANDE ID ": item?.GUID,
                                    "Site ": article?.LibSite,
                                    "Vendeur ": article?.LibVendeur,
                                    "Groupe ": article?.LibGroupe,
                                    "Famille ": article?.LibFamille,
                                    "Libelle ": article?.Libelle,
                                    "TVA ": article?.LibTVA,
                                    "Quantite ": article?.OFFERT ? article?.OFFERT?.Quantite : article?.QuantiteNet,
                                    "PrixUnitaireTTC ": article?.PrixUnitaireTTC?.toFixed(2) + " €",
                                    "PrixTotalNetHT ": article?.OFFERT ? article?.OFFERT?.Libelle : article?.PrixTotalNetHT?.toFixed(2) + " €",
                                    "PrixTotalNet ": article?.OFFERT ? article?.OFFERT?.Libelle : article?.PrixTotalNet?.toFixed(2) + " €",
                                });
                            });
                        }
                    });
                    newDataToExport['Liste des articles'] = arrayArticles
                }
                if (this.exportTo["reglements"]?.length > 0) {
                    const arrayReglements = []
                    this.exportTo["reglements"]?.map((item, i) => {
                        arrayReglements.push({
                            "Site ": item?.LibSite,
                            "Group ": item?.LibFamille,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Valeur Unitaire ": item?.ValeurUnitaire?.toFixed(2) + " €",
                            "Total Value (€) ": item?.TotalValue?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['Reglements'] = arrayReglements
                }

                if (this.exportTo["tvas"]?.length > 0) {
                    const arrayTva = []
                    this.exportTo["tvas"]?.map((item, i) => {
                        arrayTva.push({
                            "Site ": item?.LibSite,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Libelle ": item?.Libelle,
                            "Taux ": item?.Taux,
                            "TVA ": item?.ValeurTVA?.toFixed(2) + " €",
                            "HT ": item?.ValeurHT?.toFixed(2) + " €",
                            "TTC ": item?.ValeurTTC?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['TVA'] = arrayTva
                }

                if (this.exportTo["statistiques"]?.length > 0) {

                    const arrayStatistiques = []
                    this.exportTo["statistiques"]?.map((item, i) => {
                        arrayStatistiques.push({
                            "Site ": item?.LibSite,
                            "Type ": item?.LibFamille,
                            "La Caisse ": item?.LibCaisse,
                            "Vendeur ": item?.LibVendeur,
                            "Libelle ": item?.Libelle,
                            "Type ": item?.LibFamille,
                            "Quantité ": item?.Quantite,
                            "Valeur (€) ": item?.Valeur?.toFixed(2) + " €",
                        })
                    });
                    newDataToExport['Statistiques'] = arrayStatistiques
                }
                return newDataToExport
            } catch (error) {
                return []
            }
        }


    },
    mounted() { },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    }
};
</script>


<style scoped>
.bg-green {
    background-color: #00d900 !important;
    font-size: 14px;
    border: none;
}
</style>