<!-- eslint-disable -->
<template>
  <div class="pagination-btn">
    <ul v-if="pagination.last_page != 1">
      <li v-if="pagination.current_page > 1" class="btn prev" @click="changePage(pagination.current_page - 1)">
        <a aria-label="Précédent">
          <span aria-hidden="true"> Précédent </span>
        </a>
      </li>
      <li v-for="page in pagesNumber" :key="page" :class="{ active: page == pagination.current_page }" class="numb"
        @click="changePage(page)">
        <a>{{ page }}</a>
      </li>
      <li v-if="pagination.current_page < pagination.last_page" class="btn last"
        @click="changePage(pagination.current_page + 1)">
        <a aria-label="Suivant">
          <span aria-hidden="true"> Suivant </span>
        </a>
      </li>
    </ul>
  </div>
</template>
<!-- eslint-disable -->
<script>
export default {
  props: ["pagination", "offset"],
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return [];
      }
      let from = this.pagination.current_page - this.pagination.offset;
      if (from < 1) {
        from = 1;
      }
      let to = from + this.pagination.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      let pagesArray = [];
      for (let page = from; page <= to; page++) {
        pagesArray.push(page);
      }
      return pagesArray;
    },
  },
  methods: {
    changePage(page) {
      this.pagination.current_page = page;
      this.$emit("paginate", this.pagination);
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.pagination-btn {
  margin-top: 20px;
}

.pagination-btn ul {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-btn li.active {
  background: #fff;
  color: #094694;
  border: 1px solid #094694;
}

.pagination-btn li {
  margin: 8px !important;
  background: #094694;
  color: #fff;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}
</style>
