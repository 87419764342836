<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="noble-ui-logo d-block mb-2">Renouveler<span>&nbsp; l'abonnement</span></div>
                  <form @submit.prevent="SubmitData">
                    <div class="row mt-5 mb-5">
                      <div class="col-md-6" v-if="currentSite?.serieNumber">
                        <div class="">
                          <input type="text" readonly class="form-control"
                            :value="`Numéro de série : ${currentSite?.serieNumber}`" />
                        </div>
                      </div>
                      <div class="col-md-6" v-if="currentSite?.LibSite">
                        <div class="">
                          <input type="text" readonly class="form-control"
                            :value="`Nom du restaurant : ${currentSite?.LibSite}`" />
                        </div>
                      </div>
                    </div>
                    <div class="row  mt-3 mb-3 ">
                      <div class="col-md-12 mb-3 ">
                        <h5>Type d'abonnement</h5>
                      </div>
                      <div class="col-md-12 mb-3 ">
                        <div class="d-flex justify-content align-items-end">
                          <div class="form-check  me-4">
                            <input class="form-check-input" type="radio" id="abonnement" v-model="selectedType"
                              value="abonnement">
                            <label class="form-check-label" for="abonnement">Direct</label>
                          </div>
                          <div class="form-check  me-4">
                            <input class="form-check-input" type="radio" id="codecsi" v-model="selectedType"
                              value="codecsi">
                            <label class="form-check-label" for="codecsi">Code CSI</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedType == 'abonnement'" class="row mb-3 section-abonnement ">
                      <div class="col-md-12 mb-3">
                        <div class="">
                          <h5>Direct : </h5>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="d-flex justify-content align-items-end flex-wrap grid-margin">
                          <span v-for="(abonnement, index) in abonnements" class="type_ca float-end">
                            <span @click="updateDates(abonnement)" type="button"
                              class="type-abonnement btn-icon-text me-3 mb-3 mb-md-0 "
                              :class="{ 'selected-default-dates': abonnement.nbMonths == selectedAbonnement?.nbMonths }">{{
                                abonnement?.libel }}</span>
                          </span>
                        </div>
                        <div class="w-100">
                          <p> <b>Date de début</b> : {{ dateDebut }}</p>
                          <p> <b>Date de fin</b> : {{ dateFin }}</p>
                          <p> <b>Prix HT</b> : {{ selectedAbonnement?.price?.toFixed(2) }} €</p>
                          <p> <b>TVA</b> : 20%</p>
                          <p> <b>Prix TTC</b> : {{ (selectedAbonnement?.price * (1 + 0.2)).toFixed(2)
                            }} €</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedType == 'codecsi'" class="row mb-3  section-abonnement">
                      <div class="col-md-12 mb-3">
                        <div class="">
                          <h5>Code CSI : </h5>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="text" class="form-control" id="codecsi" placeholder="Code CSI" v-model="codecsi" />
                      </div>
                    </div>

                    <!-- <div class="row mb-3 ">
                      <div class="col-md-12 mt-3 mb-3">
                        <div class="">
                          <h4>Abonnement : </h4>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="d-flex justify-content align-items-end flex-wrap grid-margin">
                          <span v-for="(abonnement, index) in abonnements" class="type_ca float-end">
                            <span @click="updateDates(abonnement)" type="button"
                              class="btn btn-info btn-icon-text me-3 mb-3 mb-md-0 "
                              :class="{ 'selected-default-dates': abonnement.nbMonths == selectedAbonnement?.nbMonths }">{{
                                abonnement?.libel }}</span>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p> <b>Date de début</b> : {{ dateDebut }}</p>
                        <p> <b>Date de fin</b> : {{ dateFin }}</p>
                        <p> <b>Prix</b> : {{ selectedAbonnement?.price?.toFixed(2) }} €</p>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-md-12 mt-5">
                        <div class="mb-3">
                          <button type="submit" class="btn btn-primary w-100 submit">
                            Enregister
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";


export default {
  name: "AddrestaurantView",
  components: {
    VueDatePicker
  },
  computed: {
    ...mapGetters({ isRole: "isRole", User: "StateUser", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", currentSite: "currentSite", }),

  },
  watch: {
    selectedType(newv, oldv) {
      if (newv == 'abonnement') {
        this.codecsi = null
        this.selectedAbonnement = null
        this.abonnement = []
        if (this.abonnements?.length > 0) {
          this.updateDates(this.abonnements[0])
        }
      } else if (newv == 'codecsi') {
        this.codecsi = null
        this.selectedAbonnement = null
        this.abonnement = []

      }
    }
  },
  mounted() {
    this.fetchAbonnements()
  },
  data() {
    return {
      newAbn: {},
      dateDebut: new Date().toLocaleDateString(),
      dateFin: null,
      show: false,
      abonnements: [],
      abonnement: {},
      selectedAbonnement: null,
      codecsi: null,
      selectedType: 'abonnement',
    };
  },
  methods: {
    ...mapMutations(["setRenouvelerSite"]),
    ...mapActions(["renouvlerAbonnement", "searchAbonnements"]),
    async fetchAbonnements() {
      const searchData = {}
      searchData['isEnabled'] = true
      this.abonnements = await this.searchAbonnements(searchData);
      if (this.abonnements?.length > 0) {
        this.updateDates(this.abonnements[0])
      }
    },
    updateDates(abonnement) {
      if (abonnement) {
        const currentDate = new Date();
        this.selectedAbonnement = abonnement
        const dateFin = new Date(currentDate);
        dateFin.setDate(currentDate.getDate() + (abonnement?.nbMonths * 30));
        this.dateFin = dateFin.toLocaleDateString();
      }

    },

    async SubmitData() {



      if (this.selectedType == 'abonnement') {
        if (!this.selectedAbonnement?._id) {
          alertSwal("error", "abonnement est obligatoire");
          return;
        }
        const parsedDateDebut = this.$moment(this.dateDebut, 'DD/MM/YYYY');
        const isoDateDebut = parsedDateDebut.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const parsedDateFin = this.$moment(this.dateFin, 'DD/MM/YYYY');
        const isoDateFin = parsedDateFin.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        this.abonnement =
        {
          dateDebut: isoDateDebut,
          dateFin: isoDateFin,
          motif: '',
          closed: false,
          isActive: false,
          abonnementID: this.selectedAbonnement?._id || null,
          nbMonths: this.selectedAbonnement?.nbMonths || null,
          price: Math.round((this.selectedAbonnement?.price * (1 + 0.2)) * 100) || null,
          codecsi: null,
          payementCancledAt: null,
          payementCancled: false,
        }

      } else if (this.selectedType == 'codecsi') {
        if (!this.codecsi || this.codecsi == null) {
          alertSwal("error", "code CSI est obligatoire");
          return;
        }
        this.dateFin = this.dateDebut
        const parsedDateDebut = this.$moment(this.dateDebut, 'DD/MM/YYYY');
        const isoDateDebut = parsedDateDebut.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const parsedDateFin = this.$moment(this.dateFin, 'DD/MM/YYYY');
        const isoDateFin = parsedDateFin.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        this.abonnement =
        {
          dateDebut: isoDateDebut,
          dateFin: isoDateFin,
          motif: '',
          closed: false,
          isActive: false,
          abonnementID: null,
          nbMonths: 12,
          price: null,
          codecsi: this.codecsi || null,
          payementCancledAt: null,
          payementCancled: false,
        }
      }

      const restaurant = {
        id: this.currentSite?._id,
        userId: this.User?._id,
        name: this.currentSite?.name,
        abonnement: this.abonnement,

      };
      try {
        if (
          this.currentSite?._id === "" || this.dateDebut == null || this.dateFin == null || !this.User
        ) {
          alertSwal("error", "Veuillez remplir tous les champs");
        } else {
          const result = await this.renouvlerAbonnement(restaurant);
          if (result?.data?.status == 201 || result?.data?.modifiedCount > 0 || result?.data?.url) {
            this.setRenouvelerSite(this.currentSite?._id)
            alertSwal("success", "Success");
            window.location.href = result?.data?.url ? result?.data?.url : '/';
          } else if (result?.data?.status == 404) {
            alertSwal("error", "code CSI n'existe pas ou déja utilisé");
          } else {
            alertSwal("error", "Please Enter good informations");
          }
        }
      } catch (error) {
        console.log(error)
        if (error.response.status === 403) {
          alertSwal("error", "Forbidden");
          return;
        } else if (error.response.status === 404) {
          alertSwal("error", "Introuvable");
          return;
        }
        alertSwal("error", "Please Enter good informations");
      }
    },
  },
};
</script>
<!-- eslint-disable -->
<style scoped>
.type-abonnement {
  background: blue;
  color: #fff;
  padding: 5px 24px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  font-weight: 500;
  margin: 3px;
  position: relative;
}
</style>