<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-4">
        <span class="type_ca float-start">
          <span>
            <router-link to="/managers">Revenir</router-link>
          </span>
        </span>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 ps-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <a href="#" class="noble-ui-logo d-block mb-4">Add or remove a restaurant for the manager</a>

                <form class="forms-sample" @submit.prevent="SubmitData">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label">Nom d'utilisateur</label>
                        <input readonly type="text" class="form-control" id="exampleInputUsername1"
                          placeholder="Nom d'utilisateur" v-model="username" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label">Choisir un
                          Restaurant</label>
                        <div></div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="">
                              <select class="form-select btn-outline-primary dropdown-toggle"
                                @change="onChangeSelect($event)">
                                <option selected disabled>
                                  Sélectionnez Restaurants.
                                </option>
                                <option v-for="(restaurant, index) in restaurants" v-bind:key="index"
                                  :selected="selectRes === restaurant.LibSite ? true : false"
                                  :value="JSON.stringify(restaurant)">{{
                                    index
                                    +
                                    1
                                  }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ restaurant.LibSite.toUpperCase() }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" v-for="(rest, indexLib) in SelectedNomRestaurants">
                      <div class="mb-3">
                        <div class="selected-store">
                          <p>
                            <b>Nom : </b> {{ rest.LibSite }}
                          </p>
                          <span class="remove-it" @click="removeRestaurant(indexLib)">
                            X
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5">
                      <button type="submit" class="btn btn-primary text-white w-100">
                        Modifier</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditmanagerView",
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", Username: "StateUsername", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      this.restaurants = await this.getAllRestaurant();
      this.user = await this.getManagerByName(this.Username);
      this.id = this.user._id;
      this.username = this.user.username;
      this.user.restaurant.map((rest, index) => {
        const selectedRestaurant = this.restaurants.find(restaurant => restaurant._id === rest);
        if (selectedRestaurant) {
          this.SelectedIDRestaurants.push(selectedRestaurant?._id);
          this.SelectedNomRestaurants.push(selectedRestaurant);
        }
      })

    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      avatar: "",
      user: {
        _id: "",
        username: "",
      },
      id: "",
      username: "",
      restaurants: [],
      SelectedIDRestaurants: [],
      SelectedNomRestaurants: [],
    };
  },
  methods: {
    removeRestaurant(index) {
      this.SelectedNomRestaurants.splice(index, 1);
      this.SelectedIDRestaurants.splice(index, 1);
    },
    onChangeSelect(event) {
      // this.restaurant = event.target.value;
      const rest = JSON.parse(event.target.value)
      const index = this.SelectedIDRestaurants.indexOf(rest?._id);
      if (index === -1) {
        // If not in the array, add it
        this.SelectedIDRestaurants.push(rest?._id);
        this.SelectedNomRestaurants.push(rest);
      } else {
        // If already in the array, remove it
        this.SelectedNomRestaurants.splice(index, 1);
        this.SelectedIDRestaurants.splice(index, 1);
      }
    },
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "edit is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "getRestaurantById",
      "getManagerByName",
      "addOrRemoveStoreFromMananger",
      "getAllRestaurant",
    ]),
    async SubmitData() {
      // add more logic here
      const user = {
        id: this.user._id,
        username: this.username,
        restaurant: this.SelectedIDRestaurants,
      };
      try {
        if (
          !user.restaurant.length
        ) {
          this.showAlert("Toutes les champs sans obligatoire");
        } else {
          this.addOrRemoveStoreFromMananger(user);
          this.$router.push({ name: "managers" });
          this.showSuccess();
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.showAlert("Forbidden");
          return;
        }
        this.showAlert("Please Enter good informations");
      }
    },
  },
};
</script>


<style>
.selected-store {
  padding: 15px;
  background: #dccbcb;
  position: relative;
  border-radius: 4px;
  margin-bottom: 5px;
}


.selected-store .remove-it {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
}
</style>