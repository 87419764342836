/*eslint-disable*/
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import axiosInstance from "../axios-api";
import router from "../router/index";
import { alertSwal } from "../Utils/globale.js";
export default createStore({
  state: {
    isLoading: false,
    appVersion: process.env.VUE_APP_VERSION,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    globalSearch: {
      SelectedLibCaisses: [],
      SelectedLibVendeurs: [],
      SelectedEtats: [],
      SelectedOperations: []
    },
    roles: ['super-admin', 'admin', 'manager'],

    datePicker: {
      selectedDates: null,
      date: {
        from: null,
        to: null,
        range: [],
      },
      date_n: {
        from: null,
        to: null,
        range: [],
      },
      defaultDates: {
        type: "today",
        list: []
      },

    },
    baseUrl: `${process.env.VUE_APP_BASE_URL}user/img/`,
    user: null,
    username: "",
    storename: "",
    res: "",
    ticket_id: null,
    status: false,
    LibVendeur: (localStorage.getItem("LibVendeur")) ? localStorage.getItem("LibVendeur") : 'vendeur',
    currentSite: (localStorage.getItem("currentSite")) ? localStorage.getItem("currentSite") : null,
    parametrages: {},
    managerWithStores: null,
    selectedSite: null,
    formLoader: null,
    chiffre_affaires: []
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isAuthenticated: (state) => !!state.user,
    isRole: (state) => state?.user?.role,
    StateUser: (state) => state.user,
    StateUsername: (state) => state.username,
    StateStorename: (state) => state.storename,
    StateBase: (state) => state.baseUrl,
    StateRes: (state) => state.res,
    currentSite: (state) => state.currentSite,
    StateIsAbonnementActif: (state) => (state?.currentSite?.latestAbonnemnt == null || state?.currentSite?.latestAbonnemnt?.status == 'inactif') ? null : state?.currentSite?.latestAbonnemnt,
    StateParametrages(state) {
      var parametrages = localStorage.getItem('parametrages') || null
      parametrages = parametrages ? JSON.parse(parametrages) : {}
      state.parametrages = parametrages;
      return state.parametrages
    },
    StateManagerWithStore: (state) => state?.managerWithStores,
    TimeDebutCalclule(state) {
      return {
        "TimeDebut": state?.parametrages?.calculeTicket?.TimeDebut || '00:00',
        "TimeFin": state?.parametrages?.calculeTicket?.TimeFin || '23:59'
      }
    },
    listChiffreAffaires: (state) => state.chiffre_affaires?.data,
  },
  mutations: {
    setRenouvelerSite(state, value) {
      state.selectedSite = value
      state.currentSite = null
    },
    setManagerWithAssociatedStores(state, manager) {
      state.managerWithStores = manager;
    },
    setCurrentSite(state, currentSite) {
      state.currentSite = currentSite;
      state.selectedSite = null
    },
    setUser(state, user) {
      state.user = user;
      //state.currentSite = state?.user?.restaurant[]
    },
    setRes(state, res) {
      state.res = res;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setStorename(state, storename) {
      state.storename = storename;
    },
    setTicketID(state, ticket_id) {
      state.ticket_id = ticket_id;
    },
    logout(state, user) {
      state.user = user;
    },
    status(state, status) {
      state.status = status;
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    formLoader(state, value) {
      state.formLoader = value;
    },
    setParametrages(state, parametrages) {
      localStorage.setItem('parametrages', JSON.stringify(parametrages))
    },
    setHeaders(state, token) {
      state.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    clearState(state, clear) {
      state.headers = {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
      state.globalSearch = {
        SelectedLibCaisses: [],
        SelectedLibVendeurs: [],
        SelectedEtats: [],
        SelectedOperations: []
      }
      state.roles = ['super-admin', 'admin', 'manager']
      state.datePicker = {
        selectedDates: null,
        date: {
          from: null,
          to: null,
          range: [],
        },
        date_n: {
          from: null,
          to: null,
          range: [],
        },
        defaultDates: {
          type: "today",
        }
      }
      state.baseUrl = `${process.env.VUE_APP_BASE_URL}user/img/`
      state.user = null
      state.username = ""
      state.storename = ""
      state.res = ""
      state.ticket_id = null
      state.status = false
      state.LibVendeur = (localStorage.getItem("LibVendeur")) ? localStorage.getItem("LibVendeur") : 'vendeur'
      state.currentSite = (localStorage.getItem("currentSite")) ? localStorage.getItem("currentSite") : null
      state.parametrages = {}
      state.managerWithStores = null
    }
  },
  actions: {
    async selectedCurrentSite(context, currentSite) {
      localStorage.setItem('currentSite', JSON.stringify(currentSite))
      context.commit("setCurrentSite", currentSite);
      if (currentSite?.latestAbonnemnt == null || currentSite?.latestAbonnemnt?.status == 'inactif') {
        location.href = '/abonnement-expirer'
        // router.push('/abonnement-expirer')
      } else {
        location.href = '/'
      }
    },
    // eslint-disable-next-line
    async LogIn(context, user) {
      localStorage.clear();
      context.commit("clearState", null)
      const User = await axios.post("auth/login", user);
      localStorage.setItem("jwt", User.data.access_token);
      context.commit("setUser", User.data.user);
      context.commit("setHeaders", User.data.access_token);
    },
    async upload(context, obj) {
      if (!obj.image) {
        return;
      }
      const formData = new FormData();
      formData.append("image", obj.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const User = await axiosInstance.put(
        `user/image/${obj.id}`,
        formData,
        config
      );
      await context.commit("setUser", User.data);
    },
    async updateUser(context, user) {
      try {
        context.commit("formLoader", true);
        const User = await axios.put(`user/${user.id}`, user, {
          headers: this.state.headers
        });
        context.commit("setUser", User.data);
        alertSwal("success", "Votre profile a ete modifiée avec success");
        return User
      } catch (error) {
        context.commit("formLoader", false);
        if (error?.response?.status === 400) {
          alertSwal("error", "Somthing wrong try again");
        } else if (error?.response?.status === 403) {
          alertSwal("error", "Forbidden");
        } else {
          alertSwal("error", "Please Enter good informations");
        }
      } finally {
        context.commit("formLoader", false);
      }
    },
    // eslint-disable-next-line
    async createManager(context, user) {
      const User = await axios.post(`user/manager`, user, {
        headers: this.state.headers
      });
      if (!user.image) {
        return;
      }
      const formData = new FormData();
      formData.append("image", user.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      await axiosInstance.put(`user/image/${User.data._id}`, formData, config);
    },
    // eslint-disable-next-line
    async createCustomAccountManager(context, user) {

      try {
        context.commit("formLoader", true);
        const response = await axios.post(`user/register`, user, {
          headers: this.state.headers
        });
        if (response?.status == 201 && response?.data) {
          const User = await axios.post("auth/login", { username: response?.data?.email, password: user?.password });
          localStorage.setItem("jwt", User.data.access_token);
          context.commit("setUser", User.data.user);
          context.commit("setHeaders", User.data.access_token);
        }
        alertSwal("success", "Votre compte a été crée avec success");
      } catch (error) {
        context.commit("formLoader", false);
        if (error?.response?.data?.message == 'username_already_existe') {
          alertSwal("error", "Nom d'utilisateur existe déjà");
        } else if (error?.response?.data?.message == 'societename_already_existe') {
          alertSwal("error", "Nom de société existe déjà");
        } else if (error.response.status === 403) {
          alertSwal("error", "Forbidden");
        }
        else {
          alertSwal("error", "Please Enter good informations");
        }

      } finally {
        context.commit("formLoader", false);
      }

    },
    // eslint-disable-next-line
    async createWaiter(context, user) {
      const User = await axiosInstance.post(`user/waiter`, user);
      if (!user.image) {
        return;
      }
      const formData = new FormData();
      formData.append("image", user.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      await axiosInstance.put(`user/image/${User.data._id}`, formData, config);
    },
    // eslint-disable-next-line
    async updateManager(context, man) {
      const Man = await axiosInstance.put(`user/manager/${man.id}`, man);
      if (!man.image) {
        return;
      }
      const formData = new FormData();
      formData.append("image", man.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      await axiosInstance.put(`user/image/${Man.data._id}`, formData, config);
    },
    // eslint-disable-next-line
    async addOrRemoveStoreFromMananger(context, man) {
      const Man = await axiosInstance.put(`user/add-store-to-manager/${man.id}`, man);
      context.commit("status", true);
    },
    // eslint-disable-next-line
    async updateWaiter(context, wai) {
      const Wai = await axiosInstance.put(`user/waiter/${wai.id}`, wai);
      if (!wai.image) {
        return;
      }
      const formData = new FormData();
      formData.append("image", wai.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      await axiosInstance.put(`user/image/${Wai.data._id}`, formData, config);
    },
    // eslint-disable-next-line
    async updatePassword(context, passwordObj) {
      try {
        context.commit("formLoader", true);
        const data = await axios.patch('user/editpassword', passwordObj, {
          headers: this.state.headers
        });
        alertSwal("success", "Votre mot de passe a ete modifiée avec success");
        return data
      } catch (error) {
        context.commit("formLoader", false);
        if (error?.response?.data?.message == 'actual_password_incorrect') {
          alertSwal("error", "Your current password is missing or incorrect");
        } else if (error.response.status === 403) {
          alertSwal("error", "Forbidden");
        }
        else {
          alertSwal("error", "Please Enter good informations");
        }
      } finally {
        context.commit("formLoader", false);
      }
    },
    async updateRestaurant(context, store) {
      context.commit("status", true);
      await axiosInstance.put(`restaurant/${store.id}`, store);
    },
    async renouvelerAbonnement(context, store) {
      context.commit("status", true);
      await axiosInstance.put(`restaurant/renouveler-abonnement/${store.id}`, store);
    },
    async resilierAbonnement(context, store) {
      context.commit("status", true);
      await axiosInstance.put(`restaurant/resilier-abonnement/${store.id}`, store);
    },
    async getAllManager() {
      const users = await axiosInstance.get(`user/manager/all`);
      return users.data;
    },
    async getAllAdmin(context, saufID) {
      const users = await axiosInstance.get(
        `user/list-admins/${saufID}`
      );
      context.commit("status", true);
      return users.data;
    },

    async getAllManagerWithoutAuth(context, saufID) {
      const users = await axiosInstance.get(
        `user/manager/without-auth/${saufID}`
      );
      context.commit("status", true);
      return users.data;
    },

    async getAllWaiter() {
      const users = await axiosInstance.get("user/waiter/all");
      return users.data;
    },
    async getAllWaiterWithoutAuth(context, saufID) {
      const users = await axiosInstance.get(
        `user/waiter/without-auth/${saufID}`
      );
      context.commit("status", true);
      return users.data;
    },
    async getAllRestaurant() {
      const users = await axiosInstance.get("restaurant");
      return users.data;
    },
    // eslint-disable-next-line
    async getRestaurantById(context, id) {
      const rest = await axiosInstance.get(`restaurant/id/${id}`);
      context.commit("status", true);
      return rest.data;
    },
    // eslint-disable-next-line
    async getRestaurantByName(context, storename) {
      const rest = await axiosInstance.get(`restaurant/${storename}`);
      context.commit("status", true);
      return rest.data;
    },
    // eslint-disable-next-line
    async getStoreByName(context, storename) {
      const rest = await axiosInstance.get(`restaurant/${storename}`);
      context.commit("status", true);
      return rest.data;
    },
    // eslint-disable-next-line
    async getManagerByName(context, username) {
      const users = await axiosInstance.get(`user/${username}`);
      context.commit("status", true);
      return users.data;
    },
    // eslint-disable-next-line
    async deleteManager(context, id) {
      context.commit("status", true);
      await axiosInstance.delete(`user/manager/${id}`);
    },
    // eslint-disable-next-line
    async deleteWaiter(context, id) {
      context.commit("status", true);
      await axiosInstance.delete(`user/waiter/${id}`);
    },
    // eslint-disable-next-line
    async deleteRestaurant(context, id) {
      context.commit("status", true);
      await axiosInstance.delete(`restaurant/${id}`);
    },
    async editAdmin(context, username) {
      context.commit("setUsername", username);
    },
    async editManager(context, username) {
      context.commit("setUsername", username);
    },
    async editRestaurant(context, storename) {
      context.commit("setStorename", storename);
    },
    // eslint-disable-next-line
    async createRestaurant(context, restaurant) {
      context.commit("status", true);
      await axiosInstance.post(`restaurant`, restaurant);
    },
    // eslint-disable-next-line
    async createRestaurantByManeger(context, restaurant) {
      try {
        context.commit("formLoader", true);
        const data = await axiosInstance.post(`restaurant/create-restaurant-manager/${restaurant?.userID}`, restaurant);
        return data
      } catch (e) {
        context.commit("formLoader", false);
      } finally {
        context.commit("formLoader", false);
      }
    },
    // eslint-disable-next-line
    async validateStoreAfterPayementSuccess(context, validate) {
      try {
        //context.commit("formLoader", true);
        const data = await axiosInstance.post(`restaurant/validate-account`, validate);
        return data
      } catch (e) {
        context.commit("formLoader", false);
      } finally {
        context.commit("formLoader", false);
      }
    },
    // eslint-disable-next-line
    async removeAbonnemetWhenPayementCancled(context, validate) {
      try {
        //context.commit("formLoader", true);
        const data = await axiosInstance.post(`restaurant/supprimer-abonnement`, validate);
        return data
      } catch (e) {
        context.commit("formLoader", false);
      } finally {
        context.commit("formLoader", false);
      }
    },
    // eslint-disable-next-line
    async renouvlerAbonnement(context, renouveler) {
      try {
        context.commit("formLoader", true);
        const data = await axiosInstance.post(`restaurant/renouvler-abonnement`, renouveler);
        return data
      } catch (e) {
        context.commit("formLoader", false);
      } finally {
        context.commit("formLoader", false);
      }
    },
    async restaurantSubscribeWithCsiCode(context, body) {
      const subscriptions = await axiosInstance.post(
        `restaurant/store-subscribe-with-csicode`, body
      );
      context.commit("status", true);
      console.log(subscriptions)
      return subscriptions.data
    },
    // eslint-disable-next-line
    async LogOut(context) {
      await axiosInstance.get("auth/logout");
      let user = null;
      localStorage.removeItem("jwt");
      localStorage.removeItem("currentSite");
      localStorage.removeItem("parametrages");
      localStorage.removeItem("vuex");
      localStorage.clear();
      // context.commit("logout", user);
      // context.commit("setCurrentSite", null);
      // context.commit("setParametrages", null);
      context.commit("clearState", null)
      window.location.reload()
    },
    async LogOut1(context) {
      let user = null;
      localStorage.removeItem("jwt");
      localStorage.removeItem("currentSite");
      localStorage.removeItem("parametrages");
      localStorage.removeItem("vuex");
      context.commit("logout", user);
      window.location.reload()
    },
    async getAllEntete() {
      const entete = await axiosInstance.get("entete");
      return entete.data;
    },
    // eslint-disable-next-line
    async getTicketsByDate(context, date) {
      const entete = await axiosInstance.get(`entete/ticket/${date}`);
      context.commit("status", true);
      return entete.data;
    },
    // eslint-disable-next-line
    async getAllByDate(context, all) {
      // eslint-disable-next-line
      const entete = await axiosInstance.get(`entete/${all.url}/${all.date}/${all.date1}/${all.check}/${all.res}`);
      context.commit("status", true);
      return entete.data;
    },
    // eslint-disable-next-line
    async getAllByDateWaiter(context, all) {
      // eslint-disable-next-line
      const entete = await axiosInstance.get(`entete/${all.url}/${all.waiter}`);
      context.commit("status", true);
      return entete.data;
    },
    // eslint-disable-next-line
    async getTicket(context, all) {
      // eslint-disable-next-line
      const entete = await axiosInstance.get(`entete/${all.url}/${all.id}`);
      context.commit("status", true);
      return entete.data;
    },

    async getRes(context, res) {
      context.commit("setRes", res);
    },

    /** BY AYOUB */
    async getDataJson() {
      const xmltojs = await axiosInstance.get("entete/xmltojs");
      return xmltojs;
    },

    /*** get data from mongoDG */
    // async getXmlEntetes(context, all) {
    //   const entetes = await axiosInstance.get(
    //     `api/${all.url}/${all.page}/${all.skip}/${all.limit}`
    //   );
    //   context.commit("status", true);
    //   return entetes.data;
    // },
    async getTicketsByVendeurDates(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const entetes = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return entetes.data;
    },
    async getDetailsTicket(context, all) {
      context.commit("isLoading", true);
      const entetes = await axiosInstance.get(`api/${all.url}/${all._id}`);
      context.commit("isLoading", false);
      return entetes.data;
    },
    async getTransfertArticlesByGUUID(context, all) {
      context.commit("isLoading", true);
      const transfertArticles = await axiosInstance.get(`api/${all.url}/${all._GUID}`);
      context.commit("isLoading", false);
      return transfertArticles.data;
    },
    // async getXmlEntetesByDates(context, all) {
    //   const entetes = await axiosInstance.get(
    //     `api/${all.url}/${all.from}/${all.to}/${context.getters.TimeDebutCalclule}`
    //   );
    //   context.commit("status", true);
    //   return entetes.data;
    // },
    // async getEntetesByVendeur(context, all) {
    //   const commandes = await axiosInstance.get(
    //     `api/${all.url}/${all.vendeur}/${all.site}`
    //   ); // array
    //   context.commit("status", true);
    //   return commandes.data;
    // },
    async getMeilleursVents(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const meilleursventes = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return meilleursventes.data;
    },
    async getStatistiques(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const statistiques = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return statistiques.data;
    },
    async getReglements(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const reglements = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return reglements.data;
    },

    //:page?/:limit?/:from/:to/:vendeur?/:qte?/:groupbycaisse?
    async TotalReglements(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const total_reglements = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return total_reglements.data;
    },

    // async getTVA(context, formData) {
    //   const config = {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   };
    //   formData.calculeTicket = context.getters.TimeDebutCalclule
    //   const tva = await axiosInstance.post(
    //     `api/${formData.url}`, formData, config
    //   );
    //   context.commit("status", true);
    //   return tva.data;
    // },
    async getEntetesStaPrincipal(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const stat_principal = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return stat_principal.data;
    },
    async chiffreAffaires(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      this.state.chiffre_affaires = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return this.state.chiffre_affaires?.data;
    },
    async getCaisses(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const list_caisses = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("status", true);
      return list_caisses.data;
    },
    async getVendeurs(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const list_vendeurs = await axiosInstance.post(
        `${formData.url}`, formData, config
      );
      context.commit("status", true);
      return list_vendeurs.data;
    },
    async fetchPointeuses(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const list_pointeuses = await axiosInstance.post(
        `${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return list_pointeuses.data;
    },
    async fetchTotalDurations(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const listTotalDurations = await axiosInstance.post(
        `${formData.url}`, formData, config
      );
      context.commit("status", true);
      return listTotalDurations.data;
    },
    // eslint-disable-next-line
    async updateEtatVendeur(context, body) {
      const vendeurs = await axios.put(`vendeurs`, body, {
        headers: this.state.headers
      });
    },
    async getSitesWithAssociatedVendeurs(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const list_sites = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      // const restaurant = list_sites?.data?.length ? list_sites?.data[0] : []
      // localStorage.setItem('currentSite', JSON.stringify(restaurant))
      // context.commit("setCurrentSite", restaurant);
      context.commit("status", true);
      return list_sites.data;
    },
    async mangerWithAssociatedRestaurants(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const manager = await axiosInstance.post(
        `user/${formData.url}`, formData, config
      );
      // const restaurant = manager?.data?.length ? manager?.data[0] : []
      // localStorage.setItem('currentSite', JSON.stringify(restaurant))
      context.commit("setManagerWithAssociatedStores", manager?.data);
      context.commit("status", true);
    },

    async getEncaissement(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const encaissement = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("status", true);
      return encaissement.data;
    },

    async getTicketsByArticleBaliseName(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const tickets = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("status", true);
      return tickets.data;
    },
    // async getDetailsTicketBaliseName(context, all) {
    //   const config = {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   };
    //   formData.calculeTicket = context.getters.TimeDebutCalclule
    //   const ticket = await axiosInstance.post(
    //     `api/${formData.url}`, formData, config
    //   );
    //   context.commit("status", true);
    //   return ticket.data;
    // },

    /**** DASHBOARD  */
    //:page?/:limit?/:from/:to/:vendeur?/:qte?
    async BoardMeilleursVents(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const meilleursventes = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return meilleursventes.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?
    async BoardCaPerHour(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const meilleurscinpheurs = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return meilleurscinpheurs.data;
    },
    // Transaction par heur
    async BoardPaymentsPerHour(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const payments_per_hour = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return payments_per_hour.data;
    },
    //  Statistiques par serveur
    async BoardStatistiquesServeurs(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const statistiques_serveurs = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return statistiques_serveurs.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?
    async BoardRevenuStatistiques(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const revenustatistiques = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return revenustatistiques.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?/:groupbycaisse?
    async BoardChiffreAffaires(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const chiffre_affaires = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return chiffre_affaires.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?/:type_ca?
    async BoardChiffreAffairesChartJs(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const chiffre_affaires_chartjs = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return chiffre_affaires_chartjs.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?/:type_ca?
    async BoardCouvertsChartJs(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const couverts_chartjs = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return couverts_chartjs.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?
    async BoardStatPrincipal(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const stat_principal = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return stat_principal.data;
    },

    async BoardStatAllSites(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const stat_principal = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return stat_principal.data;
    },
    //:page?/:limit?/:from/:to/:vendeur?/:qte?
    async BoardMeilleurJourDuMois(context, formData) {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const meilleur_jour = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("status", true);
      return meilleur_jour.data;
    },

    /*** REPORT PAGE */
    async JournalierTVA(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const journalier_tva = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return journalier_tva.data;
    },
    async JournalierPayments(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const journalier_payments = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return journalier_payments.data;
    },
    //report/familly/:page?/:limit?/:from/:to/:vendeur?
    async ReportLibFamille(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const libfamille = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return libfamille.data;
    },
    //report/report/:page?/:limit?/:from/:to/:vendeur?
    async ReportTVA(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const tva = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return tva.data;
    },
    async ReportREMISE(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const remise = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return remise.data;
    },

    /// state
    async ArticlesoffertsOuRemises(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const articles_offerts_remises = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return articles_offerts_remises.data;
    },
    async ArticlesOfferts(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const articles_offerts = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return articles_offerts.data;
    },
    async ArticlesRemises(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const articles_remises = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return articles_remises.data;
    },
    async ArticelsAnnules(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      formData.calculeTicket = context.getters.TimeDebutCalclule
      const articles_annules = await axiosInstance.post(
        `api/${formData.url}`, formData, config
      );
      context.commit("isLoading", false);
      return articles_annules.data;
    },

    /*** user, manager, restaurants */
    // eslint-disable-next-line
    async getWaitersByRestaurantID(context, storeid) {
      const users = await axiosInstance.get(`user/waiters/store/${storeid}`);
      context.commit("status", true);
      return users.data;
    },
    async getManagersByRestaurantID(context, storeid) {
      const users = await axiosInstance.get(`user/managers/store/${storeid}`);
      context.commit("status", true);
      return users.data;
    },
    /*** PARAMETRES */
    // eslint-disable-next-line
    async createParametre(context, parametre) {
      const Parametre = await axiosInstance.post(`parametre`, parametre);
      if (!parametre.logo) {
        return;
      }
      /*const formData = new FormData();
      formData.append("logo", parametre.logo);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      //await axiosInstance.put(`parametre/image/${Parametre.data._id}`, formData, config);*/
    },
    async editParametre(context, parametre) {
      // context.commit("setParametre", parametre);
    },

    // eslint-disable-next-line
    async updateParametre(context, parametre) {
      const Parametre = await axiosInstance.put(`parametre/${parametre.id}`, parametre);
      if (!parametre.image) {
        return;
      }
      /*const formData = new FormData();
      formData.append("image", parametre.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      context.commit("status", true);
      //await axiosInstance.put(`user/logo/${Parametre.data._id}`, formData, config);*/
    },

    async getAllParametre() {
      const parametres = await axiosInstance.get(`parametre`);
      return parametres.data;
    },

    // eslint-disable-next-line
    async getParametreBySiteID(context, ID_SITE) {
      context.commit("isLoading", true);
      const parametre = await axiosInstance.get(`parametre/site/${ID_SITE}`);
      context.commit("isLoading", false);
      return parametre.data;
    },
    // eslint-disable-next-line
    async deleteParametre(context, id) {
      context.commit("status", true);
      await axiosInstance.delete(`parametre/${id}`);
    },

    // eslint-disable-next-line
    async updateParametrages(context, formData) {
      context.commit("isLoading", true);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const parametrages = await axiosInstance.put(`api/parametres/debut-calcule-ticket`, formData, config);
      context.commit("isLoading", false);
      context.commit("setParametrages", parametrages?.data);
      if (parametrages?.data) {
        window.location.reload()
      }
    },
    // eslint-disable-next-line
    async getParametrages(context, site) {
      context.commit("isLoading", true);
      const parametrages = await axiosInstance.get(`api/parametres/debut-calcule-ticket/${site}`);
      context.commit("setParametrages", parametrages?.data);
      context.commit("isLoading", false);
      return parametrages?.data
    },

    /**** creation type abonnements */
    async getAllAbonnements(context) {
      const abonnements = await axiosInstance.get(
        `abonnements`
      );
      return abonnements.data;
    },
    // eslint-disable-next-line
    async createAbonnement(context, abonnement) {
      const Abonnement = await axios.post('abonnements/create', abonnement, {
        headers: this.state.headers
      });
    },
    async searchAbonnements(context, searchData) {
      const abonnement = await axios.post('abonnements/search', searchData, {
        headers: this.state.headers
      });
      return abonnement?.data
    },
    // eslint-disable-next-line
    async updateAbonnement(context, abonnement) {
      const Abonnement = await axios.put(`abonnements/update/${abonnement.id}`, abonnement, {
        headers: this.state.headers
      });
    },
    // eslint-disable-next-line
    async deleteAbonnement(context, paylod) {
      return await axios.delete(`abonnements/delete/${paylod?.id}`, { headers: this.state.headers });
    },
    /**** creation CODE CSI */
    async getAllCodecsis(context) {
      const codecsis = await axiosInstance.get(
        `codecsis`
      );
      return codecsis.data;
    },
    // eslint-disable-next-line
    async createCodecsi(context, codecsi) {
      const Codecsi = await axios.post('codecsis/create', codecsi, {
        headers: this.state.headers
      });
    },
    async searchCodecsis(context, searchData) {
      const Codecsi = await axios.post('codecsis/search', searchData, {
        headers: this.state.headers
      });
      return Codecsi?.data
    },
    // eslint-disable-next-line
    async updateCodecsi(context, codecsi) {
      const Codecsi = await axios.put(`codecsis/update/${codecsi.id}`, codecsi, {
        headers: this.state.headers
      });
    },
    // eslint-disable-next-line
    async deleteCodecsi(context, paylod) {
      return await axios.delete(`codecsis/delete/${paylod?.id}`, { headers: this.state.headers });
    },
    // eslint-disable-next-line
    async sendMailjet(context) {
      const Codecsi = await axios.post(`emails/send`, {}, {
        headers: this.state.headers
      });
    },
    /**** creation parents sites */
    async getAllParentSites(context, paylod) {
      const parent = await axiosInstance.get(
        `parents/id/${paylod?.id}`
      );
      return parent.data;
    },

    /**** creation contacts */
    async searchContacts(context, searchData) {
      context.commit("isLoading", true);
      const contacts = await axios.post('contacts/search', searchData, {
        headers: this.state.headers
      });
      context.commit("isLoading", false);
      return contacts?.data
    },

    // eslint-disable-next-line
    async sendEmailWithAttachment(context, formdata) {
      try {
        context.commit("formLoader", true);
        const results = await axios.post('contacts/send', formdata, {
          headers: this.state.headers
        });
        if (results?.data?.success === false) {
          alertSwal("error", "Error sending email");
          return false
        } else if (results?.data?.success === true) {
          alertSwal("success", "Ticket send to " + this.state?.user?.email);
          return true
        }
        else {
          alertSwal("error", "Something wrong try again");
          return false
        }
      } catch (error) {
        console.log(error)
        context.commit("formLoader", false);
        if (error.response.status === 403) {
          alertSwal("error", "Forbidden");
          return false
        }
        else {
          alertSwal("error", "Please Enter good informations");
          return false
        }
      } finally {
        context.commit("formLoader", false);
      }
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
